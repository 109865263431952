a,
body {
    color: #3b3b3b
}

.container,
.wrap {
    position: relative
}

*,
input,
input:focus,
input:hover {
    outline: 0
}

.inline-block,
.list-inline-block>li {
    display: inline-block;
    vertical-align: top
}

body {
    font-family: 'Century Gothic';
    font-size: 13px;
    line-height: inherit;
    margin: 0;
    padding: 0;
    font-weight: 400
}

.wrap {
    overflow: hidden
}

* {
    box-sizing: border-box
}

img {
    max-width: 100%
}

a {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

a:focus,
a:hover {
    text-decoration: none;
    outline: 0
}

.container {
    max-width: 1200px;
    width: 100%
}

.border {
    border: 1px solid #e5e5e5
}

.border-none {
    border: none !important
}

.no-margin {
    margin: 0 !important
}

.border-left {
    border-left: 1px solid #e5e5e5
}

.border-right {
    border-right: 1px solid #e5e5e5
}

.border-top {
    border-top: 1px solid #e5e5e5
}

.border-bottom {
    border-bottom: 1px solid #e5e5e5
}

.transition {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.radius4 {
    border-radius: 4px
}

.radius6 {
    border-radius: 6px
}

.drop-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1)
}

.round {
    border-radius: 50%
}

.desc {
    color: #555;
    margin: 0;
    line-height: 24px;
    font-size: 14px
}

.white {
    color: #3a3a3c
}

.black {
    color: #3b3b3b
}

.silver {
    color: #999
}

.bg-white {
    background: #fff
}

.list-none {
    list-style: none;
    margin: 0;
    padding: 0
}

.list-inline-block {
    margin: 0;
    padding: 0;
    list-style: none
}

.title100,
.title12,
.title13,
.title14,
.title16,
.title18,
.title24,
.title30,
.title40,
.title48,
.title60,
.title90 {
    font-weight: 700;
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    font-family: Poppins, sans-serif
}

.title12 {
    font-size: 12px
}

.title13 {
    font-size: 13px
}

.title16 {
    font-size: 16px
}

.title18 {
    font-size: 18px
}

.title24 {
    font-size: 24px
}

.title30 {
    font-size: 30px
}

.title40 {
    font-size: 40px
}

.title48 {
    font-size: 30px;
    margin-top: 200px;
    margin-bottom: 20px
}

.title60 {
    font-size: 60px
}

.title90 {
    font-size: 90px
}

.title100 {
    font-size: 100px
}

.vibes-font {
    font-family: 'Great Vibes', cursive;
    font-weight: 400;
    text-transform: inherit
}

.mont-font {
    font-family: 'Montserrat Alternates', sans-serif
}

.light-font {
    font-weight: 300
}

.absolute {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.banner-slider,
.banner-slider .item-slider {
    position: relative
}

div.table {
    display: table;
    margin: 0;
    width: 100%
}

div.table>div {
    display: table-cell;
    vertical-align: top
}

.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonUp,
.wrap-item>div:not(:first-child) {
    display: none
}

.owl-theme .owl-controls {
    margin: 0
}

.banner-slider {
    overflow: hidden
}

.owl-theme .owl-controls .owl-buttons div {
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    opacity: 1;
    padding: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.owl-theme .owl-controls .owl-page {
    vertical-align: middle
}

.owl-theme .owl-controls .owl-page span {
    background: #3b3b3b;
    margin: 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    opacity: 1;
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.banner-slider.bg-slider .item-slider {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%
}

.banner-slider.bg-slider .banner-thumb img {
    max-width: none;
    opacity: 0;
    width: auto
}

.group-navi .owl-buttons {
    position: absolute;
    right: 15px;
    top: -45px;
    width: 61px
}

.banner-info,
.banner-info-image {
    top: 0;
    position: absolute;
    right: 0
}

.banner-slider .banner-thumb img {
    width: 100%
}

.banner-slider .banner-info {
    height: 200px
}

.banner-info {
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 9
}

.banner-info-text {
    top: 50%;
    height: 200px;
    margin: -100px 0 0;
    z-index: 9;
    left: 0;
    position: absolute
}

.time-delay1 {
    transition-delay: .5s;
    -webkit-transition-delay: .5s
}

.time-delay2 {
    transition-delay: .8s;
    -webkit-transition-delay: .8s
}

.time-delay3 {
    transition-delay: 1.1s;
    -webkit-transition-delay: 1.1s
}

.time-delay4 {
    transition-delay: 1.4s;
    -webkit-transition-delay: 1.4s
}

.top-to-bottom {
    transform: translateY(-500px);
    -webkit-transform: translateY(-500px);
    transition-property: all;
    transition-timing-function: ease-out;
    transition-duration: .5s;
    -webkit-transition-property: all;
    -webkit-transition-timing-function: ease-out;
    -webkit-transition-duration: .5s;
    opacity: 0
}

.bottom-to-top,
.right-to-left {
    transition-property: all;
    transition-timing-function: ease-out;
    transition-duration: .5s
}

.owl-item.active .top-to-bottom {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1
}

.bottom-to-top {
    transform: translateY(500px);
    -webkit-transform: translateY(500px);
    -webkit-transition-property: all;
    -webkit-transition-timing-function: ease-out;
    -webkit-transition-duration: .5s;
    opacity: 0
}

.owl-item.active .bottom-to-top {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1
}

.right-to-left {
    transform: translateX(500px);
    -webkit-transform: translateX(500px);
    -webkit-transition-property: all;
    -webkit-transition-timing-function: ease-out;
    -webkit-transition-duration: .5s;
    opacity: 0
}

.owl-item.active .right-to-left {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1
}

.left-to-right {
    transform: translateX(-500px);
    -webkit-transform: translateX(-500px);
    transition-property: all;
    transition-timing-function: ease-out;
    transition-duration: .5s;
    -webkit-transition-property: all;
    -webkit-transition-timing-function: ease-out;
    -webkit-transition-duration: .5s;
    opacity: 0
}

.owl-item.active .left-to-right {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1
}

.mCSB_container {
    margin-right: 0
}

.mCustomScrollBox>.mCSB_scrollTools {
    width: 4px
}

.line-white {
    position: relative
}

.line-white::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    content: "";
    background: #fff
}

.box-hover-dir {
    overflow: hidden;
    position: relative
}

.box-hover-dir>div {
    height: 100%;
    position: absolute;
    width: 100%;
    transition: all .5s ease-out 0s !important;
    -webkit-transition: all .5s ease-out 0s !important
}

.banner-new {
    position: relative
}

.adv-new-thumb-link {
    display: block;
    overflow: hidden;
    position: relative
}

.adv-new-thumb-link>img {
    display: block;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.zoom-rotate .adv-new-thumb-link>img {
    transform: scale(1) rotate(0);
    -webkit-transform: scale(1) rotate(0)
}

.zoom-rotate:hover .adv-new-thumb-link>img {
    transform: scale(1.15) rotate(4deg);
    -webkit-transform: scale(1.15) rotate(4deg)
}

.zoom-image:hover .adv-new-thumb-link>img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.fade-in-out .adv-new-thumb-link::after,
.fade-in-out .adv-new-thumb-link::before,
.fade-out-in .adv-new-thumb-link::after,
.fade-out-in .adv-new-thumb-link::before,
.overlay-image .adv-new-thumb-link::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background: rgba(0, 0, 0, .1);
    opacity: 0;
    z-index: 1;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.line-scale .adv-new-thumb-link::after,
.line-scale .adv-new-thumb-link::before {
    position: absolute;
    content: "";
    z-index: 1;
    transition: all .5s ease-out 0s
}

.fade-out-in:hover .adv-new-thumb-link::before {
    opacity: 1;
    top: 50%;
    bottom: 50%
}

.fade-out-in:hover .adv-new-thumb-link::after {
    opacity: 1;
    left: 50%;
    right: 50%
}

.fade-in-out .adv-new-thumb-link::before {
    opacity: 1;
    top: 50%;
    bottom: 50%
}

.fade-in-out .adv-new-thumb-link::after {
    opacity: 1;
    left: 50%;
    right: 50%
}

.fade-in-out:hover .adv-new-thumb-link::before {
    opacity: 0;
    top: 0;
    bottom: 0
}

.fade-in-out:hover .adv-new-thumb-link::after {
    opacity: 0;
    left: 0;
    right: 0
}

.overlay-image .adv-new-thumb-link::after {
    background: rgba(0, 0, 0, .3)
}

.overlay-image:hover .adv-new-thumb-link::after {
    opacity: 1
}

.blur-image:hover .adv-new-thumb-link img {
    filter: blur(5px);
    -webkit-filter: blur(5px)
}

.zoom-out .adv-new-thumb-link img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: scale(3);
    -webkit-transform: scale(3)
}

.zoom-out:hover .adv-new-thumb-link img {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    -webkit-transform: scale(1)
}

.line-scale .adv-new-thumb-link::before {
    top: 20px;
    bottom: 20px;
    left: 10px;
    right: 10px;
    border-top: 1px solid rgba(255, 255, 255, .9);
    border-bottom: 1px solid rgba(255, 255, 255, .9);
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -webkit-transition: all .5s ease-out 0s
}

.line-scale .adv-new-thumb-link::after {
    top: 10px;
    bottom: 10px;
    left: 20px;
    right: 20px;
    border-left: 1px solid rgba(255, 255, 255, .9);
    border-right: 1px solid rgba(255, 255, 255, .9);
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    -webkit-transition: all .5s ease-out 0s
}

.line-scale:hover .adv-new-thumb-link::after,
.line-scale:hover .adv-new-thumb-link::before {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.main-nav>ul>li .sub-menu>li.menu-item-preview {
    position: relative
}

.main-nav>ul>li .sub-menu>li.menu-item-preview>.preview-image {
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    left: 100%;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 200px;
    opacity: 0;
    visibility: hidden;
    margin-top: 30px;
    border: 1px solid #e5e5e5;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.main-nav>ul>li .sub-menu>li.menu-item-preview:hover>.preview-image {
    opacity: 1;
    visibility: visible;
    margin-top: 0
}

.main-nav>ul>li .sub-menu>li.menu-item-preview>.preview-image>a {
    display: block;
    height: 200px;
    overflow: hidden;
    position: relative
}

.main-nav>ul>li .sub-menu>li.menu-item-preview>.preview-image>a>img {
    left: 0;
    position: absolute;
    top: 0;
    transition: all 5s ease-in-out 0s;
    -webkit-transition: all 5s ease-in-out 0s;
    width: 100%
}

.main-nav>ul>li .sub-menu>li.menu-item-preview>.preview-image>a:hover>img {
    top: 100%;
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%)
}

.header-ontop {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.header-ontop.fixed-ontop {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    visibility: visible;
    width: 100%;
    z-index: 999 !important;
    padding: 15px 0;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .1);
    left: 0;
    position: fixed !important;
    top: 0
}

.main-nav,
.main-nav li.menu-item-has-children {
    position: relative
}

.main-nav {
    z-index: 100
}

.main-nav>ul>li>a {
    padding: 0 20px
}

.main-nav li.has-mega-menu:hover>.mega-menu,
.main-nav li.menu-item-has-children:hover>.sub-menu {
    margin: 0;
    opacity: 1;
    visibility: visible
}

.main-nav .sub-menu {
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    left: 0;
    list-style: none;
    margin: 30px 0 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    top: 100%;
    visibility: hidden;
    width: 200px;
    z-index: 999;
    text-align: left;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.main-nav>ul>li .sub-menu>li {
    padding: 0 20px
}

.main-nav>ul>li .sub-menu>li>a {
    border-top: 1px solid #e5e5e5;
    display: block;
    padding: 10px 0;
    position: relative;
    text-transform: capitalize
}

.main-nav>ul>li .sub-menu>li:first-child>a {
    border: none
}

.main-nav>ul>li .sub-menu>li.menu-item-has-children>a {
    padding-right: 10px;
    position: relative
}

.main-nav>ul>li .sub-menu>li.menu-item-has-children>a::after {
    content: "";
    font-family: fontAwesome;
    position: absolute;
    right: 0;
    top: 11px
}

.main-nav>ul>li.menu-item-has-children li.menu-item-has-children>.sub-menu {
    left: 100%;
    top: 0
}

.mega-menu {
    background: #fff;
    margin-top: 30px
}

.main-nav>ul>li.has-mega-menu:hover .mega-menu {
    margin-top: 0;
    opacity: 1;
    visibility: visible
}

.mega-menu,
.sub-menu {
    pointer-events: none
}

li.has-mega-menu:hover>.mega-menu,
li.menu-item-has-children:hover>.sub-menu {
    pointer-events: inherit
}

.main-nav .toggle-mobile-menu {
    display: none;
    height: 28px;
    width: 30px;
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.main-nav .toggle-mobile-menu span {
    position: absolute;
    width: 100%;
    height: 6px;
    left: 0;
    top: 50%;
    background: #fff
}

.main-nav .toggle-mobile-menu::after,
.main-nav .toggle-mobile-menu::before {
    content: '';
    position: absolute;
    height: 6px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: #fff
}

.main-nav.active .toggle-mobile-menu::after,
.main-nav.active .toggle-mobile-menu::before {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    left: 0;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    background: #fff
}

.main-nav .toggle-mobile-menu::before {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0)
}

.main-nav .toggle-mobile-menu::after {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0)
}

.main-nav.active .toggle-mobile-menu::before {
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg)
}

.main-nav.active .toggle-mobile-menu::after {
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg)
}

.main-nav.active .toggle-mobile-menu span {
    display: none
}

@media (max-width:767px) {
    .main-nav .menu-item-has-children .sub-menu li>a {
        border-bottom: none
    }

    .mega-menu {
        display: none
    }

    .main-nav .toggle-mobile-menu {
        display: block
    }

    .main-nav .sub-menu {
        background: #fff;
        display: none;
        margin: 0 !important;
        opacity: 1 !important;
        visibility: visible !important;
        position: static !important;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .1) !important
    }

    .main-nav>ul>li>a::before {
        display: none !important
    }

    .main-nav>ul>li>a {
        color: #333 !important;
        font-weight: 400 !important
    }

    .main-nav {
        position: relative;
        z-index: 9999;
        margin: 0
    }

    .main-nav>ul {
        position: absolute;
        left: 0;
        top: 100%;
        width: 290px;
        z-index: 999;
        background: #fff;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
        transition: all .5s ease-out 0s;
        -webkit-transition: all .5s ease-out 0s;
        transform: translateX(-320px);
        -webkit-transform: translateX(-320px)
    }

    .main-nav.active>ul {
        transform: translateX(0) !important;
        -webkit-transform: translateX(0) !important
    }

    .main-nav>ul>li {
        display: block;
        margin: 0 15px !important;
        padding: 0 !important
    }

    .main-nav .sub-menu li a::before {
        display: none
    }

    .main-nav>ul li>a {
        display: block;
        font-size: 14px;
        font-weight: 400;
        height: 40px !important;
        line-height: 40px !important;
        padding: 0 !important
    }

    .main-nav>ul li:first-child>a {
        border: none
    }

    .main-nav .sub-menu>li {
        padding: 0 !important;
        margin: 0 15px
    }

    .main-nav>ul li.menu-item-has-children .menu-item-has-children>a::after {
        right: 0;
        top: 0
    }

    .main-nav>ul>li.menu-item-has-children>a::after {
        position: absolute;
        right: 0 !important;
        top: 13px;
        content: "\f107";
        font-family: fontawesome
    }

    .main-nav>ul>li .sub-menu::after {
        display: none
    }

    .main-nav>ul>li .sub-menu {
        display: none;
        width: 100%;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .1);
        padding: 0
    }

    .main-nav>ul>li.menu-item-has-children li.menu-item-has-children>.sub-menu {
        left: 0;
        top: 100%;
        padding: 0
    }

    .main-nav>ul>li.menu-item-has-children li.menu-item-has-children>a::after {
        content: "";
        font-family: fontawesome;
        position: absolute;
        right: 0;
        top: 0
    }

    .main-nav>ul>li.has-mega-menu>a::after {
        display: none !important
    }

    .menu-item-preview .preview-image {
        display: none
    }
}

.product-rate,
.product-rating {
    background-image: url(../images/icons/rate-star.html);
    background-repeat: repeat-x
}

.product-rate {
    background-position: 0 -13px;
    height: 13px;
    margin-top: 3px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 95px
}

.product-rating {
    background-position: 0 0
}

body .scroll-top {
    background: rgba(0, 0, 0, .5);
    bottom: 10px;
    color: #fff;
    display: block;
    height: 40px;
    line-height: 40px;
    position: fixed;
    right: -50px;
    text-align: center;
    width: 40px;
    opacity: 0
}

.scroll-top.active {
    opacity: 1;
    right: 10px
}

.product-price del::after,
.product-thumb-link::before {
    content: "";
    right: 0;
    left: 0
}

.product-title {
    font-weight: 400;
    line-height: 16px
}

.product-price ins {
    font-size: 14px;
    margin: 0 2px;
    text-decoration: none
}

.product-price del::after {
    background: #999;
    height: 1px;
    position: absolute;
    top: 10px
}

.product-price del {
    color: #999;
    margin: 0 2px;
    position: relative;
    text-decoration: none
}

.product-price {
    margin: 0 -2px 7px
}

.product-thumb-link img {
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.product-thumb-link::before {
    background: rgba(0, 0, 0, .3);
    opacity: 0;
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.product-thumb:hover .product-thumb-link::before {
    opacity: 1
}

.product-thumb:hover .zoom-thumb img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.zoomout-thumb img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(3);
    -webkit-transform: scale(3)
}

.product-thumb:hover .zoomout-thumb img:last-child {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1
}

.rotate-thumb img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: rotateY(90deg);
    -webkit-transform: rotateY(90deg)
}

.product-thumb:hover .rotate-thumb img:last-child {
    transform: rotateY(0);
    -webkit-transform: rotateY(0);
    opacity: 1
}

.product-thumb:hover .rotate-thumb img:first-child {
    transform: rotateY(-90deg);
    -webkit-transform: rotateY(-90deg);
    opacity: 0
}

.translate-thumb img:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%)
}

.product-thumb:hover .translate-thumb img:last-child {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    opacity: 1
}

.product-thumb:hover .translate-thumb img:first-child {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    opacity: 0
}

.select-box label {
    font-weight: 400;
    margin: 0 5px
}

.select-box select {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    cursor: pointer;
    height: 36px;
    padding: 0 35px 0 10px;
    position: relative;
    text-align: left;
    text-transform: capitalize
}

.select-box {
    position: relative
}

.select-box::after {
    position: absolute;
    top: 10px;
    right: 15px;
    content: "\f107";
    font-family: fontAwesome
}

.wishlist-mask {
    background: rgba(0, 0, 0, .5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 999;
    display: none
}

.wishlist-popup {
    background: #fff;
    border: 10px solid rgba(0, 0, 0, .5);
    bottom: 0;
    height: 200px;
    left: 0;
    margin: auto;
    padding: 20px;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    width: 470px;
    z-index: 9999
}

.wishlist-button a {
    background: #858785;
    color: #fff;
    display: inline-block;
    padding: 8px 15px;
    margin: 0 3px
}

#boxes .window,
#mask {
    top: 0;
    display: none;
    left: 0
}

.wishlist-button a:last-child {
    background: #ff607c
}

.popup-icon {
    color: #ff607c;
    font-size: 30px
}

.wishlist-alert {
    margin: 10px 0 20px
}

.product-popup-content {
    padding: 15px 0
}

#mask {
    position: absolute;
    z-index: 9000;
    background-color: #000
}

#boxes .window {
    position: fixed;
    z-index: 9999
}

.window-popup {
    background: #F6F5F2;
    padding: 30px;
    width: 600px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    border-radius: 6px
}

.window-popup .close-popup {
    background: #fff;
    display: block;
    height: 30px;
    line-height: 30px;
    position: absolute;
    right: -15px;
    text-align: center;
    top: -15px;
    width: 30px;
    z-index: 999;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1)
}

.content-popup {
    position: relative;
    z-index: 99
}

.content-popup input[type=submit] {
    border-width: 0 0 3px;
    display: block;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 4px
}

.content-popup input[type=text] {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #999;
    display: block;
    font-size: 18px;
    height: 50px;
    margin: 0 0 16px;
    padding: 0 10px;
    text-align: center;
    width: 100%
}

.content-popup .image-popup {
    margin: 10px 0
}

.content-popup .title30 {
    margin-bottom: 5px
}

.content-popup select {
    background: linear-gradient(to bottom, #fff 0, #e5e5e5 100%) rgba(0, 0, 0, 0);
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #555;
    display: block;
    height: 40px;
    margin-bottom: 15px;
    padding: 0 15px;
    width: 100%
}

.currency-link,
.language-link,
.top-nav a {
    color: #ccc
}

.currency-box,
.language-box,
.mini-cart-link,
.top-nav li {
    display: inline-block
}

.content-popup select option {
    padding: 0 15px
}

.top-header {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    padding: 12px 0 9px
}

.top-nav>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.top-nav li {
    border-right: 1px solid rgba(255, 255, 255, .1);
    margin-right: 7px;
    padding-right: 10px;
    position: relative
}

.top-nav li:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.header {
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.scroll-fixed.nav-fixed {
    left: 0;
    position: fixed;
    top: -100px;
    width: 100%;
    z-index: 999999;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.currency-box,
.language-box,
.mini-cart-box {
    position: relative;
    vertical-align: middle
}

.header-nav8,
.logo img {
    transition: all .5s ease-out 0s
}

.logo img {
    -webkit-transition: all .5s ease-out 0s
}

.scroll-fixed.nav-fixed .main-nav>ul>li>a {
    height: 50px;
    line-height: 50px
}

.scroll-fixed.nav-fixed .box-search-hover.search-hover1,
.scroll-fixed.nav-fixed .mini-cart-box.mini-cart1 {
    margin-top: 5px
}

.scroll-fixed.nav-fixed .main-nav.main-nav1 {
    margin: -50px 0 0
}

.scroll-fixed.nav-fixed .logo.logo1 img {
    height: 50px
}

.scroll-fixed.nav-fixed.nav-show {
    top: 0
}

.header-nav6.scroll-fixed.nav-fixed {
    transition: none
}

.wrap-header-nav4.scroll-fixed.nav-fixed {
    background: #333;
    border: none
}

.header-nav6.scroll-fixed.nav-fixed,
.header5.scroll-fixed.nav-fixed {
    background: #fff
}

.inner-header7.scroll-fixed.nav-fixed {
    background: #fff;
    padding: 0
}

.header-nav8.scroll-fixed.nav-fixed,
.main-header12.scroll-fixed.nav-fixed {
    background: #fff;
    padding: 20px 0 15px
}

.inner-header7.scroll-fixed.nav-fixed .logo7 {
    margin: 10px auto auto;
    width: 70px
}

.header-nav8 {
    -webkit-transition: all .5s ease-out 0s
}

.main-header12 {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.wrap-language-cart {
    float: right;
    margin-top: -5px
}

.language-box {
    border-right: 1px solid rgba(25, 255, 255, .1);
    margin-right: 7px;
    padding-right: 10px
}

.mini-cart-box {
    z-index: 99999
}

.mini-cart-box:hover .mini-cart-content {
    visibility: visible;
    opacity: 1;
    margin-top: 10px
}

.mini-cart-content {
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    margin-top: 40px;
    padding: 20px;
    position: absolute;
    right: 0;
    top: 100%;
    width: 300px;
    z-index: 999;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    visibility: hidden;
    opacity: 0
}

.mini-cart-link {
    position: relative;
    vertical-align: middle
}

.mini-cart-icon {
    font-size: 16px
}

.mini-cart-number {
    border-radius: 50%;
    display: block;
    font-size: 10px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    right: -7px;
    text-align: center;
    top: -7px;
    width: 20px
}

.main-nav>ul,
.main-nav>ul li.menu-item-has-children,
.main-nav>ul li.menu-item-has-children .menu-item-has-children>a {
    position: relative
}

.mini-cart-items {
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 10px;
    padding-bottom: 25px;
    text-transform: uppercase
}

.list-mini-cart-item {
    list-style: none;
    margin: 0 0 20px;
    padding: 0
}

.list-mini-cart-item>li {
    border-bottom: 1px solid #e5e5e5;
    display: table;
    padding: 10px 0;
    width: 100%
}

.mini-cart-thumb {
    display: table-cell;
    vertical-align: top
}

.cart-thumb {
    display: block;
    overflow: hidden;
    width: 70px
}

.mini-cart-info {
    display: table-cell;
    padding: 0 15px;
    text-align: left;
    vertical-align: middle
}

.mini-cart-remove {
    display: table-cell;
    text-align: right;
    vertical-align: top
}

.mini-cart-title {
    font-size: 13px;
    font-weight: 400;
    margin: 0 0 10px
}

.mini-cart-title>a {
    color: #3b3b3b
}

.mini-cart-qty {
    color: #888
}

.mini-cart-qty>label {
    font-weight: 400;
    margin: 0 10px 0 0
}

.mini-cart-price {
    display: block
}

.remove-product {
    background: url(../images/1440px/website/images/home1/icon-remove.png) center center no-repeat rgba(0, 0, 0, 0);
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 30px
}

.cart-thumb>img {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.mini-cart-button a,
.sub-menu {
    transition: all .5s ease-out 0s
}

.cart-thumb:hover img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.mini-cart-total {
    display: table;
    font-size: 16px;
    margin-bottom: 20px;
    text-transform: uppercase;
    width: 100%
}

.mini-cart-total>label {
    display: table-cell;
    font-weight: 400;
    margin: 0
}

.mini-cart-total>span {
    display: table-cell;
    text-align: right
}

.main-nav>ul>li,
.mini-cart-button a {
    display: inline-block
}

.mini-cart-button a {
    background: #000;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    line-height: 36px;
    text-transform: capitalize;
    padding-right: 14px;
    -webkit-transition: all .5s ease-out 0s
}

.banner-info-inner>label,
.banner-info-inner>strong,
.category-home-info>h3,
.deal-week-countdown>label,
.list-brand>h2,
.main-nav>ul>li>a,
.new-label,
.product-title,
.saleoff,
.sidebar-mega-menu a,
.staff-info>h3,
.title-box h2,
.title-tab-home>ul>li>a {
    text-transform: uppercase
}

.mini-cart-button a::before {
    border-right: 1px solid rgba(255, 255, 255, .2);
    content: "";
    display: inline-block;
    font-family: linearicons;
    height: 36px;
    margin-right: 5px;
    text-align: center;
    vertical-align: top;
    width: 40px
}

.mini-cart-button .mini-cart-edit::before {
    content: ""
}

.mini-cart-button .mini-cart-checkout::before {
    content: ""
}

.mini-cart-button a:hover {
    color: #fff
}

.remove-product:hover {
    background-color: #e5e5e5
}

.main-nav>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.main-nav>ul li.menu-item-has-children .menu-item-has-children>a::after {
    font-family: linearicons;
    content: "\e876";
    position: absolute;
    top: 3px;
    right: -5px
}

.main-nav>ul>li a {
    display: block
}

.main-nav>ul>li>a {
    color: #fff;
    font-weight: 700;
    position: relative;
    height: 50px;
    line-height: 50px;
    display: block
}

.mega-menu,
.sub-menu {
    background-color: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    left: 0;
    margin: 30px 0 0;
    opacity: 0;
    position: absolute;
    top: 100%;
    z-index: 999;
    text-align: left;
    visibility: hidden
}

.sub-menu {
    list-style: none;
    padding: 10px 0;
    width: 180px;
    -webkit-transition: all .5s ease-out 0s
}

.mega-menu {
    padding: 20px 0 20px 170px;
    width: 870px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.main-nav .has-mega-menu:hover .mega-menu,
.main-nav>ul li.menu-item-has-children:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    margin: 0
}

.main-nav>ul>li:last-child {
    margin: 0
}

.sub-menu>li {
    padding: 4px 20px
}

.sub-menu>li>a {
    color: #3b3b3b
}

.main-nav>ul>li li.menu-item-has-children>.sub-menu {
    left: 100%;
    top: 0
}

.toggle-mobile-menu {
    display: none
}

.saleoff,
.search-form::after {
    display: block;
    text-align: center
}

.search-form {
    background: rgba(255, 255, 255, .1);
    border-radius: 4px;
    margin-left: -45px;
    padding-right: 36px;
    position: relative;
    margin-top: 26px
}

.search-form>input[type=text] {
    background: 0 0;
    border: none;
    color: #888;
    font-style: italic;
    height: 36px;
    padding: 0 15px;
    width: 100%
}

.search-form>input[type=submit] {
    background: 0 0;
    border: none;
    border-radius: 4px;
    height: 36px;
    position: absolute;
    width: 36px;
    z-index: 99;
    top: 0;
    right: 0
}

.search-form::after {
    border-radius: 4px;
    height: 36px;
    position: absolute;
    width: 36px;
    font-family: FontAwesome;
    content: "\f002";
    font-size: 16px;
    top: 0;
    right: 0;
    line-height: 36px
}

.sidebar-mega-menu {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 170px
}

.content-mega-menu {
    border-left: 1px solid #e5e5e5
}

.sidebar-mega-menu>ul {
    list-style: none;
    padding: 20px
}

.sidebar-mega-menu a {
    color: #3b3b3b
}

.sidebar-mega-menu li {
    padding: 10px 0
}

.list-product-mega-menu {
    padding: 0 20px
}

.product-title {
    font-size: 13px;
    margin: 0 0 10px
}

.product-title>a {
    color: #3b3b3b;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    font-weight: 700
}

.product-thumb {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative
}

.product-thumb>a {
    color: #3b3b3b
}

.product-price>strong {
    font-size: 18px;
    font-weight: 700;
    margin-right: 5px
}

.product-desc {
    color: #888;
    margin: 0
}

.product-price {
    margin-bottom: 7px
}

.mega-menu-v2 .list-product-mega-menu {
    padding: 0 15px
}

.mega-menu-v2 .item-product-mega-menu {
    margin: 0 -15px
}

.mega-menu-v2 .content-mega-menu {
    border: none
}

.saleoff {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    left: 0;
    position: absolute;
    top: 20px;
    width: 100%;
    z-index: 999
}

.mega-menu-v2 .product-info {
    padding: 0 20px 20px
}

.product-thumb img {
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.mega-menu-v2 .shopnow {
    color: #3b3b3b;
    display: inline-block
}

.mega-menu-v2 .product-title {
    margin-bottom: 5px
}

.mega-menu-v2 .shopnow::after {
    content: "";
    font-family: linearicons;
    margin-left: 5px
}

.mega-menu.mega-menu-v2 {
    padding: 0 0 0 170px
}

.mega-menu-v2 .sidebar-mega-menu {
    background: url(../images/1440px/website/images/home1/bg-mega-menu.jpg) top left no-repeat;
    background-size: 100% 100%
}

.mega-menu-v2 .sidebar-mega-menu a {
    color: #fff
}

.item-product-mega-menu .product-thumb:hover img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.directnav-slider .owl-theme .owl-controls .owl-buttons div {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    margin: -25px 0 0;
    opacity: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    background: rgba(0, 0, 0, .5);
    font-size: 20px;
    color: #fff;
    line-height: 50px;
    text-align: center
}

.directnav-slider:hover .owl-theme .owl-controls .owl-buttons div {
    opacity: 1
}

.directnav-slider .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: -50px
}

.directnav-slider .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -50px
}

.directnav-slider:hover .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: 0
}

.directnav-slider:hover .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0
}

.banner-info-inner>strong {
    color: #fff;
    font-size: 200px;
    display: block;
    transition: all 1s ease-out 0s;
    -webkit-transition: all 1s ease-out 0s;
    transform: translateX(-300px);
    -webkit-transform: translateX(-300px);
    opacity: 0
}

.banner-info-inner>label {
    display: block;
    font-size: 60px;
    margin-bottom: -124px;
    position: relative;
    z-index: 99;
    margin-right: 20px;
    transition: all 1s ease-out 0s;
    -webkit-transition: all 1s ease-out 0s;
    transform: translateX(300px);
    -webkit-transform: translateX(300px);
    opacity: 0
}

.banner-slider .owl-item.active .banner-info-inner>label,
.banner-slider .owl-item.active .banner-info-inner>strong {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1
}

.banner-info-inner {
    display: inline-block;
    text-align: right
}

.special-adv {
    margin: -170px auto 100px;
    max-width: 1060px;
    padding: 0 15px
}

.special-adv-mask {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .1);
    opacity: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-special-adv {
    color: #fff;
    font-weight: 700;
    margin: 0 -15px;
    padding: 75px 15px 70px;
    position: relative;
    text-align: center;
    text-transform: uppercase
}

.item-special-adv:hover .special-adv-mask {
    opacity: 1
}

.inner-special-adv>h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 0
}

.inner-special-adv>h2 {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    font-size: 60px;
    font-weight: 700;
    margin: 15px auto 22px;
    padding-bottom: 9px;
    width: 300px
}

.item-special-adv::after,
.item-special-adv::before {
    border: 1px solid rgba(255, 255, 255, .5);
    content: "";
    height: 220px;
    position: absolute;
    width: 430px;
    opacity: 0
}

.item-special-adv.style2 {
    background: #dda379;
    top: -50px
}

.inner-special-adv a {
    color: #fff;
    font-size: 16px
}

.item-special-adv::before {
    left: 40px;
    top: 60px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-special-adv::after {
    left: 50px;
    top: 50px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-special-adv.style2::after,
.item-special-adv.style2::before {
    background: rgba(221, 163, 121, .5)
}

.title-box::after,
.title-box::before {
    background: #e5e5e5;
    content: "";
    height: 1px;
    top: 6px;
    width: 160px
}

.inner-special-adv {
    position: relative;
    z-index: 999
}

.item-special-adv:hover::after,
.item-special-adv:hover::before {
    opacity: 1
}

.title-box h2 {
    font-weight: 700;
    margin: 0
}

.title-box::before {
    margin-right: 15px;
    position: absolute;
    right: 50%
}

.title-box::after {
    left: 50%;
    margin-left: 15px;
    position: absolute
}

.category-home-thumb img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
}

.say-hello {
    margin: auto;
    max-width: 900px
}

.title-box {
    background: url(../images/1440px/website/images/home1/icon-box.png) center top no-repeat rgba(0, 0, 0, 0);
    margin-bottom: 42px;
    padding-top: 33px;
    position: relative;
    text-align: center
}

.list-category-home {
    margin-top: 42px;
    margin-bottom: 50px
}

.category-home-thumb>a {
    border-radius: 50%;
    display: block;
    height: 100px;
    position: relative;
    width: 100px
}

.item-category-home::after,
.item-category-home::before {
    height: 100%;
    transition: all .5s ease-out 0s;
    top: 0;
    left: 0;
    content: ""
}

.item-category-home {
    display: table;
    margin-bottom: 50px;
    width: 100%;
    position: relative
}

.item-category-home::before {
    position: absolute;
    width: 100%;
    background: url(../images/1440px/website/images/home1/round1.png) left top no-repeat rgba(0, 0, 0, 0);
    transform: scale(1);
    -webkit-transform: scale(1);
    -webkit-transition: all .5s ease-out 0s
}

.item-category-home::after {
    position: absolute;
    width: 100%;
    background: url(../images/1440px/website/images/home1/round2.png) left top no-repeat rgba(0, 0, 0, 0);
    background-size: 100% 100%;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-transition: all .5s ease-out 0s
}

.item-category-home:hover::before {
    transform: scale(0);
    -webkit-transform: scale(0)
}

.item-category-home:hover::after {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.category-home-thumb {
    display: table-cell;
    width: 100px
}

.category-home-info {
    display: table-cell;
    padding: 0 0 0 20px;
    vertical-align: middle;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-category-home:hover .category-home-info {
    padding: 0
}

.category-home-info>h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0
}

.category-home-info h3 a {
    color: #3b3b3b
}

.category-home-info>span {
    color: #888
}

.home-best-seller {
    background: #f4f4f4;
    padding: 100px 0 43px
}

.home-best-seller-header {
    background: url(../images/1440px/website/images/home1/bg-bestsell.png) center top no-repeat rgba(0, 0, 0, 0);
    margin-bottom: 50px;
    padding: 50px 0
}

.title-tab-home>ul {
    margin: 0;
    padding: 0
}

.title-tab-home {
    text-align: center
}

.title-tab-home>ul>li {
    display: inline-block;
    margin: 0 4px
}

.title-tab-home>ul>li>a {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    color: #3b3b3b;
    display: block;
    font-size: 16px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.new-label,
.title-tab-home>ul>li.active a,
.title-tab-home>ul>li:hover>a {
    color: #fff
}

.home-best-seller-slider .item {
    padding: 0 15px
}

.home-best-seller-slider {
    margin: 0 -15px
}

.product-thumb-link .first-thumb {
    left: 0;
    position: absolute;
    top: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.product-thumb-link:hover .first-thumb {
    opacity: 0;
    transform: scale(5);
    -webkit-transform: scale(5);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.product-thumb-link {
    display: block;
    overflow: hidden;
    position: relative
}

.item-home-bestsell {
    background: #fff;
    margin-bottom: 30px
}

.item-home-bestsell .product-thumb {
    margin: 0;
    padding: 20px
}

.new-label {
    background: #dda379;
    border-radius: 0 0 18px 18px;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    line-height: 18px;
    padding: 10px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 36px;
    word-wrap: break-word;
    z-index: 99
}

.item-home-bestsell .product-info {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    padding: 30px;
    position: relative
}

.product-price>span {
    color: #888;
    padding: 0 5px;
    position: relative
}

.product-price>span::after {
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 100%
}

.saleoff-label {
    background: #f6403f;
    border-radius: 18px 0 0 18px;
    color: #fff;
    display: block;
    height: 36px;
    line-height: 36px;
    margin: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50px;
    width: 66px
}

.product-rating {
    background: url(../images/detail/rate.png) rgba(0, 0, 0, 0);
    height: 14px;
    margin-left: -2px;
    width: 90px
}

.inner-rating {
    background: url(../images/1440px/website/images/home1/rate.png) repeat-x rgba(0, 0, 0, 0);
    height: 14px
}

.item-home-bestsell .product-extra-link {
    display: table;
    width: 100%
}

.item-home-bestsell .product-extra-link a {
    border-right: 1px solid #e5e5e5;
    color: #3b3b3b;
    display: table-cell;
    padding: 13px 0;
    position: relative;
    text-align: center;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-home-bestsell .product-extra-link a:last-child {
    border: none
}

.item-home-bestsell .product-extra-link a i {
    margin-right: 10px
}

.item-home-bestsell .product-extra-link a.addcart-link span {
    position: relative;
    z-index: 9
}

.item-home-bestsell .product-extra-link a.addcart-link:hover {
    color: #fff
}

.item-home-bestsell .product-extra-link a.addcart-link:hover::after {
    height: 100%
}

.item-home-bestsell .product-extra-link a.addcart-link::after {
    left: 0;
    width: 100%
}

.item-home-bestsell .product-extra-link a::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    width: 0;
    height: 2px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-home-bestsell .product-extra-link a:hover::after {
    left: 0;
    width: 100%
}

.home-best-seller-slider .owl-theme .owl-controls {
    margin-top: 20px
}

.brand-show {
    background: url(../images/1440px/website/images/home1/bg-brand.jpg) center top no-repeat fixed rgba(0, 0, 0, 0);
    margin-bottom: 100px
}

.content-brand-show {
    padding: 96px 0 100px;
    position: relative
}

.content-brand-show::before {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-right: 100px;
    width: 100%;
    height: 100%;
    background: url(../images/1440px/website/images/home1/men.png) right bottom no-repeat rgba(0, 0, 0, 0);
    content: "";
    display: block
}

.list-brand {
    float: right;
    text-align: center;
    max-width: 470px;
    width: 100%
}

.list-brand>h2 {
    color: #fff;
    font-weight: 700;
    margin: 0 0 50px
}

.list-brand>ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative
}

.list-brand li {
    border-bottom: 1px solid rgba(255, 255, 255, .5)
}

.list-brand a {
    border-right: 1px solid rgba(255, 255, 255, .5);
    display: block;
    width: 33.33%;
    float: left
}

.list-brand a:last-child,
.list-brand li:last-child {
    border: none
}

.list-brand>ul::after {
    bottom: 0;
    content: "";
    height: 35%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 35%
}

.list-brand img {
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    z-index: 999
}

.list-brand a:hover img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.wrap-deal-week-product {
    float: left;
    width: 40%
}

.deal-week-countdown {
    color: #fff;
    float: left;
    padding-top: 60px;
    text-align: center;
    width: 20%
}

.deal-week-product {
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    float: left;
    overflow: hidden;
    position: relative;
    width: 50%
}

.deal-week-slider {
    background: url(../images/1440px/website/images/home1/bg-prl.jpg) center top no-repeat fixed rgba(0, 0, 0, 0);
    margin-bottom: 100px;
    padding: 100px 0 44px
}

.deal-week-product .product-info {
    background: rgba(0, 0, 0, .7);
    height: 100%;
    left: 0;
    padding-top: 60px;
    position: absolute;
    text-align: center;
    top: 100%;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.deal-week-product .product-thumb {
    margin-bottom: 0
}

.deal-week-slider .owl-theme .owl-controls {
    margin-top: 50px
}

.deal-week-slider .owl-theme .owl-controls .owl-page span {
    border: 1px solid #fff
}

.deal-week-slider .owl-theme .owl-controls .owl-page.active span::before,
.owl-theme .owl-controls .owl-page:hover span::before {
    background: #fff
}

.deal-week-product .product-title a {
    color: #fff;
    font-weight: 700
}

.product-more-link {
    font-size: 0
}

.product-more-link a {
    background: #fff;
    color: #3b3b3b;
    display: inline-block;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    margin-left: 1px;
    position: relative;
    text-align: center;
    vertical-align: top;
    width: 40px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.product-more-link a:hover {
    color: #fff
}

.product-more-link a span {
    background: #000;
    border-radius: 12px;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    height: 23px;
    left: 0;
    line-height: 23px;
    margin-left: -33px;
    padding: 0;
    position: absolute;
    text-align: center;
    top: 100%;
    width: 100px;
    margin-top: 10px;
    opacity: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    visibility: hidden
}

.product-more-link a span::after {
    border-bottom: 5px solid #000;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    top: -5px;
    width: 0
}

.product-more-link a:hover span {
    opacity: 1;
    visibility: visible;
    margin-top: 0
}

.item-deal-week {
    background: rgba(0, 0, 0, .7)
}

.deal-week-product+.deal-week-product+.deal-week-product {
    border-bottom: medium none
}

.content-deal-countdown canvas {
    display: none
}

.content-deal-countdown .time_circles {
    margin: auto;
    width: 70px
}

.content-deal-countdown .time_circles>div {
    background: rgba(255, 255, 255, .1);
    border-radius: 50%;
    height: 70px;
    left: 0 !important;
    margin-bottom: 6px;
    position: relative;
    top: 0 !important;
    width: 70px !important
}

.content-deal-countdown .time_circles>div::after {
    border-radius: 50%;
    bottom: 0;
    content: "";
    display: block;
    height: 50px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50px
}

.content-deal-countdown .time_circles>div .number {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: -8px;
    padding-top: 18px
}

.content-deal-countdown .time_circles>div .text {
    display: block;
    font-size: 10px
}

.deal-week-countdown>label {
    margin: 0 0 20px
}

.staff-slider .item {
    padding: 0 15px
}

.featured-staff {
    text-align: center
}

.staff-info>h3 {
    font-size: 16px;
    margin: 0 0 5px
}

.staff-thumb {
    margin: auto auto 25px;
    max-width: 200px;
    overflow: hidden;
    position: relative
}

.staff-info>span {
    color: #888;
    display: block;
    font-size: 16px;
    margin-bottom: 10px
}

.staff-info h3 a:hover {
    color: #333
}

.all-staff {
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin-top: 12px;
    padding: 0 25px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    text-transform: capitalize
}

.staff-social a,
.staff-thumb img {
    border-radius: 50%;
    position: relative;
    transition: all .5s ease-out 0s
}

.advert-home-info>h3,
.news-info a.readmore,
.news-info h3,
.newsletter-form>label,
.review-content>h3 {
    text-transform: uppercase
}

.all-staff:hover {
    background-color: #333;
    color: #fff
}

.home-staff {
    margin-bottom: 100px
}

.staff-thumb img {
    z-index: 9;
    -webkit-transition: all .5s ease-out 0s
}

.staff-thumb>a {
    display: block;
    margin: auto;
    max-width: 200px;
    position: relative
}

.staff-thumb:hover img {
    opacity: .5
}

.staff-social a {
    background: rgba(0, 0, 0, .5);
    color: #fff;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    opacity: 0;
    text-align: center;
    -webkit-transition: all .5s ease-out 0s;
    width: 30px
}

.staff-link:hover a,
.staff-thumb:hover .staff-social a {
    opacity: 1
}

.staff-social {
    left: 0;
    margin-top: -15px;
    position: absolute;
    top: 50%;
    width: 100%;
    z-index: 99
}

.staff-face {
    left: -100px
}

.staff-link {
    right: -100px
}

.staff-twit {
    transform: scale(6);
    -webkit-transform: scale(6)
}

.staff-thumb:hover .staff-social a.staff-twit {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.staff-thumb:hover .staff-social a.staff-face {
    left: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

.staff-thumb:hover .staff-social a.staff-link {
    right: 0;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
}

.advert-home-info {
    bottom: 40px;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 9
}

.adv-default>a::after,
.adv-default>a::before {
    bottom: 20px;
    left: 20px;
    right: 20px;
    top: 20px;
    z-index: 9;
    content: ""
}

.advert-home-info a {
    color: #fff
}

.advert-home-info>h3 {
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 10px
}

.advert-home-info>a.shopnow:hover {
    text-decoration: underline
}

.list-advert-home {
    margin-bottom: 70px;
    padding: 0 5px
}

.item-advert-home {
    margin: 0 -5px 30px;
    position: relative
}

.adv-default>a {
    display: block;
    overflow: hidden;
    position: relative
}

.item-advert-home:hover .adv-default img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.adv-default img {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 100%
}

.adv-default>a::before {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    border-top: 1px solid rgba(255, 255, 255, .5);
    position: absolute;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.adv-default>a::after,
.news-info {
    position: absolute;
    transition: all .5s ease-out 0s
}

.adv-default>a:hover::before {
    transform: scale(1)
}

.adv-default>a::after {
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-right: 1px solid rgba(255, 255, 255, .5);
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    -webkit-transition: all .5s ease-out 0s
}

.adv-default>a:hover::after {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.home-latest-news {
    margin-bottom: 100px
}

.latest-news-header {
    margin-bottom: 50px
}

.list-box-left {
    float: left;
    width: 44.78%
}

.list-box-right {
    float: left;
    width: 55.2%
}

.ver-box.news-box {
    float: left;
    width: 55.8%
}

.list-box-left .square-box.news-box {
    float: left;
    width: 44.2%
}

.hoz-box.news-box {
    float: left;
    width: 64.15%
}

.list-box-right .square-box.news-box {
    float: left;
    width: 35.85%
}

.news-info,
.news-thumb::before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0
}

.news-info {
    margin: auto;
    text-align: center;
    -webkit-transition: all .5s ease-out 0s;
    z-index: 99
}

.news-box {
    overflow: hidden;
    position: relative
}

.news-thumb::before {
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, .5);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    z-index: 9
}

.news-box:hover .news-info,
.news-box:hover .news-thumb::before {
    opacity: 1
}

.news-info h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 20px
}

.news-info li .lnr {
    margin-right: 5px
}

.news-info a.readmore {
    border: 2px solid #fff;
    display: block;
    font-size: 14px;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    width: 100px;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
    margin: 23px auto auto;
    color: #fff
}

.news-info h3 a {
    color: #fff;
    -webkit-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

.news-info ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.news-info li {
    color: #fff;
    display: inline-block;
    margin: 0 6px
}

.news-info li:first-child {
    border-right: 1px solid rgba(255, 225, 255, .5);
    padding-right: 11px
}

.news-info li a {
    color: #fff
}

.inner-news-info {
    padding: 0 40px;
    position: relative;
    z-index: 99
}

.news-info::after,
.news-info::before {
    bottom: 20px;
    content: "";
    left: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 9
}

.news-info::before {
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    border-top: 1px solid rgba(255, 255, 255, .5);
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.news-box:hover .news-info::before {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.news-info::after {
    border-left: 1px solid rgba(255, 255, 255, .5);
    border-right: 1px solid rgba(255, 255, 255, .5);
    -webkit-transform: scale(1, 0);
    transform: scale(1, 0);
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s
}

.news-box:hover .news-info::after {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.news-thumb img {
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    width: 100%
}

.news-thumb {
    overflow: hidden;
    position: relative
}

.news-box:hover .news-thumb img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.home-video {
    margin: auto auto 90px;
    max-width: 1024px;
    width: 100%
}

.list-download {
    margin-top: 100px;
    text-align: center
}

.list-download a {
    display: inline-block;
    font-weight: 700;
    color: #333;
    margin: 0 50px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.list-download a::before {
    content: "";
    display: inline-block;
    font-family: linearicons;
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    margin-right: 15px;
    text-align: center;
    width: 60px;
    vertical-align: middle;
    font-weight: 400;
    border: 1px solid #3b3b3b;
    border-radius: 50%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.list-download a:hover::before {
    color: #fff
}

#video-intro .vjs-big-play-button {
    background: 0 0;
    border: 2px solid #fff;
    border-radius: 50%;
    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100px
}

#video-intro .vjs-big-play-button::before {
    border-bottom: 19px solid transparent;
    border-left: 20px solid #fff;
    border-top: 19px solid transparent;
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 0
}

.customer-review {
    background: url(../images/1440px/website/images/home1/bg-test.jpg) center center no-repeat rgba(0, 0, 0, 0);
    padding: 100px 0
}

.customer-review-slider {
    color: #fff;
    margin: auto;
    max-width: 600px;
    text-align: center
}

.footer-box a,
.footer-box>p {
    color: #ccc
}

.review-content>h3 {
    font-size: 16px;
    margin: 0 0 17px
}

.customer-info,
.review-content>p {
    margin: 0
}

.review-content>p::before {
    content: "";
    font-family: fontawesome;
    font-size: 10px;
    margin-right: 7px;
    position: relative;
    top: -3px
}

.review-content>p::after {
    content: "";
    font-family: fontawesome;
    font-size: 10px;
    margin-left: 5px
}

.customer-thumb {
    margin-top: 20px
}

.customer-thumb>a {
    background: #e6e6e6;
    border: 1px solid #121212;
    border-radius: 50%;
    display: inline-block;
    height: 43px;
    margin: 0 10px;
    opacity: .5;
    width: 43px;
    position: relative
}

.customer-thumb>a img {
    border-radius: 50%;
    width: 100%
}

.customer-thumb>a.active {
    opacity: 1
}

.customer-thumb>a.active::before {
    border-radius: 50%;
    content: "";
    display: block;
    left: 50%;
    margin-left: -3px;
    position: absolute;
    top: -12px;
    z-index: 99;
    width: 6px;
    height: 6px
}

.copyright,
.footer-menu,
.footer-menu>li,
.payment-method>a,
.social-footer>a {
    display: inline-block
}

.footer {
    padding: 50px 0 40px
}

.footer-box>ul {
    list-style: none;
    margin: 0 0 17px;
    padding: 0
}

.footer-box li {
    margin-bottom: 10px
}

.footer-address {
    background: url(../images/1440px/website/images/home1/foma1.png) left center no-repeat rgba(0, 0, 0, 0)
}

.footer-contact-box p {
    margin-bottom: 14px;
    padding-left: 45px
}

.list-footer-box {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin-bottom: 50px;
    padding-bottom: 25px;
    position: relative
}

.back-to-top,
.back-to-top::after {
    border-radius: 50%;
    position: absolute;
    bottom: 0
}

.back-to-top {
    height: 40px;
    left: 50%;
    margin-bottom: -20px;
    margin-left: -20px;
    width: 40px
}

.back-to-top::after {
    content: "";
    font-family: linearicons;
    font-size: 20px;
    font-weight: 700;
    height: 32px;
    left: 0;
    line-height: 45px;
    margin: auto;
    right: 0;
    text-align: center;
    top: 0;
    width: 32px
}

.footer-phone {
    background: url(../images/1440px/website/images/home1/foma2.png) left center no-repeat rgba(0, 0, 0, 0)
}

.footer-email {
    background: url(../images/1440px/website/images/home1/foma3.png) left center no-repeat rgba(0, 0, 0, 0)
}

.social-footer>a {
    border: 1px solid rgba(255, 255, 255, .1);
    border-radius: 50%;
    color: #ccc;
    font-size: 14px;
    height: 38px;
    line-height: 38px;
    margin-right: 8px;
    text-align: center;
    width: 38px
}

.social-footer {
    font-size: 0;
    margin-bottom: 40px
}

.newsletter-form {
    float: right;
    margin-bottom: 40px;
    max-width: 500px;
    padding-left: 115px;
    position: relative;
    width: 100%
}

.newsletter-form>label {
    color: #fff;
    left: 0;
    margin: 0;
    position: absolute;
    top: 8px
}

.newsletter-form>form {
    border-radius: 4px;
    padding-right: 100px;
    position: relative
}

.newsletter-form input[type=text] {
    background: rgba(255, 255, 255, .1);
    border: none;
    border-radius: 4px;
    color: #ccc;
    height: 40px;
    padding: 0 15px;
    width: 100%
}

.newsletter-form input[type=submit] {
    background: rgba(255, 255, 255, .1);
    border: none;
    border-radius: 4px;
    color: #fff;
    height: 40px;
    position: absolute;
    text-transform: capitalize;
    width: 105px;
    top: 0;
    right: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.copyright,
.footer-menu a {
    text-transform: uppercase;
    color: #fff;
    font-weight: 700
}

.copyright {
    margin-right: 20px;
    margin-top: 5px
}

.footer-menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.footer-menu>li {
    margin-right: 17px
}

.payment-method {
    float: right
}

.payment-method>a {
    margin-left: 10px;
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    top: 0
}

.payment-method>a:hover {
    top: -10px
}

.footer-box .readmore:hover {
    color: #fff
}

.title-footer {
    font-size: 15px;
    color: #fff;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 15px
}

.attr-filter h3,
.banner-shop-info,
.select-box,
.tool-box label,
.tool-box.showing-box>span,
.view-type>label,
.widget-title {
    text-transform: uppercase
}

.image-directnav .owl-theme .owl-controls .owl-buttons div {
    height: 83px;
    margin: -42px -10px 0;
    position: absolute;
    top: 50%;
    width: 55px
}

.image-directnav .owl-theme .owl-controls .owl-buttons div>span {
    display: none
}

.image-directnav .owl-theme .owl-controls .owl-buttons div.owl-prev {
    background: url(../images/1440px/website/images/home1/prev.png) center center no-repeat;
    left: 0
}

.image-directnav .owl-theme .owl-controls .owl-buttons div.owl-next {
    background: url(../images/1440px/website/images/home1/next.png) center center no-repeat;
    right: 0
}

.banner-shop-info {
    background: rgba(0, 0, 0, .5);
    bottom: 0;
    color: #fff;
    font-weight: 700;
    left: 0;
    margin: auto;
    max-width: 520px;
    padding: 25px 15px;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100%
}

.banner-shop-info>h2 {
    font-weight: 700;
    margin: 0 0 8px
}

.banner-shop-info>p {
    margin: 0
}

.banner-shop {
    margin-top: 10px
}

.content-shop {
    padding: 30px 0 80px
}

.widget {
    border-top: 3px solid #3b3b3b;
    margin-bottom: 25px
}

.widget-title {
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 15px;
    padding: 17px 0 15px
}

.widget.widget-shop-cat ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.widget.widget-shop-cat ul li {
    position: relative
}

.widget.widget-shop-cat a {
    color: #3b3b3b;
    display: block;
    position: relative
}

.widget.widget-shop-cat>.widget-content>ul>li>a {
    font-weight: 700;
    text-transform: uppercase
}

.widget.widget-shop-cat>.widget-content>ul>li {
    margin-bottom: 10px
}

.widget.widget-shop-cat>.widget-content>ul>li:last-child {
    margin: 0
}

.widget.widget-shop-cat>.widget-content>ul>li.has-sub-cat>a::after {
    content: "+";
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 400
}

.widget.widget-shop-cat>.widget-content>ul>li.has-sub-cat.active>a::after {
    content: "-"
}

.widget.widget-shop-cat>.widget-content>ul>li>ul>li {
    margin-top: 10px
}

.widget.widget-shop-cat>.widget-content>ul>li>ul>li>a::before {
    color: #888;
    content: "";
    display: inline-block;
    font-family: linearicons;
    font-size: 7px;
    margin-right: 7px;
    vertical-align: middle
}

.widget.widget-shop-cat>.widget-content>ul>li>ul {
    display: none
}

.widget.widget-shop-cat>.widget-content>ul>li.has-sub-cat.active>ul {
    display: block
}

.attr-filter h3 {
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 15px
}

.attr-filter-price .ui-slider-handle.ui-state-default.ui-corner-all {
    background: #000;
    border: none;
    border-radius: 50%;
    height: 10px;
    margin: 0 -5px;
    top: -4px;
    width: 10px
}

.attr-filter-price .ui-slider-range.ui-widget-header.ui-corner-all {
    background: #3b3b3b;
    height: 1px
}

.attr-filter-price .ui-slider.ui-slider-horizontal.ui-widget.ui-widget-content.ui-corner-all {
    background: #e5e5e5;
    border: none;
    height: 1px
}

.attr-filter.attr-filter-price>p {
    margin: 17px 0
}

.attr-filter.attr-filter-price label {
    font-weight: 400;
    margin: 0 5px 0 0
}

.attr-filter.attr-filter-price input {
    border: none;
    font-weight: 700
}

.attr-filter.attr-filter-price {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px
}

.attr-filter ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.attr-filter.attr-filter-color>ul {
    margin: 20px 0
}

.attr-filter.attr-filter-color li {
    display: inline-block;
    margin-right: 7px
}

.attr-filter.attr-filter-color li:last-child {
    margin: 0
}

.attr-filter.attr-filter-color a {
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    display: block;
    height: 36px;
    position: relative;
    width: 36px
}

.attr-filter.attr-filter-color span {
    border-radius: 50%;
    bottom: 0;
    display: block;
    height: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px
}

.attr-filter.attr-filter-color {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 20px
}

.attr-filter.attr-filter-size li {
    display: inline-block;
    margin-right: 7px
}

.attr-filter.attr-filter-size a {
    background: #f1f1f1;
    border-radius: 50%;
    color: #3b3b3b;
    display: block;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px
}

.attr-filter.attr-filter-size li.active a {
    color: #fff
}

.attr-filter.attr-filter-size>.widget-content>ul {
    margin: 20px 0
}

.attr-filter.attr-filter-color li.active span {
    height: 100%;
    width: 100%
}

.attr-filter.attr-filter-color li.active a::after {
    color: #fff;
    content: "";
    display: block;
    font-family: linearicons;
    left: 10px;
    position: absolute;
    text-align: center;
    top: 9px
}

.widget.widget-recent-product>.widget-content>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.widget.widget-recent-product li {
    border-bottom: 1px solid #e5e5e5;
    display: table;
    margin-bottom: 20px;
    padding-bottom: 20px;
    width: 100%
}

.widget.widget-recent-product li:last-child {
    border: none;
    margin: 0
}

.widget.widget-recent-product li .product-thumb {
    display: table-cell;
    width: 100px
}

.widget.widget-recent-product li .product-thumb img {
    max-width: 100px
}

.widget.widget-recent-product li .product-info {
    display: table-cell;
    padding-left: 20px;
    vertical-align: top
}

.zoom-image {
    position: relative;
    overflow: hidden
}

.zoom-image a {
    display: block
}

.zoom-image img {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 100%
}

.zoom-image:hover img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.view-type>label {
    color: #888;
    margin: 0 15px 0 0
}

.view-type a {
    color: #888;
    display: inline-block;
    font-size: 30px;
    margin-right: 5px;
    vertical-align: middle
}

.view-type a.active {
    color: #3b3b3b
}

.wrap-pagibar {
    float: right;
    margin-top: 10px
}

.tool-box {
    border-right: 1px solid #e5e5e5;
    display: inline-block;
    margin-right: 17px;
    padding-right: 20px;
    vertical-align: top
}

.tool-box:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.tool-box label {
    color: #888;
    margin: 0 10px 0 0
}

.tool-box.showing-box>span {
    font-weight: 700
}

.select-box {
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(../images/grid/bg-select.png) right center no-repeat rgba(0, 0, 0, 0);
    font-weight: 700;
    padding-right: 25px
}

.select-box option {
    font-weight: 400;
    text-transform: inherit
}

.list-product {
    list-style: none;
    margin-bottom: 0;
    padding: 0
}

.product-item {
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.grid-view .product-item:hover {
    border-color: #333
}

.sort-pagibar {
    margin: -5px 0 10px
}

.product-item .product-info {
    text-align: center
}

.product-thumb .product-more-link {
    left: 0;
    margin-top: -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 100%;
    z-index: 99;
    opacity: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.product-thumb:hover .product-more-link {
    opacity: 1
}

.product-item .product-more-link span.lnr {
    background: 0 0;
    color: #3b3b3b;
    font-size: 13px;
    margin: 0;
    opacity: 1;
    position: static;
    visibility: visible;
    width: auto
}

.product-item .product-more-link span.lnr::after {
    display: none
}

.product-item .product-more-link a:hover span.lnr {
    color: #fff
}

.product-item .product-thumb {
    margin: 10px 10px 20px
}

.product-item .product-thumb-link::before {
    background: 0 0;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0
}

.product-item .product-thumb:hover .product-thumb-link::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.product-thumb .product-more-link a {
    -webkit-transform: scale(0);
    transform: scale(0)
}

.product-thumb:hover .product-more-link a {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.shop-paginav a {
    color: #3b3b3b;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    width: 40px
}

.shop-paginav>a.current-page {
    background: #3b3b3b;
    color: #fff;
    font-weight: 700
}

.shop-paginav a.next-page {
    border: 1px solid #e5e5e5;
    color: #666
}

.grid-view-2-col .product-thumb {
    border: 1px solid #e5e5e5;
    margin: 0 0 20px;
    padding: 10px
}

.grid-view-2-col .product-item {
    border: none
}

.grid-view-2-col .product-thumb::before {
    display: none
}

.grid-view-2-col .product-thumb-link::before {
    width: 100%;
    height: 100%;
    opacity: 1;
    top: 100%;
    left: 100%
}

.grid-view-2-col .product-thumb:hover .product-thumb-link::before {
    top: 0;
    left: 0
}

.grid-view-2-col .product-thumb:hover .quick-link {
    height: 40px;
    line-height: 40px;
    width: 40px;
    opacity: 1
}

.grid-view-2-col .quick-link {
    background: #fff;
    bottom: 10px;
    display: block;
    height: 0;
    line-height: 0;
    opacity: 0;
    position: absolute;
    right: 10px;
    text-align: center;
    transition: all .3s ease-out 0s;
    width: 0;
    z-index: 99
}

.widget.widget-manufacturer>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.widget.widget-manufacturer a {
    color: #3b3b3b;
    display: block;
    padding-right: 20px;
    position: relative
}

.widget.widget-manufacturer span {
    color: #888;
    position: absolute;
    right: 0
}

.widget.widget-manufacturer a::before {
    content: "";
    display: inline-block;
    font-family: linearicons;
    font-size: 6px;
    margin-right: 10px;
    vertical-align: middle
}

.widget.widget-manufacturer li {
    margin-bottom: 10px
}

.widget.widget-manufacturer li:last-child {
    margin: 0
}

.widget-advert-slider.directnav-slider {
    background: #fff;
    border: 1px solid #e5e5e5;
    margin-top: -16px;
    padding: 10px;
    text-align: center
}

.wd-adv-info>h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 5px;
    text-transform: uppercase
}

.wd-adv-info>p {
    color: #666
}

.wd-adv-info h3 a {
    color: #3b3b3b
}

.wd-adv-info .shopnow {
    background: #3b3b3b;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    transition: all .3s ease-out 0s
}

.wd-adv-info {
    padding-top: 18px
}

.widget-advert-slider .owl-carousel .owl-wrapper-outer {
    padding-bottom: 2px
}

.directnav-slider.widget-advert-slider .owl-theme .owl-controls .owl-buttons div {
    font-size: 13px;
    height: 30px;
    line-height: 30px;
    margin: -15px 0 0;
    top: 120px;
    width: 30px
}

.directnav-slider.widget-advert-slider .owl-theme .owl-controls .owl-buttons div span.lnr {
    display: block;
    height: 30px;
    line-height: 30px
}

.directnav-slider.widget-advert-slider .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: -30px
}

.directnav-slider.widget-advert-slider .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -30px
}

.directnav-slider.widget-advert-slider:hover .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: 0
}

.directnav-slider.widget-advert-slider:hover .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0
}

.directnav-slider.widget-advert-slider .owl-theme {
    overflow: hidden
}

.list-view .product-item {
    border: none
}

.list-view .product-item .product-thumb {
    border: 1px solid #e5e5e5;
    margin: 0
}

.list-view .product-item .product-info {
    text-align: left
}

.list-view .product-item .desc {
    margin-top: 20px
}

.list-view .product-extra-link {
    margin-top: 22px
}

.product-extra-link.style2 a {
    color: #3b3b3b;
    display: inline-block;
    margin-left: 16px;
    transition: all .3s ease-out 0s;
    vertical-align: middle
}

.product-extra-link.style2 a.addcart-link {
    background: #3b3b3b;
    border-radius: 4px;
    color: #fff;
    height: 36px;
    letter-spacing: 1px;
    line-height: 36px;
    padding-left: 53px;
    position: relative;
    text-align: left;
    width: 156px;
    margin-left: 0
}

.grid-view-2-col .product-extra-link {
    margin-top: 15px
}

.product-extra-link.style2 a.addcart-link .fa,
.product-extra-link.style2 a.addcart-link .lnr {
    border-right: 1px solid rgba(255, 255, 255, .1);
    height: 100%;
    left: 0;
    line-height: 38px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 40px
}

.product-extra-link.style2 a .fa,
.product-extra-link.style2 a .lnr {
    margin-right: 10px
}

.product-extra-link.style2 a.addcart-link:hover {
    color: #fff
}

.list-view .quick-link {
    background: #fff;
    bottom: 0;
    color: #3b3b3b;
    display: block;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 40px;
    z-index: 99;
    opacity: 0
}

.list-view .quick-link:hover {
    color: #fff
}

.list-view .product-thumb:hover .quick-link {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
}

.main-detail {
    background: #f4f4f4;
    margin-top: 10px;
    padding: 30px 0 40px
}

.detail-gallery .bxslider {
    list-style: none
}

.detail-gallery .bx-pager>a {
    border: 1px solid #e5e5e5;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
    display: inline-block;
    font-size: 13px;
    margin: 0 5px;
    position: relative;
    width: 70px
}

.detail-gallery .bx-pager {
    font-size: 0;
    text-align: center
}

.detail-gallery .bx-pager>a.active::before {
    background: rgba(0, 0, 0, .7);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.detail-gallery .bx-pager>a.active::after {
    color: #fff;
    content: "";
    font-family: linearicons;
    height: 100%;
    left: 0;
    line-height: 68px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99
}

.detail-gallery .bx-controls-direction a {
    color: #3b3b3b;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -15px;
    position: absolute;
    top: 50%
}

.detail-gallery .bx-controls-direction a.bx-prev {
    left: 13px
}

.detail-gallery .bx-controls-direction a.bx-next {
    right: 13px
}

.detail-gallery .bx-controls-direction a .lnr {
    display: inline-block;
    font-size: 30px;
    vertical-align: text-bottom
}

.detail-gallery .bx-controls-direction a.bx-next .lnr {
    margin-left: -10px
}

.detail-gallery .bx-controls-direction a.bx-prev .lnr {
    margin-right: -10px
}

.detail-gallery {
    background: #fff;
    margin-bottom: -70px;
    position: relative
}

.detail-gallery .bx-pager {
    margin-top: -30px
}

.detail-info {
    padding-left: 20px;
    padding-top: 35px
}

.title-detail {
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase
}

.product-review {
    list-style: none;
    margin: 0 0 10px;
    padding: 0
}

.product-review>li {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle
}

.product-review a {
    border-left: 1px solid #888;
    color: #3b3b3b;
    display: block;
    height: 14px;
    line-height: 14px;
    padding-left: 9px
}

.product-code {
    list-style: none;
    margin: 0 0 20px;
    padding: 0
}

.product-code>li {
    display: inline-block
}

.product-code>li label {
    font-weight: 400;
    margin: 0 10px 0 0
}

.product-code span {
    color: #888
}

.product-code li:first-child span {
    border-right: 1px solid #b4b4b4;
    color: #1ea200;
    display: inline-block;
    height: 14px;
    line-height: 14px;
    margin-right: 6px;
    padding-right: 10px
}

.attr-detail-box .product-extra-link.style2 a,
.info-qty {
    height: 40px;
    line-height: 40px
}

.detail-info .desc {
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 30px;
    padding: 20px 0
}

.label-attr {
    left: 0;
    margin: 0;
    position: absolute;
    text-transform: uppercase;
    top: 10px
}

.attr-detail-box,
.attr-detail-box select,
.info-qty {
    position: relative;
    width: 100%
}

.attr-detail-box {
    margin-bottom: 10px;
    max-width: 490px;
    padding-left: 90px
}

.label-attr sup {
    color: #f65251
}

.attr-detail-box select {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: url(../images/detail/select-arrow.png) right 15px center no-repeat #fff;
    border: 1px solid #ccc;
    color: #888;
    font-style: italic;
    height: 40px;
    padding: 0 15px
}

.info-qty,
.list-social-product {
    background: #fff;
    text-align: center
}

.attr-detail-box .product-price {
    margin: 0;
    padding: 6px 0
}

.attr-detail-box .product-extra-link.style2,
.info-qty {
    display: inline-block;
    margin-bottom: 20px
}

.info-qty {
    border: 1px solid #ccc;
    margin-right: 4px;
    max-width: 235px;
    padding: 0 40px;
    vertical-align: top
}

.qty-down,
.qty-up {
    height: 100%;
    position: absolute;
    top: 0;
    width: 40px;
    color: #888;
    display: inline-block
}

.qty-down {
    border-right: 1px solid #ccc;
    left: 0
}

.qty-up {
    border-left: 1px solid #ccc;
    right: 0
}

.qty-val {
    color: #888
}

.product-detail-extra-link>a {
    color: #3b3b3b;
    margin-right: 27px;
    display: inline-block
}

.share-social-product {
    display: inline-block;
    position: relative
}

.list-social-product {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, .1);
    list-style: none;
    padding: 5px 0;
    position: absolute;
    top: 100%;
    margin-top: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    z-index: 999;
    left: 0;
    width: 140px
}

.share-social-product:hover .list-social-product {
    opacity: 1;
    margin-top: 0;
    visibility: visible
}

.list-social-product li a {
    margin: 5px auto;
    text-align: center
}

.list-social-product a i.fa {
    margin: 0
}

.product-detail-extra-link a .lnr {
    margin-right: 10px
}

.tab-detail {
    margin-top: 90px
}

.title-tab-detail>ul {
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center
}

.title-tab-detail li {
    display: inline-block;
    font-size: 13px;
    padding: 0 20px
}

.title-tab-detail li a {
    color: #3b3b3b;
    display: block;
    font-weight: 700;
    position: relative;
    text-transform: uppercase
}

.title-tab-detail {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 25px;
    padding-bottom: 13px
}

.title-tab-detail li.active a::after {
    background: #3b3b3b;
    content: "";
    height: 3px;
    left: 0;
    position: absolute;
    top: -18px;
    width: 100%
}

.inner-tab-detail>p {
    margin-bottom: 40px
}

.inner-tab-detail ul {
    list-style: none;
    margin: 0 0 20px;
    padding: 0
}

.inner-tab-detail ul li a {
    color: #3b3b3b
}

.inner-tab-detail ul li a::before {
    content: "";
    display: inline-block;
    font-family: linearicons;
    font-size: 6px;
    margin-right: 10px;
    position: relative;
    top: -2px
}

.latest-product-detail>h2 {
    font-weight: 700;
    margin: 0 0 20px;
    text-align: center;
    text-transform: uppercase
}

.latest-product-detail {
    margin: 35px -15px 20px
}

.latest-product-detail .item {
    padding: 0 15px
}

.product-detail-extra-link a:last-child {
    margin: 0
}

.latest-product-detail .owl-theme .owl-controls .owl-buttons div {
    color: #3b3b3b;
    font-weight: 700;
    margin-top: -15px;
    position: absolute;
    text-transform: uppercase;
    top: 50%;
    opacity: 0
}

.latest-product-detail:hover .owl-theme .owl-controls .owl-buttons div {
    opacity: 1
}

.latest-product-detail .owl-theme .owl-controls .owl-buttons div .lnr {
    display: inline-block;
    font-size: 30px;
    vertical-align: text-bottom
}

.latest-product-detail .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -20px
}

.latest-product-detail .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: -20px
}

.latest-product-detail .owl-theme .owl-controls .owl-buttons div.owl-next .lnr {
    margin-left: -10px
}

.latest-product-detail .owl-theme .owl-controls .owl-buttons div.owl-prev .lnr {
    margin-right: -10px
}

.detail-gallery .saleoff-label {
    top: 0;
    z-index: 9
}

.main-detail.style2 {
    background: 0 0
}

.attr-detail .attr-filter.attr-filter-color {
    border: none;
    margin: 0 0 13px
}

.attr-detail .attr-filter.attr-filter-color>ul {
    margin: 0
}

.attr-detail .attr-filter.attr-filter-size>ul {
    margin: 0 0 20px
}

.info-qty.style2 .qty-down,
.info-qty.style2 .qty-up {
    position: static;
    border: none
}

.style2.info-qty {
    width: 200px
}

.detail-gallery.style3 {
    margin: 0;
    padding-right: 45px
}

.gallery-slider {
    border: 1px solid #e5e5e5;
    position: relative
}

.gallery-slider .new-label {
    left: 10px;
    top: 10px
}

.gallery-slider .saleoff-label {
    right: 10px;
    top: 10px
}

.gallery-thumb a {
    border: 1px solid #e5e5e5;
    display: block;
    width: 70px;
    margin-bottom: 10px
}

.wrap-gallery-thumb {
    position: absolute;
    right: -35px;
    top: 55px;
    width: 70px
}

.gallery-thumb>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.gallery-control {
    font-size: 0
}

.gallery-control a {
    background: #3b3b3b;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    transition: all .3s ease-out 0s;
    width: 35px
}

.main-detail.style3 {
    background: 0 0;
    margin: 0;
    padding: 40px 0
}

.tab-detail.style3 {
    margin: 0;
    padding-left: 290px;
    position: relative
}

.title-tab-detail.style3 li {
    display: block;
    margin: 0 0 18px;
    padding: 0;
    text-align: left
}

.tab-detail.style3 .content-tab-detail {
    padding-left: 60px
}

.title-tab-detail.style3 li.active a::after {
    border-radius: 6px;
    height: 20px;
    left: 100%;
    margin-left: -2px;
    top: 0;
    width: 6px
}

.title-tab-detail.style3 {
    border-bottom: medium none;
    border-right: 2px solid #e5e5e5;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    width: 290px
}

.latest-product-detail.upsell-product-detail {
    margin-top: -15px
}

.main-detail.style2 .product-price {
    margin-bottom: 20px;
    margin-top: -5px
}

.wrap-color-size {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding-bottom: 10px;
    padding-top: 30px
}

.gallery-thumb li.active a {
    position: relative;
    text-align: center
}

.gallery-thumb li.active a::before {
    background: rgba(0, 0, 0, .7);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    color: #fff;
    content: "";
    font-family: linearicons;
    line-height: 68px;
    z-index: 99
}

.post-image,
.post-thumb {
    position: relative
}

.post-image::before,
.post-item .post-desc::before {
    content: "";
    width: 100%
}

.main-detail.style4 {
    background: 0 0;
    margin: 0;
    padding: 40px 0 50px
}

.detail-gallery.style4 {
    margin-bottom: 0;
    margin-right: -40px;
    padding-left: 110px
}

.detail-gallery.style4 .wrap-gallery-thumb {
    left: -115px;
    top: -5px;
    width: 110px
}

.detail-gallery.style4 .gallery-thumb a {
    width: 100px;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .2);
    margin: 5px
}

.detail-gallery.style4 .gallery-slider {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .2)
}

.detail-gallery.style4 .gallery-thumb li.active a::before {
    line-height: 98px
}

.main-detail.style4 .detail-info {
    padding-left: 40px
}

.gallery-control.style4 {
    padding: 5px
}

.gallery-control.style4 a {
    height: 58px;
    line-height: 58px;
    width: 50px;
    font-size: 18px
}

.tab-detail.style4 {
    margin-top: 0
}

.title-tab-detail.style4 li.active a::after {
    display: none
}

.title-tab-detail.style4 li {
    margin: 0 5px;
    padding: 0
}

.title-tab-detail.style4 li a {
    border: 1px solid #e5e5e5;
    display: block;
    padding: 7px 23px
}

.title-tab-detail.style4 {
    padding-bottom: 0
}

.title-tab-detail.style4>ul {
    margin-bottom: -1px
}

.content-product-detail {
    margin-top: 40px
}

.main-detail.style5 {
    background: 0 0;
    margin: 0;
    padding: 0
}

.main-detail.style5 .bx-wrapper {
    border: 1px solid #e5e5e5
}

.main-detail.style5 .detail-gallery {
    margin: 0
}

.main-detail.style5 .bx-pager {
    margin: 20px 0 0
}

.main-detail.style5 .bx-pager>a {
    box-shadow: none
}

.main-detail.style5 .control-text {
    display: none
}

.main-detail.style5 .detail-gallery .bx-controls-direction a {
    margin-top: -50px;
    top: 100%
}

.main-detail.style5 .product-code {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 15px;
    padding-bottom: 22px
}

.title-tab-detail.style5>ul {
    margin-left: -20px;
    text-align: left
}

.latest-product-detail.style5 h2 {
    margin-left: 15px;
    text-align: left
}

.detail-info.style6 .inner-rating {
    background: url(../images/detail/rate.png) repeat-x rgba(0, 0, 0, 0)
}

.price-limited {
    margin-bottom: 8px;
    text-transform: uppercase
}

.price-limited span,
.price-limited>strong {
    margin-right: 13px
}

.detail-info.style6 .product-code li:first-child span {
    border: none
}

.attr-detail-box.attr-detail-product {
    margin-bottom: 0;
    max-width: none;
    padding-left: 135px
}

.attr-detail-product select {
    display: inline-block;
    max-width: 245px
}

.attr-detail-product .info-qty.style2 {
    display: inline-block;
    margin-left: -4px;
    padding: 0;
    width: 145px
}

.post-image {
    display: block;
    overflow: hidden
}

.post-thumb .post-image-zoom {
    background: rgba(255, 255, 255, .3);
    border-radius: 50%;
    bottom: 0;
    color: #fff;
    display: block;
    font-size: 16px;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 40px;
    z-index: 9
}

.post-thumb .post-image-zoom .lnr {
    line-height: 40px
}

.post-image::before {
    background: rgba(0, 0, 0, .5);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.post-thumb:hover .post-image-zoom,
.post-thumb:hover .post-image::before {
    -webkit-transform: scale(1);
    transform: scale(1)
}

.post-title {
    font-size: 13px;
    font-weight: 700;
    line-height: 20px;
    margin: 0 0 5px;
    text-transform: uppercase
}

.post-title>a {
    color: #3b3b3b;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.post-date {
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0
}

.post-date>li {
    color: #888;
    display: inline-block;
    font-size: 11px;
    height: 11px;
    line-height: 11px
}

.post-date>li:last-child {
    border-left: 1px solid #adadad;
    margin-left: 5px;
    padding-left: 5px
}

.post-item .post-info {
    border: 1px solid #e5e5e5;
    padding: 20px
}

.post-item {
    margin-bottom: 30px
}

.post-item .post-desc {
    border-top: 1px solid #e5e5e5;
    margin-bottom: 18px;
    margin-top: 18px;
    padding-top: 20px;
    position: relative
}

.post-date a {
    color: #3b3b3b
}

.post-item .post-desc::before {
    background: #e5e5e5;
    height: 1px;
    position: absolute;
    top: 5px
}

.readmore-comment-post {
    display: table;
    width: 100%
}

.readmore-comment-post a {
    color: #3b3b3b;
    font-weight: 700;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.readmore-comment-post .post-comment {
    text-align: right;
    display: table-cell
}

.readmore-comment-post .post-readmore {
    display: table-cell;
    width: 80px
}

.post-comment .lnr {
    margin-right: 5px
}

.widget-blog-cat ul li a {
    color: #333;
    display: block;
    font-weight: 700;
    padding-right: 20px;
    position: relative;
    text-transform: uppercase
}

.widget-blog-cat ul li a span {
    color: #888;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 0
}

.widget-blog-cat ul li {
    margin-bottom: 10px
}

.widget.widget-post-tabs .widget-title {
    display: none
}

.post-tab-title li a {
    color: #3b3b3b;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase
}

.post-tab-title {
    border-bottom: 1px solid #e5e5e5;
    margin: 0 0 20px;
    padding: 11px 20px 11px 0;
    position: relative
}

.post-tab-title li {
    margin-right: 20px;
    padding: 0
}

.post-tab-title::after {
    color: #a1a1a1;
    content: "";
    font-family: linearicons;
    font-size: 16px;
    position: absolute;
    right: 18px;
    top: 15px
}

.list-post-tabs>li {
    border-bottom: 1px solid #e5e5e5;
    display: table;
    margin-bottom: 20px;
    padding-bottom: 20px;
    width: 100%
}

.list-post-tabs li .post-thumb {
    display: table-cell;
    width: 100px
}

.list-post-tabs li .post-thumb img {
    width: 100px
}

.list-post-tabs li .post-info {
    display: table-cell;
    padding-left: 20px;
    vertical-align: top
}

.list-post-tabs li .post-title {
    text-transform: inherit
}

.intro-shop ul li a,
.intro-shop>h2,
.reply-comment-link,
.shop-cat-info>h3,
.shop-cat-intro>h2,
.shop-cat-title>a,
.title-single,
.widget.widget-faq h3 {
    text-transform: uppercase
}

.list-post-tabs li .post-date li {
    border: none;
    margin: 0;
    padding: 0
}

.list-post-tabs>li:last-child {
    border: none
}

.widget.widget-faq h3 {
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    padding: 13px 10px 11px 0;
    position: relative;
    cursor: pointer
}

.widget.widget-faq li {
    border-bottom: 1px solid #e5e5e5
}

.widget.widget-faq ul {
    margin-top: -15px
}

.widget.widget-faq p {
    display: none;
    margin-bottom: 15px
}

.widget.widget-faq li:last-child {
    border: none
}

.widget.widget-faq li.active p {
    display: block
}

.widget.widget-faq h3::after {
    content: "+";
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 13px
}

.widget.widget-faq li.active h3::after {
    content: "-"
}

.post-large-item .post-thumb {
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding: 10px
}

.post-format-date {
    left: 10px;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 90px
}

.post-format-date .post-date {
    color: #fff;
    font-size: 13px;
    padding-bottom: 2px
}

.post-format-date .post-date strong {
    display: block;
    font-size: 30px;
    margin: 0 0 -15px;
    position: relative;
    top: -2px
}

.post-large-image::before,
.post-medium-item .post-format-date {
    left: 0;
    top: 0
}

.post-format {
    background: #e5e5e5
}

.post-format .lnr {
    display: block;
    font-size: 30px;
    height: 60px;
    line-height: 60px
}

.post-large-item .post-title {
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 20px
}

.readmore-comment-post .post-info-total {
    text-align: right
}

.post-info-total {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0
}

.post-info-total>li {
    border-left: 1px solid #a9a9a9;
    color: #888;
    display: inline-block;
    font-size: 13px;
    height: 13px;
    line-height: 13px;
    margin-left: 5px;
    padding-left: 5px
}

.post-info-total>li a {
    font-weight: 400;
    color: #3b3b3b
}

.main-content-single h2,
.title-single {
    font-weight: 700
}

.post-large-item {
    margin-bottom: 40px
}

.post-info-total>li:first-child {
    border: none;
    margin: 0;
    padding: 0
}

.post-large-item .post-desc {
    margin-bottom: 25px
}

.post-large-image {
    display: block;
    overflow: hidden;
    position: relative
}

.post-large-image::after,
.post-large-image::before {
    background: rgba(0, 0, 0, .2);
    height: 0;
    position: absolute;
    width: 0;
    content: "";
    transition: all .3s ease-out 0s
}

.post-large-image::after {
    bottom: 0;
    right: 0
}

.post-large-image:hover::after,
.post-large-image:hover::before {
    width: 100%;
    height: 100%
}

.post-medium-item .post-thumb {
    margin-right: -25px;
    padding-left: 100px
}

.post-medium-item {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding-bottom: 30px
}

.post-medium-image>img {
    transition: all .3s ease-out 0s;
    width: 100%
}

.post-medium-image {
    display: block;
    overflow: hidden
}

.post-medium-image:hover img {
    -webkit-transform: scale(1.15);
    transform: scale(1.15)
}

.post-medium-item .post-info-total>li a {
    color: #3b3b3b
}

.post-medium-item .post-info-total {
    font-size: 0;
    margin-top: 10px;
    text-align: left
}

.post-medium-item .post-desc {
    border-top: 1px solid #e5e5e5;
    line-height: 20px;
    margin-bottom: 17px;
    margin-top: 17px;
    padding-top: 13px
}

.post-medium-item .post-info {
    padding-left: 15px
}

.list-medium {
    margin-bottom: 0
}

.post-medium-item .post-title {
    font-size: 16px
}

.post-medium-item .post-readmore {
    color: #3b3b3b;
    font-weight: 700
}

.content-single {
    padding: 30px 0 40px
}

.single-post-thumb {
    border: 1px solid #e5e5e5;
    padding: 10px
}

.title-single {
    margin-bottom: 20px;
    margin-top: 35px
}

.main-content-single .post-info-total {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 23px;
    padding-bottom: 27px
}

.main-content-single p {
    color: #444;
    margin-bottom: 35px
}

.main-content-single .block-quote {
    border: 1px solid #e5e5e5;
    color: #888;
    margin-bottom: 50px;
    margin-top: 40px;
    padding: 25px 25px 25px 100px;
    position: relative
}

.main-content-single .block-quote::before {
    background: url(../images/blog/quote.png) center center no-repeat;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 70px
}

.directnav-slider .item img {
    width: 100%
}

.directnav-slider .wrap-item {
    overflow: hidden
}

.directnav-slider.single-slider .owl-theme .owl-controls .owl-buttons div {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-top: -15px;
    font-size: 13px
}

.directnav-slider.single-slider .owl-theme .owl-controls .owl-buttons div span.lnr {
    display: block;
    height: 30px;
    line-height: 30px
}

.single-slider {
    margin-bottom: 35px;
    padding-right: 20px
}

.unorder-list {
    margin: 0;
    padding: 0 0 0 15px
}

.unorder-list li {
    font-size: 10px;
    margin-bottom: 2px;
    padding-left: 10px
}

.unorder-list li a {
    color: #888;
    font-size: 13px
}

.single-tabs-share label {
    color: #888;
    font-weight: 400;
    margin: 0 5px 0 0
}

.single-post-tabs>a {
    color: #3b3b3b
}

.single-post-social {
    margin-top: -10px;
    text-align: right
}

.single-post-social>a {
    border: 1px solid #e5e5e5;
    border-radius: 50%;
    color: #3b3b3b;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin-left: 4px;
    text-align: center;
    transition: all .3s ease-out 0s;
    width: 40px
}

.single-post-social>a:hover {
    color: #fff
}

.single-tabs-share {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 45px;
    padding-bottom: 35px
}

.single-post-author {
    display: table
}

.post-author-thumb {
    display: table-cell;
    width: 100px
}

.post-author-thumb .zoom-image a {
    border: 1px solid #e5e5e5;
    padding: 10px
}

.single-post-author .zoom-image img {
    width: 80px
}

.post-author-info {
    display: table-cell;
    padding-left: 30px;
    vertical-align: top
}

.post-author-info>ul {
    margin: 0 0 5px;
    padding: 0
}

.post-author-info li {
    border-right: 1px solid #999;
    color: #999;
    display: inline-block;
    height: 14px;
    line-height: 14px;
    margin-right: 5px;
    padding-right: 8px
}

.post-author-info li:last-child {
    border: none
}

.post-author-info li a {
    color: #3b3b3b;
    font-weight: 700
}

.single-related-post>h2 {
    margin-bottom: 40px
}

.item-single-related-post {
    padding: 0 15px
}

.single-related-post-slider {
    margin: 0 -15px
}

.single-related-post-slider .owl-theme .owl-controls .owl-buttons div {
    background-color: #3b3b3b;
    border-radius: 0;
    height: 30px;
    line-height: 30px;
    margin: 0;
    opacity: 1;
    padding: 0;
    text-align: center;
    width: 30px;
    vertical-align: top;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.single-related-post-slider .owl-theme .owl-controls .owl-buttons div.owl-next {
    margin-left: 1px
}

.single-related-post-slider .owl-theme .owl-controls .owl-buttons div .lnr {
    line-height: 30px
}

.single-related-post-slider .owl-theme .owl-controls {
    margin-top: 10px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: -85px
}

.leave-comment-link::before {
    border: 1px solid #3b3b3b;
    border-radius: 50%;
    content: "";
    display: inline-block;
    font-family: fontawesome;
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    text-align: center;
    width: 30px
}

.header-post-comment .leave-comment-link {
    color: #3b3b3b;
    position: absolute;
    right: 0;
    top: 0
}

.header-post-comment {
    margin-bottom: 47px;
    position: relative
}

ol.list-post-comment {
    list-style: none;
    margin: 0;
    padding: 0
}

.item-single-post-comment {
    display: table;
    margin-bottom: 30px;
    width: 100%
}

.single-post-comment-thumb {
    display: table-cell;
    padding-bottom: 30px;
    padding-right: 30px;
    width: 130px
}

.single-post-comment-thumb .zoom-image img {
    width: 100px
}

.single-post-comment-thumb .zoom-image a {
    border: 1px solid #e5e5e5;
    display: inline-block;
    padding: 10px
}

.single-post-comment-info {
    border-bottom: 1px solid #e5e5e5;
    display: table-cell;
    vertical-align: top
}

.comment-author {
    margin-bottom: 8px;
    position: relative
}

.reply-comment-link {
    color: #3b3b3b;
    position: absolute;
    right: 0;
    top: 0
}

.single-post-comment-info>p {
    color: #888;
    margin-bottom: 25px
}

.post-date-author {
    margin: 0;
    padding: 0
}

.post-date-author>li {
    border-right: 1px solid #999;
    color: #999;
    display: inline-block;
    height: 14px;
    line-height: 14px;
    margin-right: 5px;
    padding-right: 10px
}

.post-date-author>li a {
    color: #3b3b3b;
    font-weight: 700
}

.post-date-author>li:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.list-post-comment>li>ul {
    list-style: none;
    margin-left: 130px;
    padding: 0
}

.form-leave-comment.form-contact {
    margin-top: 37px
}

.form-contact input[type=text] {
    border: 1px solid #e5e5e5;
    color: #888;
    height: 42px;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%
}

.form-contact textarea {
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding: 20px;
    width: 100%
}

.form-contact input[type=submit] {
    background: #3b3b3b;
    border: none;
    color: #fff;
    height: 40px;
    width: 155px;
    border-radius: 4px
}

.shop-cat-info .shopnow,
.single-post-control a {
    border-radius: 4px;
    height: 40px;
    line-height: 40px
}

.single-post-control {
    margin-top: 35px
}

.single-post-control a {
    background: #333;
    color: #fff;
    font-weight: 700;
    padding: 0 30px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.shop-cat-info,
.shop-cat-title {
    transition: all .5s ease-out 0s;
    left: 0;
    bottom: 0
}

.single-post-control a .lnr {
    font-size: 30px;
    vertical-align: text-bottom
}

.single-post-control a.prev-post .lnr {
    margin-right: -10px
}

.single-post-control a.next-post .lnr {
    margin-left: -10px
}

.single-post-comment {
    margin-bottom: 42px
}

.intro-shop {
    background: url(../images/shop/bg-shop.png) center center no-repeat rgba(0, 0, 0, 0);
    margin-bottom: 45px;
    padding: 40px 50px;
    background-size: auto 100%
}

.shop-cat-info,
.shop-cat-title>a {
    background: rgba(0, 0, 0, .5);
    color: #fff
}

.shop-content {
    padding: 10px 0 60px
}

.intro-shop>h2 {
    font-weight: 700;
    margin: 0 0 15px
}

.intro-shop ul li a {
    font-weight: 700
}

.intro-shop ul {
    margin-bottom: 0;
    margin-top: 20px
}

.shop-cat-intro>h2 {
    font-weight: 700;
    margin: 0 0 20px
}

.shop-cat-intro {
    margin-bottom: 45px
}

.item-shop-cat {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden
}

.shop-cat-title {
    display: inline-block;
    margin: auto;
    padding: 0
}

.shop-cat-title>a {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    padding: 13px 20px
}

.shop-cat-thumb {
    position: relative;
    text-align: center
}

.shop-cat-thumb img {
    width: 100%
}

.shop-cat-title {
    position: absolute;
    right: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: all .5s ease-out 0s
}

.language-cart2 .language-box::after,
.top-nav.top-nav2 li::after {
    content: "-";
    position: absolute;
    right: -4px;
    top: 0
}

.shop-cat-info {
    padding: 25px 15px;
    position: absolute;
    text-align: center;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    width: 100%;
    -webkit-transition: all .5s ease-out 0s
}

.shop-cat-info>h3 {
    font-size: 13px;
    font-weight: 700;
    margin: 0 0 14px
}

.shop-cat-info>h3 a {
    border-bottom: 1px solid #fff;
    color: #fff;
    display: inline-block;
    padding-bottom: 15px
}

.shop-cat-info .shopnow {
    background: #171614;
    color: #fff;
    display: inline-block;
    margin-top: 5px;
    padding: 0 22px
}

.language-cart2 .currency-link,
.language-cart2 .language-link,
.top-nav.top-nav2 a {
    color: #3b3b3b
}

.item-shop-cat:hover .shop-cat-info {
    -webkit-transform: translateY(0);
    transform: translateY(0)
}

.item-shop-cat:hover .shop-cat-title {
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
}

.top-header.top-header2 {
    background: #fff
}

.price-cart-top {
    border: 1px dotted #ddd;
    border-radius: 4px;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    padding: 0 20px;
    vertical-align: middle
}

.language-cart2 .language-box,
.top-nav.top-nav2 li {
    border: none
}

.top-nav.top-nav2 li:last-child::after {
    display: none
}

.top-nav.top-nav2 {
    margin-top: 3px
}

.search-form.search-form2 {
    background: #fff;
    margin: 0;
    max-width: 240px;
    border: 1px solid #e5e5e5
}

.search-form.search-form2::after {
    color: #555;
    content: "";
    font-family: linearicons
}

.item-banner2 .banner-info {
    background: rgba(0, 0, 0, .5);
    color: #fff;
    margin-top: -110px;
    max-width: 520px;
    padding: 50px;
    text-align: left
}

.item-banner2 .banner-info h2 {
    font-weight: 700;
    margin: 0 0 10px;
    text-transform: uppercase
}

.btn-eye {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px 0 55px;
    position: relative;
    text-align: center;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.btn-eye:hover {
    color: #fff
}

.btn-eye::before {
    border-right: 1px solid rgba(255, 255, 255, .1);
    content: "";
    font-family: linearicons;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px
}

.item-banner2 .btn-eye {
    color: #fff;
    margin-top: 15px
}

.item-banner2 .btn-eye:hover {
    background: #3b3b3b
}

.owl-directnav .owl-theme .owl-controls .owl-buttons div {
    background: rgba(0, 0, 0, .5);
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div {
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 700;
    height: 60px;
    letter-spacing: 4px;
    line-height: 60px;
    right: 0;
    text-transform: uppercase;
    width: 60px
}

.bestsale-filter .toggle-filter,
.bestsell-tab2 a,
.content-cat-icon>h2 {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div.owl-next {
    margin-top: -59px
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div.owl-next::after {
    content: "N"
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div.owl-prev::after {
    content: "P"
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div:hover {
    width: 100px;
    z-index: 9
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div.owl-prev:hover::after {
    content: "prev"
}

.banner-home2 .owl-theme .owl-controls .owl-buttons div.owl-next:hover::after {
    content: "next"
}

.banner-home2.owl-directnav {
    margin-bottom: 65px
}

.welcome-intro {
    margin: 0 auto 65px;
    max-width: 700px;
    text-align: center
}

.welcome-intro>h2 {
    font-weight: 700;
    margin: 0 0 15px
}

.welcome-intro>p {
    line-height: 24px
}

.wrap-list-cat2 {
    background: #f9f9f9;
    margin-bottom: 70px;
    padding: 60px 0 50px
}

.item-cat-icon {
    border-left: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    color: #888;
    margin: 0 -15px
}

.list-cat-icon {
    padding: 0 15px;
    position: relative
}

.cat-icon-thumb {
    display: block;
    height: 90px;
    position: relative
}

.content-cat-icon {
    position: relative;
    text-align: center;
    z-index: 99
}

.cat-icon-thumb img {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0
}

.inner-cat-icon {
    overflow: hidden;
    padding: 30px 15px;
    position: relative;
    border: 15px solid #f9f9f9
}

.cat-mask-icon,
.list-cat-icon::after,
.list-cat-icon::before {
    content: "";
    position: absolute;
    left: 0
}

.content-cat-icon>h2 {
    margin: 0 0 10px
}

.cat-icon-thumb .second-icon {
    opacity: 0
}

.cat-mask-icon {
    height: 100%;
    top: 100%;
    width: 100%
}

.item-cat-icon.active .cat-mask-icon {
    top: 0;
    left: 0
}

.item-cat-icon.active,
.item-cat-icon.active h2 a {
    color: #fff
}

.item-cat-icon.active .cat-icon-thumb .second-icon {
    opacity: 1
}

.item-cat-icon.active .cat-icon-thumb .first-icon {
    opacity: 0
}

.hover-out .item-cat-icon.active .cat-mask-icon {
    top: 0;
    left: 0
}

.list-cat-icon::after {
    background: #f9f9f9;
    height: 1px;
    top: 0;
    width: 100%;
    z-index: 9
}

.list-cat-icon::before {
    background: #f9f9f9;
    height: 100%;
    top: 0;
    width: 1px;
    z-index: 9
}

.item-ad2 {
    margin: 0 -5px 20px
}

.list-adv2 {
    margin-bottom: 50px;
    padding: 0 5px
}

.wrap-best-seller2 {
    background: #f4f4f4;
    margin-bottom: 65px;
    padding: 65px 0
}

.best-seller-title2>h2 {
    display: inline-block;
    font-weight: 700;
    margin: 0 55px 0 0;
    text-transform: uppercase;
    vertical-align: middle
}

.bestsell-tab2 {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0
}

.bestsell-tab2>li {
    border-right: 1px solid #000;
    display: inline-block;
    height: 24px;
    line-height: 24px;
    margin-right: 26px;
    padding-right: 30px
}

.bestsell-tab2>li:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.bestsale-filter .toggle-filter::after,
.full-view-all::after {
    border-left: 1px solid #e5e5e5;
    content: "";
    text-align: center;
    font-family: linearicons
}

.bestsale-filter {
    display: inline-block;
    float: right;
    margin-top: 0
}

.best-seller-title2 {
    margin-bottom: 60px;
    position: relative
}

.item-box-filter>ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.bestsale-filter .toggle-filter {
    background: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    display: block;
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    padding-right: 40px;
    position: relative;
    width: 156px;
    color: #3b3b3b
}

.bestsale-filter .toggle-filter::after {
    font-size: 13px;
    font-weight: 400;
    height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 40px
}

.full-collect-title>h2,
.full-view-all {
    font-weight: 700;
    text-transform: uppercase
}

.box-product-filter {
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .1);
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 99;
    display: none
}

.item-box-filter {
    float: left;
    padding: 27px 25px 15px;
    width: 20.8%
}

.item-box-filter.box-tags-filter {
    width: 37.6%
}

.item-box-filter>h2 {
    border-bottom: 1px solid #e5e5e5;
    font-size: 14px;
    margin: 0 0 15px;
    padding-bottom: 14px;
    text-transform: uppercase
}

.item-box-filter a {
    color: #999
}

.item-box-filter.box-tags-filter a:hover {
    color: #fff
}

.item-box-filter li {
    margin-bottom: 10px
}

.item-box-filter.box-tags-filter li {
    display: inline-block;
    margin: 0 2px 5px 0
}

.item-box-filter.box-tags-filter li a {
    background: #f9f9f9;
    display: block;
    height: 30px;
    line-height: 30px;
    padding: 0 20px
}

.item-box-filter span.color-filter {
    border: 1px solid #e5e5e5;
    height: 14px;
    margin-right: 10px;
    padding: 0;
    vertical-align: sub;
    width: 14px;
    display: inline-block
}

.wrap-best-seller2 .home-best-seller-slider .owl-theme .owl-controls {
    margin-top: 20px
}

.full-collect-title>h2 {
    margin: 0
}

.full-collect-title {
    margin-bottom: 65px;
    position: relative
}

.full-view-all,
.full-view-all::after {
    height: 40px;
    position: absolute;
    right: 0
}

.full-view-all {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-size: 16px;
    line-height: 40px;
    padding: 0 40px 0 30px;
    top: 0;
    width: 175px
}

.full-view-all::after {
    font-size: 13px;
    font-weight: 400;
    width: 40px
}

.item-full-collect {
    background: #f9f9f9
}

.item-full-collect .product-info {
    padding: 10px 30px
}

.item-full-collect .product-thumb>a {
    display: block;
    overflow: hidden;
    position: relative
}

.item-full-collect .product-thumb>a::after,
.item-full-collect .product-thumb>a::before {
    background: rgba(0, 0, 0, .1);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    content: ""
}

.item-full-collect .product-thumb>a::before {
    left: -100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-full-collect .product-thumb>a::after {
    left: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-full-collect:hover .product-thumb>a::after,
.item-full-collect:hover .product-thumb>a::before {
    left: 0
}

.full-collect {
    margin-bottom: 70px
}

.nec-why-us {
    background: url(../images/home2/bg-wn.jpg) center top no-repeat rgba(0, 0, 0, 0);
    padding: 90px 0;
    background-attachment: fixed
}

.nec-why-ifo {
    color: #fff;
    max-width: 450px
}

.why-faq {
    list-style: none;
    margin: 0;
    padding: 0
}

.why-faq h3 {
    cursor: pointer;
    font-size: 13px;
    font-weight: 700;
    margin: 0;
    padding-left: 30px;
    position: relative;
    text-transform: uppercase
}

.why-faq h3 .lnr {
    font-size: 16px;
    left: 0;
    margin-right: 15px;
    position: absolute;
    top: -3px
}

.deal-of-day-slider,
.why-faq>li {
    position: relative
}

.why-faq>li p {
    color: #e3e3e3;
    line-height: 25px;
    margin-top: 20px;
    padding-left: 30px
}

.why-faq>li {
    margin-bottom: 26px
}

.nec-why-ifo .btn-eye {
    margin-top: 30px;
    background: #f5f5f5;
    color: #3b3b3b
}

.nec-why-ifo .btn-eye::before {
    border-color: #e5e5e5
}

.nec-why-ifo .btn-eye:hover {
    color: #fff
}

.nec-why-ifo .btn-eye:hover::before {
    border-color: rgba(255, 255, 255, .1)
}

.nec-why-ifo>h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 40px;
    text-transform: uppercase
}

.nec-why-ifo>span {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px;
    text-transform: uppercase
}

.nec-why-thumb {
    padding-right: 130px;
    padding-top: 80px
}

.deal-of-day {
    background: #f4f4f4;
    padding: 70px 0 50px
}

.content-deal-of-day {
    background: #fff;
    border: 1px solid #e5e5e5;
    padding: 50px 0;
    position: relative
}

.title-deal-day {
    color: #fff;
    left: -179px;
    margin: 0;
    padding: 16px 45px 21px;
    position: absolute;
    text-transform: uppercase;
    top: 243px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: 99
}

.deal-of-day .product-title {
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 25px
}

.deal-of-info>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.deal-of-info>ul li {
    display: inline-block;
    vertical-align: middle
}

.product-saleoff>strong {
    font-size: 30px;
    font-weight: 400
}

.deal-of-info .product-price>strong {
    display: block;
    margin-bottom: -10px
}

.product-saleoff {
    margin-right: 16px
}

.deal-of-info .product-price {
    margin-bottom: 0
}

.deal-of-info .product-price strong {
    color: #3b3b3b
}

.deal-of-info .product-extra-link.style2 {
    margin-top: 30px
}

.deal-of-info {
    padding-right: 100px;
    padding-top: 100px
}

.dealday-countdown {
    position: absolute;
    right: -40px;
    top: 150px;
    width: 80px;
    z-index: 999
}

.dealday-countdown canvas {
    display: none
}

.dealday-countdown .time_circles>div {
    border-radius: 50%;
    color: #fff;
    font-size: 10px;
    height: 80px;
    left: auto !important;
    margin-bottom: 10px;
    padding-top: 19px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    top: auto !important;
    width: 80px !important
}

.dealday-countdown .time_circles>div .number {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: -5px
}

.dealday-countdown .time_circles>div::after {
    background: rgba(0, 0, 0, .1);
    border-radius: 0 40px 40px 0;
    content: "";
    height: 80px;
    left: 40px;
    position: absolute;
    top: 0;
    width: 40px
}

.box-title3::after,
.may-you-like::after {
    content: "";
    left: 0
}

.dealday-countdown .time_circles>div span {
    position: relative;
    z-index: 9
}

.may-you-like h2 {
    font-weight: 700;
    margin: 0 0 63px;
    text-transform: uppercase
}

.may-you-slider .item {
    padding: 0 15px
}

.may-you-slider {
    margin: 0 -15px
}

.may-you-like {
    padding-top: 15px;
    position: relative
}

.may-you-like::after {
    background: #f4f4f4;
    height: 370px;
    position: absolute;
    top: 0;
    width: 100%
}

.youlike-content {
    position: relative;
    z-index: 99
}

.may-you-slider .product-item {
    background: #fff
}

.may-you-slider .owl-controls.clickable {
    position: absolute;
    right: 15px;
    top: -98px
}

.content-from-blog2>h2 {
    font-weight: 700;
    margin: 0 0 60px;
    text-transform: uppercase
}

.item-from-blog2 {
    display: table;
    margin-bottom: 10px;
    width: 100%
}

.item-from-blog2 .post-thumb {
    display: table-cell;
    width: 200px
}

.item-from-blog2 .zoom-image>a {
    border: 1px solid #e5e5e5;
    display: block;
    overflow: hidden;
    padding: 9px;
    width: 200px
}

.item-from-blog2 .post-info {
    background: #f4f4f4;
    display: table-cell;
    padding: 28px 30px;
    vertical-align: top
}

.from-blog-slider2 .owl-item:nth-child(2n) .item-from-blog2 .post-info {
    background: #f4f4f4
}

.from-blog-slider2 .owl-item:nth-child(2n+1) .item-from-blog2 .post-info {
    background: #f9f9f9
}

.from-blog-slider2 .post-desc {
    border-top: 1px solid #e5e5e5;
    margin: 17px 0 0;
    padding-top: 15px
}

.from-blog-slider2 .owl-theme .owl-controls {
    position: absolute;
    right: 0;
    top: -95px
}

.from-blog2 {
    margin-bottom: 70px;
    margin-top: 15px
}

.contact-box>p {
    margin: 0
}

.contact-box>p span.lnr {
    font-size: 16px;
    margin-right: 10px
}

.banner-info-content.style1 {
    background: rgba(255, 255, 255, .96);
    position: absolute;
    right: 0;
    width: 300px;
    transition: all .5s ease-out .3s;
    -webkit-transition: all .5s ease-out .3s;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.banner-info .container,
.box-title3 {
    position: relative
}

.banner-slider3 .owl-item.active .banner-info-content.style1 {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.banner-info-content.style1 .product-item {
    float: left;
    margin: 0;
    width: 50%;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #e5e5e5
}

.banner-info-text.style1,
.mini-cart-box.mini-cart6,
.wrap-cart7 {
    float: right
}

.box-title3 {
    margin-bottom: 43px;
    text-align: center
}

.box-title3>h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    text-transform: uppercase
}

.box-title3 h2 span {
    background: #fff;
    display: inline-block;
    padding: 0 40px;
    position: relative;
    z-index: 9
}

.box-title3::after {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    height: 4px;
    position: absolute;
    top: 14px;
    width: 100%
}

.adv-info3 .btn-eye.bg-color2::before,
.adv-info3 .btn-eye.black-eye::before {
    border-color: rgba(255, 255, 255, .1)
}

.item-adv3.item-adv-video3::after,
.item-adv3::before {
    height: 100%;
    content: ""
}

.banner-slider3 {
    margin-bottom: 45px
}

.item-adv3 {
    position: relative
}

.adv-info3,
.item-adv3.item-adv-video3::after,
.item-adv3::before {
    position: absolute;
    width: 100%;
    left: 0
}

.item-adv3::before {
    top: 0;
    background: rgba(0, 0, 0, .3);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    opacity: 0;
    z-index: 9
}

.item-adv3:hover::before {
    opacity: 1
}

.adv-info3>h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 8px 0 22px
}

.item-adv3:hover .adv-default>a::after,
.item-adv3:hover .adv-default>a::before {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.adv-info3>span {
    text-transform: uppercase
}

.adv-info3.adv-info-top3 {
    margin-top: 0;
    top: 50px
}

.adv-info3.item-info-black3 {
    color: #3b3b3b
}

.adv-info3 .btn-eye.black-eye {
    background: #3b3b3b;
    color: #fff
}

.item-adv3.item-adv-video3::after {
    top: 0;
    background: url(../images/home2/icon-play.png) center center no-repeat
}

.adv-info3 {
    margin-top: -60px;
    text-align: center;
    top: 50%;
    z-index: 999
}

.adv-info3 .readmore {
    color: #fff;
    font-weight: 700;
    text-transform: uppercase
}

.adv-info3 .readmore::before {
    content: "";
    display: inline-block;
    font-family: linearicons;
    font-size: 30px;
    font-weight: 400;
    margin-right: 10px;
    vertical-align: middle
}

.instagam-title,
.loadmore-item>a {
    font-weight: 700;
    text-transform: uppercase
}

.adv-info3 .btn-eye {
    height: 36px;
    line-height: 36px;
    background: rgba(255, 255, 255, .9);
    color: #3b3b3b
}

.best-seller3,
.best-seller3 .box-title3 h2 span {
    background: #f4f4f4
}

.adv-info3 .btn-eye:hover {
    color: #fff
}

.adv-info3 .btn-eye::before {
    border-color: #e5e5e5
}

.adv-info3:hover .btn-eye::before {
    border-color: rgba(255, 255, 255, .1)
}

.list-banner-adv3 .item-adv3 {
    margin: 0 -5px 20px
}

.list-banner-adv3 {
    margin-bottom: 50px;
    padding: 0 5px
}

.content-best-seller3>ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0
}

.content-best-seller3 .compare-link>span,
.content-best-seller3 .wishlist-link>span {
    display: none
}

.content-best-seller3 a.compare-link>i,
.content-best-seller3 a.wishlist-link>i {
    margin: 0
}

.content-best-seller3 a.compare-link,
.content-best-seller3 a.wishlist-link {
    width: 55px
}

.best-seller3 {
    margin-bottom: 50px;
    padding: 60px 0 50px
}

.title-tab-home.title-best-seller3 {
    margin-bottom: 50px;
    text-align: left
}

.title-tab-home.title-best-seller3>ul {
    margin: 0 0 0 -5px;
    padding: 0;
    display: inline-block
}

.loadmore-item>a {
    font-size: 13px;
    color: #3b3b3b
}

.loadmore-item>a span.lnr {
    display: inline-block;
    font-size: 30px;
    margin-right: 10px;
    vertical-align: text-bottom;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.loadmore-item>a:hover span.lnr {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.loadmore-item {
    margin-top: 20px;
    text-align: center
}

.item-from-blog3 {
    position: relative
}

.item-from-blog3 .post-format-date {
    left: 0;
    top: 0
}

.item-from-blog3 .post-info {
    background: rgba(0, 0, 0, .5);
    bottom: 0;
    color: #fff;
    left: 0;
    padding: 30px 40px;
    position: absolute;
    width: 100%
}

.from-blog3 {
    margin-bottom: 50px
}

.item-from-blog3 .post-title>a {
    color: #fff
}

.instagram-box {
    background: url(../images/home2/bg-prl.jpg) center center no-repeat fixed rgba(0, 0, 0, 0);
    padding: 95px 0;
    text-align: center
}

.list-instagram {
    font-size: 0;
    margin: auto;
    max-width: 450px
}

.list-instagram a {
    display: inline-block;
    margin-bottom: 1px;
    margin-left: 1px;
    position: relative;
    width: 70px;
    font-size: 13px
}

.list-instagram a::after {
    color: #fff;
    content: "";
    font-family: linearicons;
    height: 100%;
    left: 0;
    line-height: 70px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.social-footer2>a {
    color: #3b3b3b
}

.list-instagram a:hover::after {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-from-blog3 .post-thumb img {
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 100%
}

.item-from-blog3:hover .post-thumb img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.item-from-blog3 .post-thumb {
    overflow: hidden
}

.instagam-title {
    color: #fff;
    font-size: 16px;
    margin: 0 0 25px
}

.post-blog-info3 {
    color: #fff;
    list-style: none;
    margin: 0;
    padding: 0
}

.post-blog-info3 a {
    color: #a1a1a1
}

.post-blog-info3>li {
    border-right: 1px solid #a1a1a1;
    display: inline-block;
    height: 18px;
    line-height: 18px;
    margin-right: 7px;
    padding-right: 10px
}

.post-blog-info3 a .lnr {
    color: #fff;
    margin-right: 10px
}

.post-blog-info3>li:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.item-from-blog3 .post-desc {
    border-top: 1px solid rgba(255, 255, 255, .1);
    margin-bottom: 0;
    margin-top: 15px;
    padding-top: 15px
}

.top-header4 {
    padding: 9px 0
}

.header-intro {
    margin: 0
}

.header-intro .lnr {
    font-size: 16px;
    margin-right: 10px
}

.top-info4 {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: right
}

.top-info4 li {
    border-left: 1px solid #e5e5e5;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    margin-left: 7px;
    padding-left: 10px;
    vertical-align: top
}

.top-info4 li .lnr {
    font-size: 16px;
    margin-right: 5px
}

.top-info4 li:first-child {
    border: none;
    margin: 0;
    padding: 0
}

.main-header4 {
    padding-top: 32px;
    position: relative
}

.main-header4::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .1);
    content: ""
}

.logo4 {
    position: relative;
    z-index: 99
}

.smart-search {
    border-radius: 4px;
    float: right;
    margin-right: 10px;
    max-width: 420px;
    padding: 0 0 0 175px;
    position: relative;
    width: 100%;
    z-index: 999
}

.select-category {
    left: 0;
    position: absolute;
    top: 0;
    width: 175px
}

.select-category .category-toggle-link {
    border-right: 1px solid rgba(255, 255, 255, .2);
    color: #fff;
    display: block;
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    position: relative
}

.smart-search-form>input[type=text] {
    background: 0 0;
    border: none;
    color: #fff;
    font-style: italic;
    height: 36px;
    padding: 0 20px;
    width: 100%
}

.smart-search-form {
    position: relative
}

.smart-search-form>input[type=submit] {
    background: 0 0;
    border: none;
    height: 36px;
    position: absolute;
    right: 0;
    width: 36px;
    z-index: 9
}

.smart-search-form::after {
    color: #fff;
    content: "";
    font-family: linearicons;
    font-size: 16px;
    height: 36px;
    line-height: 36px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 36px
}

.select-category .category-toggle-link::after {
    content: "";
    font-family: linearicons;
    font-size: 10px;
    position: absolute;
    right: 20px;
    top: 0
}

.list-category-toggle {
    background: #fff;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .1);
    border-radius: 4px;
    display: none;
    list-style: none;
    margin: 0;
    padding: 10px 20px;
    position: absolute;
    top: 37px;
    width: 200px;
    z-index: 9999
}

.content-category-dropdown>ul {
    background: #fff;
    margin: 0;
    padding: 0 40px 0 18px;
    position: relative;
    list-style: none
}

.content-category-dropdown>ul>li.has-cat-mega .cat-mega-menu {
    background: #f9f9f9;
    margin-left: 50px;
    opacity: 0;
    position: absolute;
    left: 100%;
    top: -2px;
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    visibility: hidden;
    width: 902px
}

.content-category-dropdown>ul>li.has-cat-mega:hover .cat-mega-menu {
    opacity: 1;
    visibility: visible;
    margin-left: 0
}

.content-category-dropdown>ul::before {
    background: #f9f9f9;
    border-right: 1px solid #e5e5e5;
    content: "";
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    width: 40px
}

.content-category-dropdown>ul>li {
    border-bottom: 1px solid #e5e5e5;
    padding-right: 20px
}

.category-dropdown {
    position: relative;
    z-index: 99;
    width: 100%
}

.content-category-dropdown>ul>li>a {
    display: block;
    height: 40px;
    line-height: 40px;
    position: relative;
    text-transform: uppercase
}

.content-category-dropdown>ul>li.has-cat-mega>a::before {
    content: "\e87a";
    font-family: linearicons;
    height: 100%;
    right: -60px;
    position: absolute;
    text-align: center;
    width: 40px
}

.category-dropdown-link::before {
    content: "";
    font-family: linearicons;
    font-size: 20px;
    left: 0;
    position: absolute;
    top: 0
}

.category-dropdown-link::after {
    border-right: 1px solid #e5e5e5;
    content: "";
    font-family: linearicons;
    font-size: 10px;
    font-weight: 400;
    height: 30px;
    line-height: 30px;
    position: absolute;
    right: 0;
    top: 10px;
    width: 25px
}

.techpro-info .title14 {
    margin-bottom: 5px
}

.content-category-dropdown>ul>li.has-cat-mega.active>a::before {
    color: #fff
}

.cat-mega-slider>h2 {
    font-weight: 700;
    margin: 0 0 23px;
    text-transform: uppercase
}

.list-category-toggle a {
    display: block;
    padding: 5px 0;
    text-transform: capitalize
}

.access-info>h3,
.category-dropdown-link,
.special-intro-info,
.tab-cat-header4 a,
.tab-cat-header4>label,
.title4,
.top-save-accor h2,
.video-best-info>h3 {
    text-transform: uppercase
}

.list-category-toggle>li {
    border-bottom: 1px solid #e5e5e5
}

.list-category-toggle>li:last-child {
    border: none
}

.cat-mega-slider .product-item {
    border: none;
    margin: 0
}

.access4>ul,
.item-adv4,
.product-item4 {
    border: 1px solid #e5e5e5
}

.cat-mega-slider .product-item .product-thumb {
    margin: 0;
    padding: 0
}

.cat-mega-slider .item {
    padding: 0 15px
}

.cat-mega-slider .wrap-item {
    margin: 0 -15px
}

.cat-mega-slider .product-item .product-info {
    padding-top: 20px;
    text-align: left
}

.cat-mega-slider .product-item .product-info .product-title {
    margin: 0
}

.cat-mega-slider {
    padding: 25px 0 0 35px
}

.cat-mega-slider .owl-controls {
    position: absolute;
    right: 10px;
    top: -48px
}

.cat-mega-menu .zoom-image {
    margin: 0 -35px 0 0
}

.content-category-dropdown {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 100%;
    width: 270px;
    visibility: hidden;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.category-dropdown:hover .content-category-dropdown {
    opacity: 1;
    visibility: visible
}

.main-nav.main-nav2>ul>li>a {
    font-size: 13px;
    height: 50px;
    line-height: 52px
}

.category-dropdown-link {
    display: block;
    font-weight: 700;
    height: 50px;
    line-height: 52px;
    padding-left: 30px;
    position: relative;
    width: 270px
}

.item-adv4 {
    margin: 0 2px;
    position: relative;
    text-align: center
}

.adv-slider4 {
    margin: 50px -2px 0
}

.item-adv4 .btn-eye.btn-eye4 {
    left: 0;
    margin: -40px auto auto;
    position: absolute;
    right: 0;
    top: 50%;
    width: 150px;
    z-index: 99;
    transform: rotate(0) scale(0);
    -webkit-transform: rotate(0) scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    opacity: 0
}

.item-adv4 .product-thumb::after {
    background: rgba(0, 0, 0, .2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transform: rotate(0) scale(0);
    -webkit-transform: rotate(0) scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    opacity: 0
}

.item-adv4 .product-info {
    background: #fff;
    bottom: 0;
    left: 0;
    padding: 14px 0 2px;
    position: absolute;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-adv4 .product-thumb {
    margin: 0
}

.item-adv4:hover .btn-eye.btn-eye4,
.item-adv4:hover .product-thumb::after {
    transform: rotate(360deg) scale(1);
    -webkit-transform: rotate(360deg) scale(1);
    opacity: 1
}

.item-adv4:hover .product-info {
    background: #fafafa
}

.adv-slider4 .owl-theme .owl-controls .owl-buttons div {
    background: rgba(0, 0, 0, .5);
    display: block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px;
    position: absolute;
    top: 50%;
    margin-top: -20px;
    opacity: 0
}

.adv-slider4 .owl-theme:hover .owl-controls .owl-buttons div {
    opacity: 1
}

.adv-slider4 .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: 0
}

.adv-slider4 .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0
}

.adv-slider4 .owl-theme:hover .owl-controls .owl-buttons div.owl-prev {
    left: -15px
}

.adv-slider4 .owl-theme:hover .owl-controls .owl-buttons div.owl-next {
    right: -15px
}

.title4 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 23px
}

.best-access {
    margin-top: 45px
}

.product-item4 {
    text-align: center;
    background: #fff
}

.bestlase-slider4 {
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 56px
}

.product-item4 .product-rating {
    margin: auto
}

.product-item4 .product-thumb {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px
}

.bestlase-slider4 .item {
    padding: 0 10px
}

.product-item4 .product-info {
    padding-bottom: 18px
}

.product-more-link4 {
    font-size: 0;
    left: 0;
    margin: -41px auto auto;
    max-width: 85px;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    z-index: 99
}

.product-more-link4 a {
    background: #3b3b3b;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    margin-bottom: 1px;
    margin-left: 1px;
    width: 40px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0) rotate(0);
    -webkit-transform: scale(0) rotate(0);
    opacity: 0
}

.product-item4 .product-thumb-link::after {
    background: rgba(0, 0, 0, .3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0) rotate(0);
    -webkit-transform: scale(0) rotate(0);
    opacity: 0
}

.product-item4:hover .product-more-link4 a,
.product-item4:hover .product-thumb-link::after {
    transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg);
    opacity: 1
}

.video-best-sale {
    position: relative
}

.video-best-info {
    background: rgba(0, 0, 0, .7);
    bottom: 0;
    left: 0;
    padding: 12px 15px 10px 80px;
    position: absolute;
    width: 100%
}

.video-best-info>h3 {
    color: #fff;
    font-size: 16px;
    margin: 0
}

.video-best-info>p {
    color: #ccc;
    line-height: 20px;
    margin: 0
}

.video-best-info::before {
    color: #fff;
    content: "";
    font-family: linearicons;
    font-size: 30px;
    height: 60px;
    left: 0;
    line-height: 60px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 60px
}

.bestlase-slider4 .owl-theme .owl-controls .owl-buttons div {
    background: rgba(0, 0, 0, .5);
    font-size: 10px;
    height: 24px;
    line-height: 24px;
    margin-left: 1px;
    opacity: 1;
    width: 24px
}

.bestlase-slider4 .owl-theme .owl-controls .owl-buttons div .lnr {
    line-height: 24px
}

.bestlase-slider4 .owl-theme .owl-controls {
    position: absolute;
    right: 12px;
    top: -54px
}

.access4>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.access4 li {
    border-bottom: 1px solid #e5e5e5;
    display: table;
    padding: 19px 0;
    width: 100%
}

.access-info,
.access-thumb {
    display: table-cell;
    vertical-align: middle
}

.access4 li:last-child {
    border: none
}

.access-thumb {
    text-align: center;
    width: 108px
}

.access-info>h3 {
    font-size: 16px;
    margin: 0
}

.lage-adv4,
.tab-cat4 {
    margin-bottom: 45px
}

.access-info>span {
    color: #888
}

.lage-adv4 {
    margin-top: 50px
}

.product-item4 .product-thumb-link {
    padding: 10px
}

.tab-cat-header4>label {
    display: inline-block;
    font-size: 30px;
    line-height: 30px;
    margin: 0 0 13px
}

.tab-cat-header4>ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0
}

.tab-cat-header4 li {
    display: inline-block;
    margin-left: 40px
}

.tab-cat-header4 a {
    font-size: 13px;
    font-weight: 700
}

.tab-cat-header4 {
    padding-bottom: 14px
}

.tab-cat-adv4 {
    margin-bottom: 20px;
    overflow: hidden;
    position: relative
}

.tab-cat-slider4 .item {
    padding: 0 10px
}

.tab-cat-slider4 {
    margin: 0 -10px
}

.tab-cat-slider4 .owl-theme .owl-controls {
    position: absolute;
    right: 10px;
    top: -143px
}

.tab-cat-slider4 .owl-theme .owl-controls .owl-buttons div {
    background: #292f52;
    font-size: 13px;
    height: 30px;
    margin-left: 1px;
    opacity: 1;
    width: 30px;
    border-radius: 4px
}

.tab-cat-slider4 .owl-theme .owl-controls .owl-buttons div span.lnr {
    display: block;
    height: 30px;
    line-height: 30px
}

.tab-cat-adv4 a::after,
.tab-cat-adv4 a::before {
    background: rgba(0, 0, 0, .1);
    height: 100%;
    width: 0;
    content: ""
}

.tab-cat-adv4 a::before {
    left: 0;
    position: absolute;
    top: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.tab-cat-adv4 a {
    display: block;
    position: relative
}

.tab-cat-adv4 a::after {
    bottom: 0;
    position: absolute;
    right: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.tab-cat-adv4 a:hover::after,
.tab-cat-adv4 a:hover::before {
    width: 100%
}

.top-save-accor img {
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.top-save-accor li:hover img {
    transform: scale(1.15)
}

.top-save-accor h2 {
    cursor: pointer;
    font-size: 16px;
    font-weight: 100;
    margin: 0;
    padding: 14px 0 13px
}

.top-save-accor li.active h2 {
    padding: 13px 0 10px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.top-save-accor {
    background: #f4f4f4;
    list-style: none;
    margin: 0;
    padding: 0
}

.top-save-accor h2 span {
    color: #fff;
    display: inline-block;
    font-weight: 700;
    height: 20px;
    line-height: 22px;
    margin: 0 10px 0 20px;
    text-align: center;
    width: 20px
}

.top-save-accor a {
    display: block;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    height: 0;
    opacity: 0
}

.top-save-accor li.active a {
    -webkit-transform: scale(1);
    transform: scale(1);
    height: auto;
    opacity: 1
}

.top-save-accor li.active h2 {
    background: rgba(0, 0, 0, .7);
    bottom: 0;
    color: #fff;
    left: 0;
    padding-left: 55px;
    position: absolute;
    width: 100%;
    z-index: 999
}

.top-save-accor>li {
    position: relative;
    overflow: hidden
}

.top-save-accor li.active h2 span {
    font-size: 30px;
    height: 100%;
    left: 0;
    line-height: 44px;
    margin: 0;
    position: absolute;
    top: 0;
    width: 40px
}

.info-skew h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 0
}

.info-skew h2 {
    font-size: 90px;
    font-weight: 700;
    margin: 10px 0 5px;
    opacity: .7
}

.info-skew h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 0
}

.info-skew.top {
    margin: auto;
    max-width: 455px;
    padding-right: 130px;
    text-align: right
}

.info-skew.bottom {
    margin: auto;
    max-width: 400px;
    padding-left: 130px;
    text-align: left
}

.item-banner5 .banner-info {
    margin-top: -60px
}

.info-skew.top>h4 {
    margin-right: 20px
}

.info-skew.bottom>h4 {
    margin-left: 20px
}

.box-special-slider .owl-carousel .owl-wrapper-outer {
    z-index: 9999
}

.product-box-special {
    background: #f4f4f4;
    margin-bottom: 70px;
    position: relative
}

.special-intro-thumb.left {
    margin-right: -30px
}

.special-intro-thumb.right {
    margin-left: -30px
}

.special-intro-info {
    padding: 100px 20px 0 70px
}

.special-intro-info>h3 {
    font-size: 30px;
    font-weight: 700;
    margin: 0
}

.special-intro-info>p {
    font-size: 18px;
    font-weight: 700;
    margin: 30px 0 24px;
    padding-left: 40px;
    position: relative
}

.special-intro-info>h2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 62px;
    margin: 0
}

.special-intro-info>p::before {
    content: "";
    height: 60px;
    left: 25px;
    position: absolute;
    top: -25px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 2px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.special-intro-info:hover>p::before {
    height: 200px;
    left: -25px;
    top: -45px
}

.box-speial-content {
    padding: 100px
}

.special-product-info {
    padding: 50px 50px 40px;
    margin-right: -100px;
    position: relative;
    z-index: 999
}

.product-detail-extra-link a .fa {
    margin-right: 10px
}

.special-product-info .product-extra-link.style2 {
    margin: 30px 0 25px
}

.special-product-gallery {
    margin-left: 20px;
    margin-top: 50px
}

.special-product-gallery .detail-gallery {
    background: 0 0;
    margin: 0
}

.special-product-gallery .wrap-gallery-thumb {
    right: 0;
    top: 0
}

.special-product-gallery .gallery-slider {
    border: none;
    padding-right: 80px
}

.special-product-gallery .gallery-thumb a {
    border: none;
    position: relative
}

.special-product-gallery .gallery-thumb li {
    height: 80px !important;
    width: 70px !important
}

.special-product-gallery .gallery-thumb a:hover::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #e5e5e5;
    content: ""
}

.special-product-gallery .gallery-thumb li.active a:hover::before {
    border: none
}

.special-product-gallery .gallery-thumb li.active a::before {
    line-height: 70px
}

.special-product-gallery .gallery-image a {
    display: block;
    overflow: hidden;
    position: relative
}

.special-product-gallery .gallery-image a img {
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.special-product-gallery .gallery-image:hover img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.special-product-gallery .gallery-control a {
    color: #3b3b3b;
    background: #fff;
    position: relative;
    overflow: hidden;
    margin: 0 1px;
    width: 30px
}

.special-product-gallery .gallery-control a::after {
    bottom: 0;
    font-family: linearicons;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    color: #fff;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.special-product-gallery .gallery-control a.prev::after {
    content: "\e878";
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%)
}

.special-product-gallery .gallery-control a.next::after {
    content: "\e877";
    transform: translateY(100%);
    -webkit-transform: translateY(100%)
}

.special-product-gallery .gallery-control a span {
    display: block;
    height: 40px;
    line-height: 40px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.special-product-gallery .gallery-control a.prev:hover span {
    transform: translateY(100%);
    -webkit-transform: translateY(100%)
}

.special-product-gallery .gallery-control a.prev:hover::after {
    transform: translateY(0);
    -webkit-transform: translateY(0)
}

.special-product-gallery .gallery-control a.next:hover span {
    transform: translateY(-100%);
    -webkit-transform: translateY(-100%)
}

.special-product-gallery .gallery-control a.next:hover::after {
    transform: translateY(0);
    -webkit-transform: translateY(0)
}

.special-product-gallery .gallery-control {
    text-align: center
}

.box-special-slider .owl-controls {
    bottom: 60px;
    left: 25%;
    margin-left: -40px;
    position: absolute;
    z-index: 9999
}

.box-speial-content>.btn-eye {
    bottom: 80px;
    left: 100px;
    position: absolute;
    z-index: 9999
}

.box-speial-content .btn-eye:hover {
    background: #3b3b3b
}

.special-product-gallery .gallery-slider::before {
    content: "";
    height: 80px;
    left: 0;
    position: absolute;
    top: -40px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 1px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-special-product:hover .special-product-gallery .gallery-slider::before {
    height: 200px;
    left: -45px;
    top: -57px
}

.top-deals-special {
    width: 270px;
    margin-left: 5px
}

.top-deals-special .time_circles canvas {
    display: none
}

.top-deals-special .time_circles>div {
    background: #000;
    border-radius: 50%;
    box-shadow: 0 0 0 10px rgba(0, 0, 0, .1);
    color: #fff;
    display: inline-block;
    height: 53px;
    margin: 5px;
    text-align: center;
    width: 53px !important
}

.main-nav.main-nav7,
.profile-list-link {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .2);
    background: #fff
}

.top-deals-special .time_circles>div .number {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin: 8px 0 -6px
}

.top-deals-special .time_circles>div .text {
    display: block;
    font-size: 10px;
    text-transform: lowercase
}

.header6 {
    position: relative
}

.main-header6 {
    padding: 20px 0
}

.header-nav6 {
    background: rgba(255, 255, 255, .9);
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 99
}

.search-form.search-form6 {
    margin: 0 -30px 0 30px;
    padding: 0
}

.search-form.search-form6::after {
    content: "";
    font-family: linearicons;
    height: 46px;
    left: 0;
    line-height: 46px;
    right: auto;
    width: 46px
}

.search-form.search-form6 input[type=text] {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    color: #999;
    font-style: normal;
    height: 46px;
    padding: 0 15px 0 40px
}

.search-form.search-form6 input[type=submit] {
    color: #fff;
    height: 46px;
    text-transform: uppercase;
    width: 100px
}

.header-link6 {
    font-size: 0;
    text-align: right
}

.header-link6>a {
    display: inline-block;
    font-size: 13px;
    margin-left: 40px;
    text-align: center
}

.header-link6>a .lnr {
    display: block;
    font-size: 16px;
    margin: 5px
}

.header-link6>a:first-child {
    margin: 0
}

.main-nav.main-nav6>ul>li>a {
    color: #3b3b3b;
    font-size: 13px;
    height: 50px;
    line-height: 50px
}

.main-nav.main-nav6>ul>li:hover>a::before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: -2px
}

.main-nav.main-nav6>ul>li {
    margin-right: 30px
}

.main-nav.main-nav6 {
    margin-left: 0
}

.mini-cart6 .mini-cart-link {
    border-radius: 4px;
    color: #fff;
    height: 36px;
    line-height: 36px;
    margin: 7px 0 -3px;
    width: 100px
}

.mini-cart6 .mini-cart-link .mini-cart-icon {
    color: #fff;
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle
}

.mini-cart6 .mini-cart-link .mini-cart-number {
    background: 0 0;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: auto;
    line-height: inherit;
    position: static;
    width: auto
}

.banner-slider6 .caption-slider {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    visibility: hidden
}

.title-box6 {
    margin: 0 auto 45px;
    max-width: 570px;
    text-align: center
}

.title-box6>h2 {
    margin: 0 0 65px;
    position: relative;
    text-transform: uppercase
}

.title-box6>h2::after {
    background: #d96a77;
    content: "";
    height: 30px;
    left: 50%;
    margin-top: 15px;
    position: absolute;
    top: 100%;
    width: 1px
}

.title-box6>p {
    color: #999;
    margin: 0
}

.service6 {
    margin-top: -25px
}

.item-service6 {
    text-align: center;
    vertical-align: top;
    padding: 0 15px
}

.item-service6>h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 0 20px;
    text-transform: uppercase
}

.service-icon {
    display: block;
    font-size: 30px;
    margin-bottom: 10px
}

.banner-slider6 .flexslider {
    background: 0 0;
    border: none;
    border-radius: 0;
    margin: 0
}

.banner-control {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%
}

.banner-slider6 {
    margin-bottom: 95px;
    position: relative
}

.inner-banner-control {
    background: rgba(0, 0, 0, .7);
    padding: 10px 40px 10px 360px;
    position: relative
}

.banner-control-text {
    color: #fff;
    font-size: 16px;
    left: 0;
    margin: 0;
    padding: 22px 0 0 35px;
    position: absolute;
    text-transform: uppercase;
    top: 0;
    width: 320px
}

.banner-slider6 .flex-direction-nav a::before {
    display: none
}

.banner-slider6 .flex-direction-nav a {
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    height: auto;
    margin: -11px 0 0;
    opacity: 1 !important;
    overflow: visible;
    position: absolute;
    text-decoration: none;
    text-shadow: none;
    top: 50%;
    transition: all .3s ease-in-out 0s;
    width: auto;
    z-index: 10
}

.banner-slider6 .flex-direction-nav .flex-prev {
    left: -22px !important
}

.banner-slider6 .flex-direction-nav .flex-next {
    right: -22px !important
}

.banner-slider6 .flexslider a {
    display: block
}

.banner-slider6 .banner-control .flexslider .flex-active-slide a {
    position: relative
}

.banner-slider6 .banner-control .flexslider .flex-active-slide a::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 999
}

.new-arrival6 {
    background: #f9f9f9;
    padding: 95px 0 77px
}

.say-hello6 {
    margin-bottom: 85px
}

.new-arrival-slider6 .item {
    padding: 0 15px
}

.new-arrival-slider6 {
    margin: 0 -15px
}

.item-product6 .sale-label {
    color: #fff;
    display: block;
    font-weight: 400;
    margin: 0;
    position: absolute;
    right: -30px;
    text-align: center;
    text-transform: lowercase;
    top: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 120px;
    background: #fe001e;
    background: -webkit-linear-gradient(left, #bd0d20, #fe001e);
    background: -o-linear-gradient(right, #bd0d20, #fe001e);
    background: -moz-linear-gradient(right, #bd0d20, #fe001e);
    background: linear-gradient(to right, #bd0d20, #fe001e)
}

.about-full-protec h2,
.about-review .title-default,
.banner-text-info,
.cat-brow-info>h3,
.content-from-blog7>h2,
.inner-banner-info1,
.inner-cat-content .cat-title,
.intro-about-slider.intro-featured6>h3,
.intro-featured6>h2,
.item-adv8 .adv-info h2,
.item-banner10 .banner-info h2,
.item-banner7 .banner-info h2,
.item-banner7 .banner-info h3,
.item-banner9 .banner-info,
.list-product7 h2,
.load-more10,
.new-product10 h2,
.parralax-info>h2,
.parralax-info>h3,
.pro-box-info>h2,
.product-label,
.product-tab-title12 a,
.title-box11,
.title8 {
    text-transform: uppercase
}

.item-product6 .product-thumb-link::after {
    background: rgba(0, 0, 0, .3);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.item-product6 .qickview-link {
    background: #fff;
    border-radius: 4px;
    bottom: 0;
    display: block;
    height: 40px;
    left: 0;
    line-height: 40px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 40px;
    z-index: 99;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.item-product6:hover .qickview-link {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-product6:hover .qickview-link:hover {
    color: #fff
}

.item-product6:hover .product-thumb-link::after {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-product6 {
    background: #fff;
    position: relative
}

.item-product6::after {
    position: absolute;
    top: 100%;
    left: 0;
    content: "";
    width: 100%;
    height: 0;
    background: #ccc;
    z-index: 9;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product6:hover::after {
    height: 2px
}

.item-product6 .product-thumb {
    border-bottom: 1px solid #f9f9f9;
    margin: 0
}

.new-arrival-slider6 .owl-carousel .owl-wrapper-outer {
    padding-bottom: 2px
}

.item-product6 .product-info {
    padding: 28px 20px
}

.cart-price6 {
    border-top: 1px solid #f9f9f9;
    display: table;
    padding: 10px 20px;
    width: 100%
}

.cart-price6 .product-price {
    display: table-cell;
    margin: 0
}

.cart-price6 .product-add-cart {
    display: table-cell;
    text-align: right
}

.cart-price6 .product-add-cart .addcart-link i {
    margin-right: 10px
}

.featured-product6 {
    background: url(../images/home6/bg-fea.jpg) top center no-repeat fixed rgba(0, 0, 0, 0);
    margin-bottom: 95px;
    padding: 95px 0
}

.intro-featured6 {
    color: #fff;
    max-width: 370px
}

.intro-featured6>h2 {
    margin: 0 0 43px
}

.intro-featured6>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.intro-featured6 li {
    display: table;
    width: 100%;
    margin-bottom: 30px
}

.intro-featured6 li .fea-thumb {
    display: table-cell;
    padding-top: 5px;
    vertical-align: top;
    width: 45px
}

.intro-featured6 li .fea-info {
    display: table-cell;
    vertical-align: top;
    color: #ccc
}

.intro-featured6 .btn-eye::before {
    border-right: 1px solid #e5e5e5
}

.intro-featured6 .btn-eye {
    background: #fff;
    color: #3b3b3b
}

.inner-cat-content .cat-title a,
.inner-cat-content .product-price>span,
.inner-cat-content .product-price>strong,
.inner-cat-content .product-title a,
.intro-featured6 .btn-eye:hover {
    color: #fff
}

.intro-featured6 .btn-eye:hover::before {
    border-right: 1px solid rgba(255, 255, 255, .1)
}

.item-pop-cat6 {
    margin: 0 -15px;
    position: relative;
    overflow: hidden
}

.list-pop-cat6 {
    padding: 0 15px
}

.cat-content {
    height: 100%;
    left: 0;
    padding: 10%;
    position: absolute;
    top: 0;
    width: 100%
}

.inner-cat-content {
    background: rgba(0, 0, 0, .2);
    border: 1px solid rgba(255, 255, 255, .2);
    height: 100%;
    padding-top: 10%;
    position: relative;
    text-align: center;
    width: 100%
}

.inner-cat-content .cat-title {
    font-size: 30px;
    font-weight: 700;
    margin: 0
}

.cat-info {
    bottom: 10%;
    left: 0;
    position: absolute;
    width: 100%
}

.inner-cat-content .product-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px
}

.inner-cat-content .product-price {
    margin-bottom: 12px
}

.item-pop-cat6 .btn-eye::before {
    border-right: 1px solid #e5e5e5
}

.item-pop-cat6 .btn-eye {
    background: #fff;
    color: #3b3b3b
}

.item-pop-cat6:hover .btn-eye::before {
    border-right: 1px solid rgba(255, 255, 255, .1)
}

.item-pop-cat6:hover .btn-eye {
    color: #fff
}

.item-pop-cat6 .cat-thumb a {
    display: block
}

.item-pop-cat6 .cat-thumb img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 100%
}

.item-pop-cat6:hover .cat-thumb img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.latest-news6,
.popular-category6 {
    margin-bottom: 95px
}

.testimonial6 {
    margin-bottom: 100px
}

.testimonial6 .review-content>p {
    color: #3b3b3b
}

.header7 {
    position: relative;
    z-index: 999
}

.inner-header7 {
    left: 0;
    padding: 30px 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.logo7,
.profile-list-link {
    transition: all .5s ease-out 0s
}

.main-nav-hover {
    display: inline-block;
    margin: 18px 65px 0 0;
    position: relative
}

.profile-list-link {
    right: -20px;
    list-style: none;
    padding: 0;
    position: absolute;
    top: 100%;
    width: 200px;
    -webkit-transition: all .5s ease-out 0s;
    margin: 30px 0 0;
    opacity: 0;
    visibility: hidden;
    z-index: 999
}

.profile-box:hover .profile-list-link {
    opacity: 1;
    margin: 0;
    visibility: visible
}

.profile-list-link>li {
    padding: 0 20px
}

.profile-list-link>li a {
    border-bottom: 1px solid #e5e5e5;
    display: block;
    height: 40px;
    line-height: 40px;
    padding-left: 25px;
    position: relative
}

.profile-list-link>li:last-child a {
    border: none
}

.profile-list-link>li a i {
    left: 0;
    position: absolute;
    top: 13px
}

.main-nav.main-nav7 .icon-main-nav7 {
    font-size: 30px;
    left: 20px;
    position: absolute;
    top: 9px;
    color: #3b3b3b
}

.main-nav.main-nav7 .close-main-nav7 {
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 9px;
    color: #3b3b3b
}

.main-nav.main-nav7 {
    left: -1px;
    margin: 0;
    position: absolute;
    top: -5px;
    width: 260px;
    z-index: 999;
    display: none
}

.logo7 {
    text-align: center;
    -webkit-transition: all .5s ease-out 0s
}

.main-nav.main-nav7>ul {
    border-top: 1px solid #e5e5e5;
    margin-top: 56px
}

.main-nav.main-nav7>ul>li {
    display: block;
    margin: 0;
    padding: 0 30px
}

.main-nav.main-nav7>ul>li:last-child {
    border: none
}

.main-nav.main-nav7>ul>li>a {
    color: #3b3b3b;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #e5e5e5
}

.main-nav.main-nav7 .mega-menu,
.main-nav.main-nav7 .sub-menu {
    left: 100%;
    top: 0
}

.main-nav.main-nav7>ul>li.has-mega-menu>a::after,
.main-nav.main-nav7>ul>li.menu-item-has-children>a::after {
    content: "";
    font-family: linearicons;
    font-size: 13px;
    font-weight: 400;
    position: absolute;
    right: 0;
    top: 0
}

.wrap-cart7 {
    margin-top: 22px
}

.profile-box.profile-box7 {
    display: inline-block
}

.profile-box {
    position: relative
}

.profile-box.profile-box7 .lnr {
    font-size: 30px
}

.mini-cart7 .mini-cart-link .mini-cart-icon {
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    display: block
}

.mini-cart7.mini-cart-box {
    margin-left: 65px;
    vertical-align: top
}

.mini-cart7 .mini-cart-number {
    background: 0 0;
    font-size: 13px;
    right: -13px;
    top: -2px
}

.box-search-hover .search-form.search-form6 {
    background: #fff;
    left: 0;
    margin: 0 0 0 30px;
    opacity: 0;
    position: absolute;
    top: -4px;
    visibility: hidden;
    width: 300px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    z-index: 999
}

.box-search-hover:hover .search-form.search-form6 {
    opacity: 1;
    margin: 0 0 0 -10px;
    visibility: visible
}

.box-search-hover .search-form.search-form6 input[type=text] {
    padding-left: 50px
}

.box-search-hover .search-form.search-form6::after {
    font-size: 30px;
    width: 53px
}

.box-search-hover {
    display: inline-block;
    position: relative
}

.box-search-hover .icon-search-hover,
.main-nav-hover .icon-menu-hover {
    font-size: 30px
}

.item-banner7 .banner-info h2 {
    font-size: 60px;
    margin: 0
}

.item-banner7 .banner-info h3 {
    font-size: 30px;
    margin: 10px auto 22px
}

.btn-eye.btn-black {
    background: #3b3b3b;
    color: #fff
}

.item-banner7 .btn-eye.btn-black {
    letter-spacing: 1px
}

.item-banner7 .banner-info::after {
    background: rgba(255, 255, 255, .9);
    content: "";
    height: 300px;
    left: 50%;
    margin-left: -150px;
    margin-top: -65px;
    position: absolute;
    top: 0;
    width: 300px
}

.item-banner7 .inner-banner-info {
    position: relative;
    z-index: 99
}

.item-banner7 .banner-info {
    margin-top: -100px
}

.directnav-slider.banner-slider7 .owl-theme .owl-controls .owl-buttons div {
    background: 0 0;
    color: #3b3b3b;
    font-size: 60px;
    height: auto;
    margin: -30px 0 0;
    top: 50%;
    width: auto
}

.pro-box-thumb {
    overflow: hidden;
    position: relative
}

.pro-box-thumb img {
    width: 100%
}

.pro-box-thumb .second-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-banner7 .product-thumb>a,
.item-product7 {
    position: relative
}

.item-pro-box:hover .pro-box-thumb .second-image {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1)
}

.pro-box-info>h2 {
    font-size: 60px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.pro-box-info strong {
    display: block;
    margin-top: -5px
}

.item-pro-box.new-pro:hover .pro-box-info>h2 {
    margin-left: 0
}

.item-pro-box.top-pro:hover .pro-box-info>h2 {
    margin-left: -30px
}

.item-pro-box.bet-pro:hover .pro-box-info>h2 {
    margin-right: 0
}

.btn-eye.eye-white {
    background: #fff;
    border: 1px solid #e5e5e5;
    color: #3b3b3b
}

.blog-info-extra a,
.btn-eye.eye-white:hover {
    color: #fff
}

.btn-eye.eye-white:hover::before {
    border-color: rgba(255, 255, 255, .1);
    color: #fff
}

.btn-eye.eye-white::before {
    border-color: #e5e5e5;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.title8,
.top-header8 {
    border-bottom: 1px solid #e5e5e5
}

.pro-box-info .btn-eye.eye-white {
    letter-spacing: 1px;
    margin-top: 14px
}

.new-pro .pro-box-thumb {
    margin-top: -150px
}

.new-pro .pro-box-info>h2 {
    margin-left: -93px;
    margin-top: 95px
}

.item-pro-box {
    margin-bottom: 30px
}

.bet-pro .pro-box-info {
    position: relative;
    text-align: right;
    z-index: 99
}

.bet-pro .pro-box-info h2 {
    margin-right: -90px;
    margin-top: 68px
}

.top-pro .pro-box-info {
    margin-top: -52px;
    padding: 0 30px
}

.list-product7 {
    background: #f9f9f9;
    padding: 65px 0;
    margin-bottom: 90px
}

.item-banner7 .product-thumb>a::after,
.item-product7 .product-thumb .product-thumb-link::after {
    background: rgba(0, 0, 0, .1);
    content: "";
    height: 100%;
    width: 100%;
    top: 0;
    left: 0
}

.pro-box {
    margin-bottom: 70px
}

.list-product7 h2 {
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 35px;
    text-align: center
}

.item-product7 {
    margin: 0 15px
}

.wrap-item .owl-item>div.item-product7 {
    overflow: visible
}

.product-slider7 {
    margin: 0 -15px
}

.item-product7 .product-extra-link.style2 {
    margin-top: 23px
}

.item-banner7 .product-thumb>a::after {
    position: absolute
}

.item-product7 .product-thumb .product-thumb-link {
    display: block;
    position: relative
}

.item-product7 .product-thumb .product-thumb-link::after {
    position: absolute;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0);
    opacity: 0
}

.item-product7:hover .product-rating,
.item-product7:hover .product-thumb .product-thumb-link::after,
.item-product7:hover .qickview-link {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1
}

.item-product7 .product-rating {
    bottom: 20px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    z-index: 999;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0);
    opacity: 0
}

.item-product7 .qickview-link {
    background: #fff;
    bottom: 0;
    height: 60px;
    left: 0;
    line-height: 60px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 60px;
    font-size: 30px;
    border-radius: 6px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0);
    opacity: 0
}

.item-product7 .qickview-link span {
    display: block;
    line-height: 60px
}

.item-product7 .product-thumb {
    margin: 0
}

.item-product7 .product-info {
    padding: 28px 30px 25px;
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product7:hover .product-info {
    background: #fff;
    box-shadow: 0 4px 4px -1px rgba(0, 0, 0, .2)
}

.item-product7 .product-extra-link.style2 .addcart-link {
    display: block;
    margin-bottom: 15px
}

.blog-info-extra>li,
.header-intro.header-intro8 {
    display: inline-block
}

.item-product7 .product-extra-link.style2 a {
    margin-left: 0;
    margin-right: 25px
}

.product-slider7 .owl-wrapper-outer {
    padding-bottom: 5px
}

.content-from-blog7>h2 {
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 35px;
    text-align: center
}

.blog-info-extra {
    background: rgba(0, 0, 0, .5);
    bottom: 0;
    font-size: 0;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 9px 0;
    position: absolute;
    width: 100%
}

.item-from-blog7 .blog-thumb {
    margin-bottom: 15px;
    position: relative
}

.blog-info-extra>li {
    font-size: 13px;
    margin-left: 20px
}

.blog-info-extra a .lnr {
    margin-right: 10px
}

.item-from-blog7 .btn-eye.eye-white {
    margin-top: 15px
}

.from-blog7 {
    margin-bottom: 100px
}

.product-slider7 .owl-theme .owl-controls .owl-buttons div {
    background-color: #3b3b3b;
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    top: 160px;
    width: 50px
}

.product-slider7 .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: -45px
}

.product-slider7 .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -45px
}

.item-banner8 {
    position: relative
}

.item-latest8 .blog-info-extra {
    background: 0 0;
    margin: 0;
    padding: 0;
    position: static
}

.item-latest8 .blog-info-extra li {
    margin: 0 20px 0 0
}

.item-latest8 .blog-info-extra li a {
    color: #999
}

.title8 {
    font-weight: 700;
    margin: 0 0 30px;
    padding-bottom: 12px
}

.addcart-link.btn-eye::before {
    content: "";
    font-family: fontawesome
}

.about-box::after,
.item-product10 .product-info::before,
.parralax-info>h2::after {
    content: ""
}

.top-header8 {
    padding: 32px 0;
    margin-bottom: 40px
}

.header-intro.header-intro8>a {
    font-size: 16px
}

.logo8 {
    left: 50%;
    margin-left: -100px;
    position: absolute;
    top: -32px;
    width: 200px
}

.box-search-hover.search-hover8 {
    margin-right: 50px
}

.box-search-hover.search-hover8 .icon-search-hover {
    font-size: 16px
}

.box-search-hover.search-hover8 .search-form.search-form6::after {
    font-size: 16px;
    width: 42px
}

.box-search-hover.search-hover8 .search-form.search-form6 input[type=text] {
    padding-left: 42px
}

.box-search-hover.search-hover8 .search-form.search-form6 {
    top: -12px
}

.wrap-cart8 .profile-box.profile-box7 .lnr {
    font-size: 16px
}

.wrap-cart8 .mini-cart7 .mini-cart-link .mini-cart-icon {
    font-size: 16px;
    height: 16px;
    line-height: 16px
}

.wrap-cart8 .mini-cart-link {
    margin-top: -3px
}

.wrap-cart8 .mini-cart7 .mini-cart-number {
    right: -10px;
    top: -12px
}

.wrap-cart7.wrap-cart8 {
    margin: 0
}

.header-nav8 {
    padding: 40px 0 35px
}

.main-nav.main-nav8>ul>li>a {
    color: #3b3b3b;
    font-size: 13px;
    height: 30px;
    line-height: 30px
}

.main-nav.main-nav8 {
    margin: auto;
    max-width: 580px
}

.item-adv8 {
    margin: 0 -15px;
    position: relative;
    overflow: hidden
}

.list-adv8 {
    margin-bottom: 95px;
    padding: 0 15px
}

.item-adv8 .adv-info {
    color: #fff;
    left: 0;
    max-width: 398px;
    padding: 50px;
    position: absolute;
    top: 0;
    width: 100%
}

.item-adv8 .adv-info h2 {
    font-weight: 700;
    margin: 0 0 17px
}

.item-adv8 .btn-eye.eye-white {
    margin-top: 45px;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-adv8:hover .btn-eye.eye-white {
    opacity: 1;
    visibility: visible;
    margin-top: 15px
}

.item-adv8 .adv-thumb img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 100%
}

.item-adv8:hover .adv-thumb img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.wrap-cart8 .mini-cart7.mini-cart-box {
    margin-left: 55px
}

.item-product8 {
    padding: 0 15px 10px;
    text-align: center
}

.product-slider8 {
    margin: 0 -15px
}

.item-product8 .quickview-link {
    bottom: 0;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 154px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0);
    opacity: 0
}

.item-product8:hover .quickview-link {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1
}

.item-product8 .product-thumb {
    margin-bottom: 30px
}

.item-product8 .product-price {
    margin: 5px 0 10px
}

.item-product8 .addcart-link {
    margin: 0 15px
}

.product-slider8 .owl-controls {
    position: absolute;
    right: 15px;
    top: -80px
}

.product-slider8 .owl-theme .owl-controls .owl-buttons div {
    background: #3b3b3b;
    border-radius: 4px;
    color: #fff;
    height: 30px;
    line-height: 30px;
    margin: 0 0 0 1px;
    width: 30px
}

.product-slider8 .owl-theme .owl-controls .owl-buttons div span {
    display: block;
    line-height: 30px
}

.banner8,
.list-product8 {
    margin-bottom: 95px
}

.item-banner8 .banner-info {
    color: #fff;
    margin-top: -85px
}

.item-banner8 .banner-info p {
    margin: 15px auto 25px;
    max-width: 630px
}

.item-banner8 .banner-info h2 {
    font-weight: 700;
    margin: 0
}

.item-banner8 .banner-info .btn-eye {
    letter-spacing: 1px
}

.directnav-slider.latest-slider8 .owl-controls {
    position: absolute;
    right: 0;
    top: -80px
}

.directnav-slider.latest-slider8 .owl-theme .owl-controls .owl-buttons div {
    background: #3b3b3b;
    border-radius: 4px;
    color: #fff;
    height: 30px;
    line-height: 30px;
    margin: 0 0 0 1px;
    width: 30px;
    position: static;
    opacity: 1;
    font-size: 13px
}

.directnav-slider.latest-slider8 .owl-theme .owl-controls .owl-buttons div span.lnr {
    display: block;
    line-height: 30px;
    height: 30px
}

.directnav-slider.latest-slider8 .wrap-item {
    overflow: visible
}

.latest-news8 {
    background: url(../images/home8/bg-pr.jpg) center center no-repeat fixed rgba(0, 0, 0, 0);
    padding: 95px 0 100px
}

.item-latest8 .post-thumb {
    background: #fff;
    padding: 16px;
    margin-right: -30px
}

.item-latest8 .post-format-date {
    right: 16px;
    top: 16px;
    left: auto
}

.item-latest8 .post-info {
    padding: 60px 0 0 60px
}

.item-latest8 .post-info .post-desc {
    margin: 15px 0 50px
}

.adv9 {
    margin-bottom: 100px
}

.product-tab-title9>ul {
    bottom: 0;
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0
}

.product-tab-title9 {
    position: relative
}

.product-tab-title9 li {
    display: inline-block
}

.product-tab-title9 a {
    display: block;
    font-size: 13px;
    font-weight: 700;
    height: 60px;
    line-height: 60px;
    padding: 0 20px
}

.product-tab-title9>ul li.active a {
    color: #fff
}

.product-slider8.tab-slider9 .owl-controls {
    position: static
}

.product-slider8.tab-slider9 .owl-theme .owl-controls .owl-buttons div {
    margin: 0;
    position: absolute;
    top: 130px
}

.product-slider8.tab-slider9 .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: 0
}

.product-slider8.tab-slider9 .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 0
}

.item-banner9 .banner-info {
    margin: 0;
    padding: 95px 0;
    position: static
}

.item-banner9 .banner-info h2 {
    font-size: 60px;
    font-weight: 700;
    margin: 5px 0 0
}

.item-banner9 .banner-info h4 {
    font-size: 30px;
    margin: 0
}

.banner-slider9 .owl-controls {
    bottom: 260px;
    height: 140px;
    left: 50%;
    margin-left: -50px;
    padding-top: 10px;
    position: absolute;
    width: 100px
}

.banner-slider9 .owl-theme .owl-controls .owl-buttons div {
    display: block;
    font-size: 30px;
    height: auto;
    line-height: inherit;
    width: auto
}

.banner-slider9 .current-item {
    display: block;
    height: 30px;
    line-height: 30px;
    font-weight: 700
}

.banner-slider9 .owl-theme .owl-controls .owl-buttons div.total-slide {
    opacity: 1 !important
}

.banner-slider9 .owl-theme .owl-controls .owl-buttons div:hover {
    opacity: .5
}

.about-box {
    background: #d96a77;
    margin-bottom: 100px;
    margin-top: 100px;
    position: relative
}

.about-box::after {
    background: rgba(0, 0, 0, .1);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.intro-about-slider.intro-featured6>h2 {
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 30px
}

.tb-price-rate {
    margin-bottom: 13px
}

.intro-about-slider.intro-featured6>h3 {
    font-size: 30px;
    margin: 0 0 35px
}

.intro-about-slider .fea-info p {
    color: #fff;
    line-height: 24px
}

.intro-about-slider.intro-featured6 {
    max-width: 470px;
    padding: 90px 0 90px 100px
}

.about-slider {
    margin: -100px -30px 0 0
}

.intro-about-slider.intro-featured6 .btn-eye:hover {
    background: #3b3b3b
}

.inner-about-box {
    position: relative;
    z-index: 99
}

.about-slider .owl-pagination {
    bottom: 100px;
    left: 0;
    position: absolute;
    width: 100%
}

.about-slider .owl-theme .owl-controls .owl-page span {
    border-color: #fff
}

.about-slider .owl-theme .owl-controls .owl-page.active span::before,
.about-slider .owl-theme .owl-controls .owl-page:hover span::before {
    background: #fff
}

.about-slider .owl-buttons {
    background: #d96a77;
    height: 140px;
    left: 200%;
    margin-left: -130px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    width: 100px;
    z-index: 999
}

.about-slider .owl-theme .owl-controls .owl-buttons div {
    display: block;
    font-size: 30px;
    height: auto;
    line-height: inherit;
    width: auto
}

.about-slider .current-item {
    display: block;
    height: 30px;
    line-height: 30px;
    font-weight: 700
}

.about-slider .owl-theme .owl-controls .owl-buttons div.total-slide {
    opacity: 1 !important
}

.about-slider .owl-theme .owl-controls .owl-buttons div:hover {
    opacity: .5
}

.item-product10 {
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    margin: 0 0 30px;
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product10:hover {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, .2)
}

.new-product10 {
    padding: 0 15px
}

.item-product10 .product-info {
    padding: 30px;
    position: relative;
    z-index: 999
}

.item-product10 .product-info::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-parralax,
.parralax-info>h2 {
    position: relative
}

.item-product10:hover .product-info::before {
    height: 1px;
    background: #e5e5e5
}

.extra-link10 a {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 40px
}

.load-more10 {
    border-radius: 4px;
    display: block;
    font-size: 13px;
    font-weight: 700;
    height: 58px;
    line-height: 58px;
    margin: 20px auto 50px;
    max-width: 400px;
    text-align: center;
    width: 100%
}

.load-more10 .lnr {
    font-size: 30px;
    margin-right: 10px;
    vertical-align: middle
}

.item-product10 .product-thumb {
    margin: 0
}

.directnav-slider .owl-theme .owl-controls .owl-buttons div span.lnr {
    display: block;
    height: 50px;
    line-height: 50px
}

.item-banner10 .banner-info-text {
    color: #fff;
    max-width: 570px
}

.item-banner10 .banner-info h4 {
    font-size: 30px;
    margin: 0;
    text-transform: uppercase
}

.item-banner10 .banner-info h2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 60px;
    margin: 35px 0
}

.item-banner10 .banner-info .btn-eye {
    margin-top: 35px
}

.banner-info-text.style2 {
    float: left
}

.top-header10 {
    background: #f4f4f4;
    padding: 5px 0
}

.top-header10 .search-form.search-form2 {
    border: 1px solid #e5e5e5;
    float: right;
    margin: 0;
    max-width: 240px;
    padding: 0;
    width: 100%
}

.pr-icon,
.pr-icon::after {
    border-radius: 50%;
    transition: all .5s ease-out 0s
}

.top-info4.top-info10 {
    margin-top: 8px;
    text-align: left
}

.logo10,
.new-product10 h2,
.parralax-info {
    text-align: center
}

.main-nav.main-nav10 {
    background: #d96a77;
    margin: 0
}

.main-nav.main-nav10>ul {
    margin: auto;
    max-width: 650px
}

.main-header10 {
    padding: 35px 0 40px
}

.header-intro.header-intro10 {
    margin-top: 15px
}

.mini-cart-box.mini-cart4.mini-cart10 {
    float: right;
    margin: 18px 0 0
}

.main-nav.main-nav10>ul>li>a {
    color: #fff;
    height: 50px;
    line-height: 50px
}

.main-nav.main-nav10>ul>li:hover>a {
    opacity: .8
}

.header-nav10 {
    margin-bottom: 50px
}

.parralax-info {
    color: #fff;
    margin: 30% auto auto;
    max-width: 300px
}

.parralax-info>h3 {
    font-size: 16px;
    font-weight: 700;
    margin: 27px 0 0
}

.parralax-info>h2 {
    font-size: 30px;
    margin: 21px 0 50px
}

.parralax-info>p {
    line-height: 24px
}

.item-banner11 .product-price {
    margin-bottom: 10px;
    margin-top: -5px
}

.parralax-info>h2::after {
    background: #fff;
    bottom: -27px;
    height: 4px;
    left: 50%;
    margin-left: -25px;
    position: absolute;
    width: 50px
}

.item-parralax.parallax::before,
.pr-icon::after {
    top: 0;
    left: 0;
    height: 100%;
    content: ""
}

.pr-icon {
    display: inline-block;
    position: relative;
    -webkit-transition: all .5s ease-out 0s
}

.pr-icon:hover {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg)
}

.pr-icon::after {
    background: rgba(255, 255, 255, .1);
    position: absolute;
    transform: scale(0);
    -webkit-transform: rotate(0) scale(0);
    -webkit-transition: all .5s ease-out 0s;
    width: 100%
}

.pr-icon:hover::after {
    transform: rotate(360deg) scale(1.3);
    -webkit-transform: scale(1.3)
}

.parralax-slider {
    margin-bottom: 90px;
    overflow: hidden;
    position: relative
}

.new-product10 h2 {
    font-size: 60px;
    font-weight: 700;
    margin: 0 0 85px
}

.parralax-thumb img {
    opacity: 0
}

.item-parralax {
    background-attachment: fixed;
    background-size: 50% auto;
    background-repeat: no-repeat;
    background-position: right top;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-parralax.parallax::before {
    position: absolute;
    width: 50%;
    background: rgba(0, 0, 0, .1)
}

.item-banner11 .banner-info {
    background: rgba(255, 255, 255, .9);
    left: auto;
    padding: 80px 100px;
    right: 100px;
    text-align: left;
    width: 540px
}

.item-banner11 .banner-info h2 {
    font-weight: 700
}

.item-banner11 .banner-info .btn-eye {
    margin-top: 2px
}

.header-nav10.header-nav11 {
    margin-bottom: 30px
}

.banner-home2.banner-home11,
.list-adv11 {
    margin-bottom: 50px
}

.title-box11 {
    border-bottom: 1px solid #e5e5e5;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 17px 0 14px;
    text-align: center
}

.box-home11,
.item-banner8.img-banner11 {
    margin-bottom: 50px
}

.box-home11 {
    border-color: #e5e5e5;
    border-style: solid;
    border-width: 1px
}

.item-cat-brow {
    padding: 20px;
    text-align: center
}

.slider-home11 .owl-controls {
    position: absolute;
    right: 7px;
    top: -36px
}

.slider-home11 .owl-theme .owl-controls .owl-buttons div {
    color: #a9a9a9;
    font-size: 16px;
    margin: 0 8px
}

.cat-brow-thumb {
    margin-bottom: 30px;
    position: relative
}

.cat-brow-thumb .btn-eye,
.cat-brow-thumb::after {
    position: absolute;
    transition: all .5s ease-out 0s;
    top: 0;
    left: 0
}

.cat-brow-info>h3 {
    font-size: 13px;
    margin: 0 0 10px;
    font-weight: 700
}

.cat-brow-info>span {
    color: #999
}

.cat-brow-info {
    padding-bottom: 2px
}

.cat-brow-thumb .btn-eye {
    background: #fff;
    border: none;
    bottom: 0;
    color: #3b3b3b;
    letter-spacing: 1px;
    margin: auto;
    padding: 0 15px 0 50px;
    right: 0;
    width: 168px;
    z-index: 9;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-transition: all .5s ease-out 0s
}

.cat-brow-thumb::after {
    background: rgba(0, 0, 0, .2);
    content: "";
    height: 100%;
    width: 100%;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-transition: all .5s ease-out 0s
}

.item-cat-brow:hover .cat-brow-thumb .btn-eye,
.item-cat-brow:hover .cat-brow-thumb::after {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-product11 {
    border-right: 1px solid #e5e5e5;
    padding: 120px 20px 28px;
    position: relative;
    text-align: center
}

.item-product11 .product-rating {
    margin: auto auto 28px
}

.product-cat {
    color: #999;
    display: inline-block;
    margin-bottom: 12px
}

.product-label {
    background: #dda379;
    border-radius: 0 0 18px 18px;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    left: 0;
    line-height: 18px;
    padding: 10px 11px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 36px;
    word-wrap: break-word;
    z-index: 99;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product11 .product-label {
    top: 20px;
    left: 20px;
    font-family: 'Days One', sans-serif;
    font-size: 13px
}

.product-label.hot-label {
    background: #ff1110
}

.product-label.top-label {
    background: #925ea2
}

.product-label.fea-label {
    background: #67d8c9
}

.product-label.sel-label {
    background: #87d568
}

.item-product11 .product-extra-link {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    z-index: 999
}

.item-product11 .product-extra-link a {
    border: 1px solid transparent;
    border-radius: 4px;
    display: block;
    height: 40px;
    line-height: 40px;
    width: 40px
}

.item-product-countdown11 .product-thumb-link::after,
.weekday-countdown canvas {
    display: none
}

.item-product11 .product-extra-link a:hover {
    border-color: #e5e5e5
}

.item-product11 .product-thumb .btn-eye {
    background: #fff;
    border: none;
    bottom: 0;
    color: #3b3b3b;
    left: 0;
    letter-spacing: 1px;
    margin: auto;
    padding: 0 15px 0 50px;
    position: absolute;
    right: 0;
    top: 0;
    width: 155px;
    z-index: 9;
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product11 .product-thumb-link::after,
.weekday-countdown {
    left: 0;
    width: 100%;
    transition: all .5s ease-out 0s;
    position: absolute
}

.item-product11 .product-thumb .btn-eye:hover {
    color: #fff
}

.item-product11 .product-thumb-link::after {
    background: rgba(0, 0, 0, .2);
    content: "";
    height: 100%;
    top: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-transition: all .5s ease-out 0s
}

.item-product11:hover .product-thumb .btn-eye,
.item-product11:hover .product-thumb-link::after {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-product-countdown11:hover .weekday-countdown {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.weekday-countdown {
    background: rgba(0, 0, 0, .5);
    bottom: 0;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    padding: 12px;
    text-align: center;
    transform: scale(0);
    -webkit-transform: scale(0);
    -webkit-transition: all .5s ease-out 0s
}

.weekday-countdown .time_circles>div {
    display: inline-block;
    width: auto !important
}

.weekday-countdown .time_circles>div::after {
    content: ":";
    margin: 0 5px
}

.weekday-countdown .time_circles>div:last-child::after {
    content: "";
    margin: 0
}

.from-blog-slider11 .owl-controls {
    position: absolute;
    right: 7px;
    top: -36px
}

.from-blog-slider11 .owl-theme .owl-controls .owl-buttons div {
    color: #a9a9a9;
    font-size: 16px;
    margin: 0 8px
}

.item-from-blog11 {
    border-right: 1px solid #e5e5e5;
    padding: 20px 21px;
    text-align: center
}

.item-from-blog11 .post-cat {
    color: #999;
    display: inline-block;
    margin-bottom: 10px
}

.item-from-blog11 .post-format-date {
    left: 50%;
    margin-left: -45px;
    top: 0
}

.item-from-blog11 .post-thumb {
    margin-bottom: 15px
}

.item-from-blog11 .btn-eye {
    margin: 28px 0 10px
}

.product-type-slider .owl-theme .owl-controls .owl-buttons div {
    margin: 0;
    position: absolute;
    top: -40px
}

.product-type-slider .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: 15px
}

.product-type-slider .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: 15px
}

.product-type-slider .item {
    padding: 20px
}

.item-product-type {
    border-bottom: 1px solid #e5e5e5;
    display: table;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px
}

.item-product-type:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.item-product-type .product-thumb {
    display: table-cell;
    width: 120px
}

.item-product-type .product-thumb-link {
    width: 120px
}

.item-product-type .product-info {
    display: table-cell;
    padding-top: 10px;
    padding-left: 20px;
    vertical-align: top
}

.item-product-type .quickview-link {
    width: 40px;
    height: 40px;
    display: block;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #fff;
    border-radius: 4px;
    transform: scale(0) rotate(0);
    -webkit-transform: scale(0) rotate(0);
    z-index: 9;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product-type .quickview-link .lnr {
    display: block;
    line-height: 40px
}

.item-product-type .quickview-link:hover {
    color: #fff
}

.item-product-type:hover .quickview-link {
    transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg)
}

.item-product-type .product-thumb-link::after {
    background: rgba(0, 0, 0, .2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transform: scale(0) rotate(0);
    -webkit-transform: scale(0) rotate(0);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product-type:hover .product-thumb-link::after {
    transform: scale(1) rotate(360deg);
    -webkit-transform: scale(1) rotate(360deg)
}

.top-info4.top-info12 {
    display: inline-block
}

.wrap-top-header12 {
    float: right
}

.top-header12 {
    background: #f4f4f4
}

.header-intro.header-intro12 {
    margin-top: 25px
}

.header-intro.header-intro12 .lnr,
.top-info4.top-info12 li .lnr {
    font-size: 13px
}

.mini-cart12 .mini-cart-header {
    background: #d96a77;
    height: 70px;
    padding-left: 30px;
    padding-top: 18px;
    position: relative;
    width: 240px
}

.mini-cart12 .mini-cart-icon {
    color: #fff;
    font-size: 30px;
    height: 30px;
    line-height: 30px
}

.mini-cart12 .price-cart-top {
    border: none;
    color: #fff;
    margin-left: 10px;
    padding: 0
}

.mini-cart-box.mini-cart12:hover .mini-cart-content {
    margin-top: 0
}

.main-header12 {
    padding: 48px 0
}

.main-nav.main-nav12>ul>li>a {
    color: #3b3b3b;
    font-size: 13px;
    height: 44px;
    line-height: 44px
}

.banner-text-info.text-white,
.inner-banner-info1>h3 a {
    color: #fff
}

.main-nav.main-nav12>ul>li {
    margin-right: 30px
}

.search-form.search-form2.search-form12 {
    border: 1px solid #e5e5e5;
    float: right;
    margin: 0;
    max-width: 240px;
    padding: 0;
    width: 100%
}

.inner-banner-info1>h2 span {
    display: inline-block;
    padding: 20px 40px 15px;
    position: relative
}

.inner-banner-info1>h2 {
    font-size: 60px;
    margin: 0
}

.inner-banner-info1 {
    color: #fff;
    text-align: right;
    max-width: 800px;
    float: right
}

.inner-banner-info1>h3 {
    background: rgba(59, 59, 59, .9);
    display: inline-block;
    font-size: 30px;
    margin: 10px 0 0;
    opacity: .9;
    padding: 12px 30px 14px
}

.item-banner1 .banner-info {
    margin-top: -100px
}

.item-adv3:hover .adv-default img {
    transform: scale(1.25) rotate(5deg);
    -webkit-transform: scale(1.25) rotate(5deg)
}

.banner-slider1 .owl-controls {
    margin: -50px 0 0;
    position: absolute;
    right: 50px;
    top: 50%;
    width: 10px
}

.banner-slider1 .owl-theme .owl-controls .owl-page span {
    margin: 5px 0
}

.inner-banner-info1>h3 .lnr {
    margin-left: 20px;
    display: inline-block;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.inner-banner-info1>h3:hover .lnr {
    transform: translateX(10px);
    -webkit-transform: translateX(10px)
}

.banner-slider12 {
    margin-bottom: 25px
}

.item-banner-text {
    position: relative
}

.banner-text-info.text-black {
    color: #3b3b3b
}

.banner-text-info.text-white>a,
.item-product12 .product-price span,
.item-product12 .product-price strong,
.item-product12 .quickview-link:hover {
    color: #fff
}

.banner-text-info {
    bottom: 0;
    left: 0;
    padding-bottom: 80px;
    padding-left: 55px;
    position: absolute
}

.banner-text-info h3 {
    font-size: 30px;
    margin: 0;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.banner-text-info h2 {
    font-size: 60px;
    margin: 2px 0 20px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.list-banner-text,
.product-tab12 {
    margin-bottom: 100px
}

.banner-text-info a {
    display: inline-block;
    font-size: 16px
}

.banner-text-info a::after {
    content: "";
    font-family: linearicons;
    margin-left: 15px
}

.item-banner-text:hover .banner-text-info h2 {
    font-size: 30px
}

.item-banner-text:hover .banner-text-info h3 {
    font-size: 60px
}

.product-tab12 {
    background: #f4f4f4;
    padding-bottom: 100px
}

.product-tab-title12 {
    margin-bottom: 100px;
    text-align: center
}

.product-tab-title12>ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.product-tab-title12 li {
    display: inline-block;
    margin: 0 5px
}

.product-tab-title12 a {
    background: #3b3b3b;
    color: #fff;
    display: block;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
    padding: 0 40px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product12 {
    padding: 0 15px;
    text-align: center
}

.product-tab-slider12 {
    margin: 0 -15px
}

.item-product12 .product-thumb {
    margin: 0
}

.item-product12 .product-info {
    background: #fff;
    padding-bottom: 10px;
    position: relative
}

.item-product12 .product-thumb-link::after {
    background: rgba(0, 0, 0, .2);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.item-product12:hover .product-thumb-link::after {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-product12 .quickview-link {
    background: #fff;
    border-radius: 4px;
    bottom: 0;
    font-size: 30px;
    height: 60px;
    left: 0;
    line-height: 60px;
    margin: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 60px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.item-product12:hover .quickview-link {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-product12 .quickview-link .lnr {
    line-height: 60px
}

.item-product12 .product-rating {
    margin: auto
}

.item-product12 .addcart-link {
    margin: 0 14px
}

.item-product12 .product-price {
    background: #d96a77;
    height: 60px;
    line-height: 60px;
    margin-bottom: 28px
}

.item-product12 .product-price span::after {
    background: #fff
}

.item-product12 .product-title {
    margin-bottom: 17px
}

.wrap-product-rating {
    background: #fff;
    left: 0;
    padding-bottom: 20px;
    position: absolute;
    top: 100%;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    margin-top: -50px
}

.item-product12:hover .wrap-product-rating {
    box-shadow: 0 6px 10px -5px rgba(0, 0, 0, .2);
    margin-top: 0
}

.item-product12 .product-extra-link {
    background: #fff;
    padding-bottom: 20px;
    position: relative;
    z-index: 999
}

.google-map {
    margin-bottom: -10px
}

.google-map iframe {
    width: 100%
}

.product-tab-slider12 .owl-wrapper-outer {
    margin-bottom: -50px;
    padding-bottom: 50px
}

.product-tab-slider12 .owl-theme .owl-controls .owl-buttons div {
    background: rgba(0, 0, 0, .5);
    border-radius: 4px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 110px;
    width: 50px
}

.product-tab-slider12 .owl-theme .owl-controls .owl-buttons div.owl-prev {
    left: -10px
}

.product-tab-slider12 .owl-theme .owl-controls .owl-buttons div.owl-next {
    right: -10px
}

.item-product12:hover .product-info {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .1)
}

.list-cat-icon.list-cat-icon12::after,
.list-cat-icon.list-cat-icon12::before {
    background: #fff
}

.wrap-list-cat12 {
    margin-bottom: 100px
}

.list-cat-icon12 .inner-cat-icon {
    border: 15px solid #fff
}

.about-menu {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    margin: 0;
    padding: 0
}

.about-menu>li {
    display: inline-block;
    margin-right: 36px
}

.about-menu a::after {
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    top: -1px;
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    width: 0
}

.about-menu a {
    color: #3b3b3b;
    display: block;
    padding: 15px 0;
    position: relative;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.about-menu a:hover::after {
    left: 0;
    width: 100%
}

.contact-info-page {
    background: #f4f4f4;
    padding: 70px 0;
    text-align: center
}

.list-contact-info {
    margin-bottom: 45px
}

.item-contact-info::after {
    background: #999;
    content: "";
    height: 2px;
    left: 100%;
    margin-left: -50px;
    position: absolute;
    top: 28px;
    width: 100px
}

.item-contact-info {
    position: relative;
    text-align: center
}

.item-contact-info .contact-icon {
    display: inline-block;
    overflow: hidden;
    position: relative
}

.item-contact-info a i {
    color: #999;
    font-size: 60px;
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.item-contact-info>h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 25px 0 0
}

.item-contact-info a {
    color: #333;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-contact-info .contact-icon.icon-quality::before {
    content: ""
}

.item-contact-info .contact-icon::before {
    color: #999;
    font-family: fontawesome;
    font-size: 60px;
    left: 0;
    opacity: 0;
    position: absolute;
    top: -1px;
    -webkit-transform: translateX(-200%);
    transform: translateX(-200%);
    -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s
}

.item-contact-info .contact-icon.icon-shipment::before {
    content: ""
}

.item-contact-info .contact-icon.icon-payment::before {
    content: "\f156"
}

.item-contact-info .contact-icon:hover::before {
    opacity: 1;
    -webkit-transform: translateX(0) rotate(0);
    transform: translateX(0) rotate(0)
}

.item-contact-info .contact-icon:hover i {
    opacity: 0;
    -webkit-transform: translateX(200%);
    transform: translateX(200%)
}

.item-contact-info.last-item::after {
    display: none
}

.contact-info-page .desc {
    color: #555;
    margin: 0 auto;
    max-width: 890px;
    padding: 0 15px
}

.protect-video {
    margin-top: 95px
}

.about-full-protec h2 {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 40px
}

.about-full-protec ul {
    margin: 0;
    max-width: 450px;
    position: relative;
    padding: 0;
    list-style: none
}

.about-full-protec li {
    display: table;
    margin-bottom: 75px;
    position: relative;
    width: 100%
}

.about-full-protec li::after {
    background: #e5e5e5;
    content: "";
    height: 50px;
    left: 30px;
    margin-top: 12px;
    position: absolute;
    top: 100%;
    width: 1px
}

.about-full-protec li:last-child::after {
    display: none
}

.about-full-protec span {
    border-radius: 50%;
    color: #fff;
    display: table-cell;
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    vertical-align: middle;
    width: 60px
}

.about-full-protec p {
    display: table-cell;
    padding-left: 25px;
    vertical-align: middle
}

.about-full-protec .new-user-guide {
    color: #3b3b3b;
    display: block;
    margin-top: 30px
}

.video-about {
    padding-right: 60px;
    padding-top: 20px;
    text-align: right
}

.about-review {
    border-top: 2px solid #e5e5e5;
    margin-top: 95px;
    padding-top: 95px
}

.about-review .title-default {
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 30px
}

.item-about-review {
    display: table;
    margin-bottom: 50px
}

.about-review-thumb {
    display: table-cell
}

.about-review-thumb a {
    border: 1px solid #e5e5e5;
    display: block;
    overflow: hidden;
    padding: 10px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s;
    width: 100px
}

.about-review-thumb a img {
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s
}

.about-info {
    display: table-cell;
    padding: 0 30px;
    vertical-align: top
}

.about-info h3 {
    font-size: 14px;
    margin: 0 0 8px
}

.machine_detect
{
  display: none;
}

.contact-form-page>h2,
.form-my-account .title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase
}

.about-info p {
    color: #999;
    margin: 0
}

.about-review-thumb a:hover img {
    transform: scale(1.15);
    -webkit-transform: scale(1.15)
}

.contact-map {
    border: 1px solid #e5e5e5;
    padding: 10px
}

.item-contact-info .contact-icon.icon-mobile::before {
    content: ""
}

.item-contact-info .contact-icon.icon-phone::before {
    content: ""
}

.item-contact-info .contact-icon.icon-email::before {
    content: ""
}

.contact-form-page>h2 {
    margin: 0 0 37px
}

.contact-form-page .form-contact input[type=text] {
    border: 1px solid #e5e5e5;
    color: #999;
    height: 42px;
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%
}

.contact-form-page .form-contact textarea {
    border: 1px solid #e5e5e5;
    margin-bottom: 30px;
    padding: 20px;
    width: 100%
}

.contact-form-page .form-contact input[type=submit] {
    background: #3b3b3b;
    border: none;
    color: #fff;
    height: 40px;
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    width: 110px
}

.contact-form-page {
    margin-top: 45px
}

.title-shop-page {
    font-weight: 700;
    margin: 0 0 35px;
    text-align: center;
    text-transform: uppercase
}

.form-my-account {
    max-width: 370px;
    width: 100%
}

.form-my-account .title {
    margin: 0 0 10px
}

.form-my-account>p {
    margin-bottom: 20px;
    max-width: 370px;
    position: relative
}

.form-my-account input[type=text] {
    border: 1px solid #e5e5e5;
    color: #999;
    font-weight: 700;
    height: 50px;
    padding: 0 20px;
    text-transform: uppercase;
    width: 100%
}

.form-my-account input[type=submit] {
    background: #3b3b3b;
    border: none;
    color: #fff;
    height: 40px;
    margin-top: 10px;
    transition: all .3s ease-out 0s;
    -webkit-transition: all .3s ease-out 0s;
    width: 110px
}

.form-my-account input[type=checkbox] {
    display: none
}

.form-my-account input[type=checkbox]:checked+label::before {
    content: ""
}

.form-my-account input[type=checkbox]+label::before {
    border: 1px solid #e5e5e5;
    content: "";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    line-height: 18px;
    margin-right: 17px;
    text-align: center;
    vertical-align: middle;
    width: 20px
}

.form-my-account a {
    color: #3b3b3b;
    position: absolute;
    right: 0;
    top: 0
}

.account-register::before {
    border-right: 1px solid #e5e5e5;
    content: "";
    height: 100%;
    left: -15px;
    position: absolute;
    top: 0;
    width: 1px
}

.general-setting .btn-setting,
.title-setting {
    font-size: 18px;
    height: 40px;
    line-height: 40px;
    color: #fff
}

.account-register {
    float: left;
    position: relative;
    width: 100%
}

.account-register .form-my-account {
    float: right
}

.content-page {
    margin-bottom: 50px;
    margin-top: 30px
}

.general-setting {
    background: #111;
    left: -200px;
    position: fixed;
    top: 200px;
    width: 200px;
    z-index: 999999;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.general-setting.active {
    left: 0
}

.title-setting {
    background: #222;
    margin: 0;
    padding: 0 15px;
    text-transform: uppercase
}

.content-general-setting {
    background: #111;
    padding-bottom: 20px
}

.general-setting .btn-setting {
    background: #222;
    border-radius: 0 6px 6px 0;
    left: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    width: 40px
}

.title-setting-box {
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    color: #fff;
    font-size: 14px;
    margin: 0 0 15px;
    padding-bottom: 10px;
    text-transform: uppercase
}

.general-setting-box {
    margin-top: 15px;
    padding: 0 15px
}

.general-setting-box ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.color-setting-box li {
    display: inline-block;
    margin-right: 6px
}

.color-setting-box li a {
    border: 1px solid #e5e5e5;
    display: block;
    height: 30px;
    width: 30px;
    position: relate
}

.color-setting-box li.active a::after {
    content: "\f00c";
    position: absolute;
    font-family: fontawesome;
    color: #fff;
    height: 28px;
    line-height: 28px;
    display: block;
    text-align: center;
    width: 28px
}

.direct-setting-box>select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../images/pages/arrow-down.png) right 10px center/10px auto no-repeat #fff;
    border: 1px solid #e5e5e5;
    color: #666;
    height: 30px;
    padding: 0 35px 0 5px
}

.layout-setting-box li {
    display: inline-block
}

.layout-setting-box li a {
    background: #fff;
    color: #333;
    display: block;
    height: 30px;
    line-height: 30px;
    margin-right: 6px;
    padding: 0 15px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.layout-setting-box li.active a {
    color: #fff
}

.boxed {
    background-image: url(../images/home8/bg-pr.jpg);
    backgound-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center top
}

.boxed .wrap {
    max-width: 1170px;
    width: 100%;
    position: relative;
    margin: auto
}

.boxed .mini-cart-content {
    right: 0
}

.general-setting-box.layout-setting-box {
    display: none
}

.special-product-info .product-extra-link.style2 a.btn-eye {
    height: 36px;
    line-height: 36px;
    color: #fff;
    background: #333;
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 0 6px
}

.main-nav.main-nav8 .toggle-mobile-menu::after {
    font-size: 18px
}

.main-nav.main-nav8 .toggle-mobile-menu {
    margin-top: 0
}

.from-blog7.from-blog12 {
    margin-top: 50px
}

.special-product-info .list-social-product li {
    display: inline-block
}

.special-product-info .list-social-product li a {
    margin: 5px
}

.item-product8 .product-extra-link>a {
    display: inline-block;
    position: relative
}

.item-product8 .product-extra-link>a>span {
    color: #fff;
    display: inline-block;
    font-size: 10px;
    left: 0;
    margin-left: -28px;
    position: absolute;
    text-align: center;
    text-transform: capitalize;
    top: 100%;
    width: 64px;
    z-index: 999;
    opacity: 0;
    margin-top: 30px;
    visibility: hidden;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-product8 .product-extra-link>a>span::after {
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    bottom: 100%;
    content: "";
    height: 0;
    left: 50%;
    position: absolute;
    width: 0
}

.item-product8 .product-extra-link>a:hover>span {
    margin-top: 0;
    opacity: 1;
    visibility: visible
}

.toggle-sidebar {
    background: #f4f4f4;
    font-size: 24px;
    margin: 20px 0;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    display: none
}

.toggle-sidebar::after {
    content: "\f196";
    font-family: fontawesome;
    font-size: 17px;
    position: absolute;
    right: 18px;
    top: 15px
}

.toggle-sidebar.active::after {
    content: "\f147"
}

.top-header1 {
    padding: 13px 0
}

.logo.logo1 {
    margin: auto;
    position: relative;
    text-align: center;
    width: 170px;
    z-index: 1000
}

.main-nav.main-nav1 {
    margin: -90px 0 0;
    text-align: center
}

.main-nav.main-nav1>ul>li>a {
    line-height: 80px;
    height: 80px
}

.mini-cart-box.mini-cart1 {
    float: right;
    margin-top: 21px
}

.mini-cart1 .mini-cart-icon {
    color: #fff;
    font-size: 30px
}

.mini-cart1 .mini-cart-number {
    color: #fff;
    font-size: 12px;
    height: 20px;
    line-height: 22px;
    right: -15px;
    width: 20px
}

.box-search-hover.search-hover1 {
    float: left;
    margin-top: 20px;
    z-index: 1000
}

.main-nav>ul>li.menu-blank-link {
    width: 200px
}

.main-header.main-header1 {
    padding: 0
}

.main-nav.main-nav1>ul {
    display: inline-block
}

.header-intro.header-intro1 {
    height: 50px;
    line-height: 50px
}

.btn-eye.bg-white {
    color: #3b3b3b;
    border: 1px solid rgba(0, 0, 0, .1)
}

.btn-eye.bg-white:hover::before {
    border-color: rgba(255, 255, 255, .5)
}

.btn-eye.bg-white::before {
    border-color: rgba(0, 0, 0, .1);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.banner-adv1 {
    margin-bottom: 75px;
    padding-bottom: 80px
}

.title-underline {
    font-weight: 400;
    margin-bottom: 30px
}

.title-underline span {
    display: inline-block;
    position: relative;
    padding-bottom: 6px
}

.banner-adv1 .banner-info {
    top: 120px;
    width: 400px
}

.list-techpro {
    margin: auto;
    max-width: 1024px
}

.techpro-icon a {
    display: block;
    font-size: 30px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    width: 100px
}

.techpro-icon {
    width: 100px
}

.item-techpro.table .techpro-info {
    padding: 0 20px;
    vertical-align: middle;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-techpro.table {
    margin: 0 0 50px;
    position: relative
}

.item-techpro.table::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    content: "";
    border-radius: 50px;
    z-index: -1;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-techpro.table:hover::before {
    width: 100%
}

.item-techpro.table:hover .techpro-info {
    padding-left: 0
}

.footer2.footer {
    background: #fff
}

.top-header2 {
    padding: 9px 0
}

.smart-search.smart-search2 {
    background: #292f52
}

.mini-cart2 .mini-cart-icon {
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    text-align: center;
    width: 40px
}

.main-header-top2 {
    padding: 10px 0
}

.search-cart2 {
    margin-top: 20px
}

.mini-cart2 .mini-cart-number {
    line-height: 22px;
    background: #fff;
    top: 8px;
    right: -10px
}

.header-nav2 {
    border-top: 1px solid rgba(255, 255, 255, .1)
}

.category-dropdown2 .category-dropdown-link {
    border-right: 1px solid rgba(255, 255, 255, .1)
}

.category-dropdown2 .category-dropdown-link::after {
    display: none
}

.top-info3 li a {
    color: #fff
}

.top-info3 li {
    border-right: 1px solid rgba(255, 255, 255, .2);
    padding: 0 10px
}

.top-header3 {
    padding: 5px 0
}

.top-info3 {
    margin: 8px -10px 0
}

.top-info3 li:last-child {
    border: none
}

.top-info3 li a span {
    margin-right: 5px
}

.main-header3 {
    padding: 20px 0
}

.header-intro.header-intro3 {
    margin: 26px 0 0
}

.mini-cart4 .price-cart-top {
    border: none;
    color: #3b3b3b;
    margin-left: 7px;
    padding: 0
}

.mini-cart10 .mini-cart-header {
    background: #fff;
    border-radius: 6px;
    padding: 5px 15px;
    width: 200px
}

.main-nav.main-nav3 {
    background: #262626;
    margin: 0;
    text-align: center
}

.main-nav.main-nav3>ul {
    display: inline-block
}

.main-nav.main-nav3>ul>li>a {
    height: 50px;
    line-height: 50px;
    padding: 0 30px
}

.main-nav.main-nav3>ul>li>a::after {
    background: rgba(255, 255, 255, .2);
    bottom: 10px;
    content: "";
    position: absolute;
    right: -2px;
    top: 10px;
    width: 1px
}

.main-nav.main-nav3>ul>li:last-child>a::after {
    display: none
}

.banner-adv3 {
    margin-bottom: 50px
}

.inner-banner-info2 .title60 {
    margin: -10px 0 5px
}

.banner-slider2 .banner-info {
    height: 350px
}

.banner-slider.banner-slider2 {
    margin-bottom: 30px
}

.list-techpro2 {
    margin: 0 50px 20px
}

.title-tab2 ul li a {
    border-radius: 6px;
    display: block;
    height: 40px;
    line-height: 42px;
    padding: 0 25px
}

.from-blog2 .title30 {
    margin-bottom: 42px
}

.title-tab2 {
    border: 1px solid #e5e5e5;
    padding: 5px 15px
}

.product-tab2 .title30 {
    margin-bottom: 45px
}

.product-slider2 {
    border: 1px solid #e5e5e5;
    margin-top: -1px
}

.product-tab2 {
    margin-bottom: 70px
}

.item-product11.style2 {
    padding: 20px 20px 30px
}

.item-product11.style2:hover .product-label {
    transform: scale(0);
    -webkit-transform: scale(0)
}

.owl-theme.arrow-style2 .owl-controls .owl-buttons div {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    color: #fff;
    font-size: 13px
}

.owl-theme.arrow-style2 .owl-controls .owl-buttons div span {
    display: block;
    line-height: 30px
}

.owl-theme.group-navi .owl-controls .owl-buttons div.owl-next {
    right: 0;
    position: absolute;
    top: 0
}

.owl-theme.group-navi .owl-controls .owl-buttons div.owl-prev {
    left: 0;
    position: absolute;
    top: 0
}

.item-product11.style2 .product-extra-link {
    position: static;
    text-align: center;
    width: auto;
    margin: 0 -20px
}

.item-product11.style2 .product-extra-link a {
    display: inline-block;
    width: auto;
    border: none
}

.item-product11.style2 .product-extra-link a.btn-eye {
    border: 1px solid #e5e5e5;
    margin: 0 10px;
    z-index: 9
}

.item-product11.style2 .product-extra-link a.btn-eye::before {
    color: #3b3b3b
}

.item-product11.style2 .product-extra-link a.btn-eye:hover,
.item-product11.style2 .product-extra-link a.btn-eye:hover::before {
    color: #fff
}

.item-product11.style2 .product-extra-link a.wishlist-link {
    transform: translateX(50px);
    -webkit-transform: translateX(50px)
}

.item-product11.style2 .product-extra-link a.compare-link {
    transform: translateX(-50px);
    -webkit-transform: translateX(-50px)
}

.item-product11.style2:hover .product-extra-link a {
    transform: translateX(0) !important;
    -webkit-transform: translateX(0) !important
}

.banner-slider .owl-theme.arrow-style2 .owl-controls .owl-buttons div {
    position: absolute;
    top: 50%;
    width: 50px;
    height: 50px;
    font-size: 30px;
    margin-top: -25px
}

.banner-slider .owl-theme.arrow-style2 .owl-controls .owl-buttons div span {
    line-height: 50px
}

.banner-slider .owl-theme.arrow-style2 .owl-controls .owl-buttons div.owl-prev {
    left: 15px
}

.banner-slider .owl-theme.arrow-style2 .owl-controls .owl-buttons div.owl-next {
    right: 15px
}

.footer.footer2 .footer-box a,
.footer.footer2 .footer-box>p {
    color: #888
}

.footer.footer2 .list-footer-box {
    border-color: #e5e5e5
}

.footer.footer2 .newsletter-form input[type=text] {
    border: 1px solid #e5e5e5
}

.footer.footer2 .copyright,
.footer.footer2 .footer-menu a {
    color: #3b3b3b
}

.tab-cat1 {
    padding: 50px 0 80px
}

.access4.style-dark>ul {
    background: #292f52;
    border: none
}

.access4.style-dark li {
    border-color: rgba(255, 255, 255, .1);
    height: 103px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.access4.style-dark li:hover * {
    color: #fff
}

.access4.style-dark li:hover .access-thumb img {
    filter: brightness(1000%);
    -webkit-filter: brightness(1000%);
    -moz-filter: brightness(1000%)
}

.brow-category.box-home1 {
    margin-bottom: 50px;
    margin-top: 65px
}

.brow-category.box-home1 .title-box {
    margin-bottom: 7px
}

.brow-category-slider .owl-buttons {
    top: -40px
}

.product-tech {
    margin-bottom: 30px
}

.techpro-thumb {
    margin-top: 50px
}

.main-nav>ul>li.menu-item-has-children li.menu-item-has-children>.sub-menu.offset-right {
    left: auto;
    right: 100%
}

.footer3,
.nav-fixed.header-nav3 {
    background: #262626
}

.footer.footer4 {
    background: #f4f4f4
}

.footer.footer2.footer4 .newsletter-form input[type=submit] {
    background: #73a209
}

.instagram-box.instagram4 {
    background: url(../images/home3/bg-in.jpg) no-repeat fixed rgba(0, 0, 0, 0)
}

.list-adv8.list-adv4 {
    margin-bottom: 0
}

.product-box4 {
    padding: 0 40px;
    margin-bottom: 70px
}

.product-slider4 {
    margin: 0 -15px
}

.product-slider4 .item {
    margin: 0 15px
}

.product-tab4 {
    padding: 0 40px;
    margin-bottom: 50px
}

.item-slider4 .thumb-slider4 {
    float: left;
    width: 60%;
    padding: 0 50px
}

.item-slider4 .inner-banner-info5 {
    width: 40%;
    float: left
}

.main-header2.header4 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9
}

.wrap-header-nav4 {
    border-bottom: 1px solid rgba(255, 255, 255, .2);
    border-top: 1px solid rgba(255, 255, 255, .2)
}

.category-dropdown.category-dropdown4 {
    width: auto;
    margin-left: 30px
}

.category-dropdown4 .category-dropdown-link::after {
    border-color: rgba(255, 255, 255, .2);
    top: 0;
    height: 50px;
    line-height: 50px
}

.main-nav.main-nav4 {
    float: left
}

.social-nav4 a {
    display: inline-block;
    height: 38px;
    width: 38px;
    border: 1px solid rgba(255, 255, 255, .2);
    text-align: center;
    color: #fff;
    line-height: 38px;
    border-radius: 50%;
    font-size: 14px;
    margin: 0 3px
}

.social-nav4 {
    margin-top: 6px;
    margin-right: 30px
}

.main-header-top4 {
    padding: 23px 35px
}

.smart-search.smart-search4 {
    max-width: 600px;
    background: #fff;
    margin-left: 40px;
    margin-top: 20px
}

.smart-search4 .select-category .category-toggle-link {
    height: 46px;
    line-height: 46px;
    color: #555
}

.smart-search4 .smart-search-form>input[type=text] {
    height: 46px;
    color: #999
}

.info-cart4 .mini-cart1 .mini-cart-icon {
    font-size: 24px
}

.info-cart4 .mini-cart1 .mini-cart-number {
    color: #333;
    right: -13px;
    background: #fff
}

.info-cart4 .mini-cart-box.mini-cart1 {
    margin-top: -4px
}

.info-cart4>li>a>span {
    font-size: 24px;
    vertical-align: middle;
    margin-right: 6px
}

.info-cart4>li {
    border-right: 1px solid #fff;
    padding-right: 22px;
    margin-right: 20px
}

.info-cart4>li:last-child {
    border: none;
    margin: 0;
    padding: 0
}

.info-cart4 {
    margin-top: 30px
}

.service-box4 {
    background: #fff;
    padding: 50px 40px;
    margin-bottom: 85px
}

.item-service4.table .service-icon {
    width: 100px
}

.item-service4.table .service-icon a {
    height: 100px;
    width: 100px;
    display: block;
    text-align: center;
    line-height: 100px;
    font-size: 36px;
    border-radius: 50%
}

.item-service4.table .service-info {
    vertical-align: middle;
    padding-left: 20px
}

.item-service4.table {
    padding: 0 15px
}

.list-service4 {
    margin: 0 -15px
}

.item-service4.table .service-info p {
    margin: 10px 0 0
}

.item-slider4 .inner-banner-info5 {
    padding-top: 100px
}

.smart-search4 .smart-search-form::after {
    height: 46px;
    line-height: 46px
}

.top-header5 {
    background: #262626
}

.main-nav.main-nav3.main-nav5 {
    background: 0 0;
    border-top: 1px solid rgba(255, 255, 255, .2)
}

.footer3 .back-to-top {
    background: #262626;
    color: #262626
}

.header-intro.header-intro5 *,
.inner-banner-info5 .btn-eye.bg-white:hover::before {
    color: #fff
}

.banner-slider .owl-theme .owl-controls .owl-buttons div {
    opacity: 0
}

.banner-slider .owl-theme:hover .owl-controls .owl-buttons div {
    opacity: 1
}

.header-intro.header-intro5 {
    float: right;
    margin: 0
}

.top-header5 .top-info3 {
    margin: 0 -10px
}

.top-header3.top-header5 {
    padding: 12px 0 10px
}

.search-form2.search-form5 {
    margin-top: 19px
}

.banner-slider5 .banner-info {
    height: 260px
}

.inner-banner-info5 .title60 {
    margin: -10px 0 15px
}

.inner-banner-info5 .title30 {
    margin-bottom: 20px
}

.inner-banner-info5 .btn-eye.shopnow::before {
    content: "\e82e"
}

.inner-banner-info5 .btn-eye {
    margin: 0 3px;
    vertical-align: top
}

.box-header-intro .list-inline-block li,
.item-message-box .fa {
    vertical-align: middle
}

.banner-slider5 .owl-theme .owl-controls .owl-page span {
    background: #fff;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border: 5px solid #fff
}

.banner-slider5 .owl-theme .owl-controls .owl-pagination {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px
}

.banner-slider5 {
    margin-bottom: 90px
}

.title-tab5 ul li a {
    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    padding: 0 30px;
    background: #fff;
    position: relative
}

.title-tab5 ul li {
    margin: 0 3px
}

.title-tab5 {
    margin-bottom: 50px
}

.title-tab5 ul li.active a::before {
    content: "";
    position: absolute;
    top: 14px;
    left: 10px;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%
}

.from-blog2.from-blog5 {
    position: relative;
    padding-bottom: 100px;
    margin-bottom: 0
}

.from-blog2.from-blog5::before {
    position: absolute;
    bottom: 0;
    left: -500px;
    right: -500px;
    content: "";
    background: #fff;
    height: 350px
}

.box-parallax {
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-attachment: fixed
}

.customer-review6 {
    background-image: url(../images/home6/bg-test.jpg)
}

.banner-phone6 {
    padding: 75px 0;
    margin-bottom: 95px
}

.content-banner-phone6 img {
    position: absolute;
    top: -105px;
    left: 0
}

.content-banner-phone6 {
    position: relative;
    padding-left: 350px
}

.info-phone6 {
    padding: 30px 50px 40px
}

.info-phone6 h3.title30 {
    margin: 10px 0 22px
}

.title-tech6 {
    margin-bottom: 42px
}

.intro-product6 {
    margin-bottom: 100px
}

.intro-product6 p {
    max-width: 720px;
    margin: auto auto 30px;
    font-size: 24px;
    line-height: 30px
}

.item-product10.style2 .product-thumb {
    padding: 10px
}

.item-product10.style2 .product-info {
    text-align: center;
    padding: 30px 5px
}

.item-product10.style2 {
    border: 1px solid #e5e5e5;
    box-shadow: none
}

.item-product10.style2:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .1);
    border-color: transparent
}

.item-product10.style2 .extra-link10 {
    margin-top: 20px
}

.item-product10.style2 .extra-link10 .addcart-link {
    width: auto
}

.item-product10.style2 .product-rating {
    margin: auto
}

.item-product10.style2 .extra-link10 a {
    margin: 0 3px
}

.item-product10.style2 .product-info::before {
    height: 1px;
    background: #e5e5e5;
    left: -1px;
    right: -1px
}

.item-product10.style2:hover .product-info::before {
    height: 3px
}

.list-product-featured6 {
    margin-bottom: 45px
}

.list-adv6 .banner-new {
    margin-bottom: 30px
}

.item-adv6 .banner-info {
    max-width: 320px;
    left: 70px;
    top: 70px;
    right: auto
}

.item-adv6 .banner-info-new h2 {
    text-transform: capitalize;
    margin: 10px 0 20px
}

.item-adv6 .banner-info-new .desc {
    margin-bottom: 30px
}

.box-adv6>.title30 {
    margin-bottom: 40px
}

.banner-new.banner-ads6 {
    margin-bottom: 95px;
    height: 500px
}

.banner-new.banner-ads6 .banner-info {
    height: 205px
}

.team-gallery-thumb .fancybox,
.team-social-network a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center
}

.banner-new.banner-ads6 .banner-info h2.title48 {
    font-weight: 400;
    display: inline-block;
    padding-bottom: 7px;
    margin-bottom: 30px;
    margin-top: 100px
}

.banner-new.banner-ads6 .banner-info p {
    margin: 10px 0 23px
}

.banner-background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%
}

.banner-background .image-background {
    opacity: 0
}

.item-techpro.table.icon-right::before {
    left: auto;
    right: 0
}

.item-techpro.table.icon-right:hover .techpro-info {
    padding: 0 0 0 40px
}

.box-header-intro {
    margin-bottom: 40px;
    margin-top: 20px
}

.box-header-intro .title30 {
    border-right: 1px solid #e5e5e5;
    margin-right: 20px;
    padding-right: 20px
}

.item-message-box.item-message-info {
    background: #1997d4;
    color: #fff
}

.item-message-box {
    margin-bottom: 30px;
    padding: 20px 30px
}

.item-message-box .fa {
    font-size: 30px;
    margin-right: 20px
}

.item-message-box p {
    font-size: 16px;
    margin: 0
}

.item-message-box.item-message-success {
    background: #eaf8e7;
    color: #2ebe12
}

.item-message-box.item-message-error {
    background: #fff2f2;
    color: red
}

.item-message-box.item-message-warning {
    background: #fff9ed;
    color: #fec64d
}

.team-gallery-thumb .fancybox {
    background: #fff;
    margin: auto;
    z-index: 9;
    transform: scale(0);
    -webkit-transform: scale(0)
}

.team-gallery-thumb:hover .fancybox {
    transform: scale(1);
    -webkit-transform: scale(1)
}

.item-team-gallery h3 {
    margin: 0 0 20px
}

.team-social-network {
    margin-left: -3px;
    margin-top: 20px
}

.team-social-network li {
    margin: 0 3px
}

.team-gallery-thumb {
    margin-bottom: 20px
}

.list-team-box,
.list-team-box .team-circle,
.list-team-box .team-gallery {
    margin-bottom: 50px
}

.team-social-network a {
    background: rgba(0, 0, 0, .1);
    color: #555;
    display: block;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.list-team-box {
    margin-top: 50px
}

.item-team-circle {
    position: relative
}

.item-team-circle1 {
    z-index: 104
}

.team-circle-thumb {
    background: #000;
    border-radius: 50%;
    cursor: pointer;
    position: relative
}

.team-circle-thumb:hover .team-cirle-image {
    margin-left: 30px;
    transform: scale(.5) translateX(100%) rotate(360deg);
    -webkit-transform: scale(.5) translateX(100%) rotate(360deg);
    border: 10px solid rgba(0, 0, 0, .1)
}

.accordion-box.style1 .item-accordion,
.item-member2 .member-info>p,
.member-info>h3 {
    border-bottom: 1px solid #e5e5e5
}

.team-circle-thumb>img {
    width: 100%
}

.team-cirle-image {
    border-radius: 50%;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.info-circle-thumb {
    left: 0;
    opacity: 0;
    padding: 45px 45px 0;
    position: absolute;
    text-align: center;
    top: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
    visibility: hidden;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.info-circle-thumb .desc {
    color: #ccc
}

.team-circle-thumb:hover .info-circle-thumb {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
    visibility: visible
}

.item-team-circle2 {
    z-index: 103
}

.item-team-circle3 {
    z-index: 102
}

.item-team-circle4 {
    z-index: 101
}

.team-circle-info>h3 {
    margin: 15px 0 10px
}

.item-team-rectang {
    border: 1px solid #e5e5e5;
    overflow: hidden;
    position: relative;
    text-align: center
}

.item-team-rectang:hover .team-circle-info {
    bottom: 0
}

.item-team-rectang .team-circle-info {
    background: rgba(255, 255, 255, .9);
    bottom: -60px;
    left: 0;
    padding: 0 20px 15px;
    position: absolute;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.team-circle-info {
    text-align: center
}

.item-member1 .member-social {
    background: rgba(0, 0, 0, .5);
    height: 100%;
    left: -60px;
    padding: 40px 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 60px;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.item-member1 .member-social a {
    color: #fff;
    margin: 7px
}

.member-social>a {
    border-radius: 50%;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transition: all .3s ease-out 0s;
    width: 30px
}

.title-pages h2 span::after,
.title-pages h2 span::before {
    content: "";
    height: 4px;
    margin-top: -2px;
    top: 50%;
    width: 1200px
}

.item-member1 .member-social a:hover {
    background: rgba(0, 0, 0, .3)
}

.item-member1 .member-info>h3 {
    margin-bottom: 15px;
    padding: 15px 0
}

.member-thumb {
    overflow: hidden;
    position: relative
}

.item-member1 .member-thumb:hover .member-social {
    left: 0
}

.team-rectang,
.title-pages {
    margin-bottom: 50px
}

.title-pages h2 {
    overflow: hidden
}

.title-pages h2 span {
    padding: 0 30px;
    position: relative;
    display: inline-block;
    z-index: 9
}

.title-pages h2 span::before {
    background: rgba(0, 0, 0, .1);
    right: 100%;
    position: absolute
}

.title-pages h2 span::after {
    background: rgba(0, 0, 0, .1);
    left: 100%;
    position: absolute
}

.testimo-avatar::before,
.testimo-content-left::before {
    content: "";
    display: block;
    height: 100%
}

.title-pages .title14 {
    margin-bottom: 5px
}

.item-member2 .member-info {
    padding-top: 50px
}

.item-member2 .member-info>h3 {
    margin-bottom: 25px;
    padding-bottom: 27px
}

.item-member2 .member-info>p {
    margin-bottom: 30px;
    padding-bottom: 25px
}

.item-member2 .member-social>a {
    background: rgba(0, 0, 0, .1);
    color: #fff;
    margin-right: 7px
}

.item-member2 .member-social>a:hover {
    background: #000
}

.box-member1 {
    margin-bottom: 50px
}

.testimo-item {
    background: #797979;
    color: #fff
}

.testimo-content-left::before {
    background: rgba(0, 0, 0, .5);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1
}

.testimo-content-left {
    overflow: hidden;
    position: relative;
    text-align: center
}

.testimo-thumb {
    filter: blur(8px);
    transform: scale(1.1)
}

.info-testimo-author {
    left: 0;
    position: absolute;
    top: 90px;
    width: 100%;
    z-index: 2
}

.testimo-avatar::before {
    border-radius: 50%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.testimo-avatar img,
.thumb-customer-saying img {
   /* border-radius: 50%;*/
    transition: all .5s ease-out 0s
}

.testimo-avatar:hover::before {
    opacity: 1
}

.testimo-avatar:hover img {
    transform: rotate(30deg);
    -webkit-transform: rotate(30deg)
}

.testimo-avatar {
    border-radius: 50%;
    box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 150px
}

.bt-style1:hover,
.bt-style6 {
    box-shadow: 0 0 0 2px #1b1d1f inset
}

.testimo-avatar img {
    width: 100%;
    -webkit-transition: all .5s ease-out 0s
}

.testimo-content-right {
    padding-left: 55px;
    padding-right: 70px;
    padding-top: 95px;
    position: relative
}

.testimo-content-right .testimo-desc::before {
    content: "\f0e5";
    font-family: fontAwesome;
    font-size: 30px;
    left: 0;
    position: absolute;
    top: 0
}

.testimo-desc {
    padding-top: 60px;
    position: relative;
    font-size: 16px
}

.testimo-date {
    color: #ccc;
    display: block;
    margin-top: 24px;
    padding-top: 15px;
    position: relative
}

.customer-saying>h2 {
    margin: 0 0 45px
}

.item-customer-saying {
    display: table;
    width: 100%
}

.thumb-customer-saying {
    display: table-cell;
    width: 70px
}

.info-customer-saying::before {
    color: #ccc;
    content: "";
    font-family: fontawesome;
    left: 20px;
    position: absolute;
    top: 5px
}

.info-customer-saying {
    display: table-cell;
    padding-left: 40px;
    padding-right: 20px;
    position: relative;
    vertical-align: top
}

.thumb-customer-saying>a {
    /*border-radius: 50%;*/
    display: block;
    overflow: hidden
}

.thumb-customer-saying img {
    width: 100%;
    -webkit-transition: all .5s ease-out 0s
}

.bt-link,
.bt-link span {
    transition: all .5s ease-out 0s
}

.info-customer-saying .desc {
    position: relative;
    font-size: 13px;
    line-height: 20px
}

.info-customer-saying>h3 {
    margin: 10px 0 5px
}

.info-customer-saying h3 a {
    font-size: 16px
}

/*.thumb-customer-saying a:hover img {
    transform: rotate(30deg);
    -webkit-transform: rotate(30deg)
}*/

.testimo-date::before {
    background: #fff;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 0;
    width: 24px
}

.info-testimo-author .title16 {
    margin: 20px 0 10px
}

.paginav-slider .owl-pagination {
    margin: 20px 0
}

.customer-saying,
.testimonial-slider {
    margin-bottom: 50px
}

.toggle-tab-content {
    display: none
}

.accordion-box.style1 .item-accordion.active .title-accordion::after {
    content: "\f106"
}

.accordion-box.style1 .title-accordion::after {
    color: #747474;
    content: "\f107";
    font-family: fontAwesome;
    font-size: 18px;
    position: absolute;
    right: 0;
    top: 15px
}

.accordion-box.style1 .title-accordion {
    padding-right: 20px
}

.title-accordion {
    color: #1b1d1f;
    cursor: pointer;
    font-size: 16px;
    margin: 0;
    padding: 15px 0;
    position: relative
}

.desc-accordion {
    color: #666;
    margin: 0 0 20px;
    line-height: 24px
}

.accordion-box.style2 .item-accordion {
    border: 1px solid #e5e5e5;
    margin-bottom: 5px;
    padding: 0 20px
}

.accordion-box.style2 .title-accordion {
    font-size: 13px;
    padding: 17px 15px 17px 25px;
    text-transform: uppercase
}

.accordion-box.style2 .item-accordion.active .title-accordion::before {
    content: "\f146"
}

.accordion-box.style2 .title-accordion::before {
    content: "\f0fe";
    display: block;
    height: 14px;
    left: 0;
    position: absolute;
    top: 17px;
    width: 14px;
    font-family: fontAwesome
}

.accordion-box,
.accordion-box.style3 h2 {
    margin-bottom: 30px
}

.accordion-box.style3 .item-accordion {
    margin-bottom: 5px
}

.accordion-box.style2 h2 {
    margin-bottom: 30px
}

.accordion-box.style3 .item-accordion.active .title-accordion::before {
    content: "\f068"
}

.accordion-box.style3 .title-accordion::before {
    content: "\f067";
    display: block;
    height: 40px;
    left: 0;
    position: absolute;
    top: -1px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-family: fontAwesome;
    background: #000;
    color: #fff
}

.accordion-box.style3 .title-accordion {
    background: #f5f5f5;
    border: 1px solid #e5e5e5;
    font-size: 13px;
    margin-bottom: 15px;
    padding: 12px 15px 12px 55px;
    text-transform: uppercase
}

.accordion-box h2 {
    color: #999;
    font-size: 16px;
    margin: 0;
    text-transform: uppercase
}

.circle1 .circles-text {
    color: #ccc;
    font-size: 60px !important
}

#chart-4 .circles-text,
#chart-5 .circles-text {
    color: #fff
}

.list-chart-processbar {
    padding: 40px 0
}

.circle1 {
    float: left;
    margin-right: 135px;
    width: 300px
}

.circle1:last-child {
    margin-right: 0
}

.circle2 {
    border-radius: 50%;
    float: left;
    height: 170px;
    margin-right: 80px;
    padding: 23px;
    width: 170px
}

.circle2:last-child {
    margin-right: 0
}

.circle2 .circles-text {
    font-size: 30px !important
}

#chart-4 {
    background: #2ea0d7
}

#chart-5 {
    background: #fec942
}

#chart-6 .circles-text,
#chart-7 .circles-text {
    color: #1b1d1f
}

#chart-6 {
    background: #ccc
}

#chart-7 {
    background: #ffd21e
}

#chart-8 {
    background: #1b1d1f
}

#chart-8 .circles-text {
    color: #ffd21e
}

.item-progressbar label {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    color: #494a4c
}

.list-chart-processbar .item-progressbar {
    max-width: 470px;
    width: 100%
}

.list-chart-processbar .ui-progressbar .ui-progressbar-value {
    float: right;
    margin: 0;
    border: none
}

.list-chart-processbar .ui-progressbar.ui-widget-content {
    background: #e5e5e5;
    border: none
}

.list-chart-processbar #progressbar-1.ui-progressbar {
    height: 5px;
    border-radius: 0
}

.list-chart-processbar #progressbar-1.ui-progressbar .ui-progressbar-value {
    background: #fec942;
    border-radius: 0
}

.list-chart-processbar #progressbar-2.ui-progressbar {
    height: 5px;
    border-radius: 0
}

.list-chart-processbar #progressbar-2.ui-progressbar .ui-progressbar-value {
    background: #2ea0d7;
    border-radius: 0
}

.list-chart-processbar #progressbar-3.ui-progressbar {
    height: 5px;
    border-radius: 0
}

.list-chart-processbar #progressbar-3.ui-progressbar .ui-progressbar-value {
    background: #494a4c;
    border-radius: 0
}

.list-chart-processbar #progressbar-4.ui-progressbar {
    height: 5px;
    border-radius: 0
}

.list-chart-processbar #progressbar-4.ui-progressbar .ui-progressbar-value {
    background: #ffd21e;
    border-radius: 0
}

.list-chart-processbar #progressbar-5.ui-progressbar {
    height: 11px;
    border-radius: 4
}

.list-chart-processbar #progressbar-5.ui-progressbar .ui-progressbar-value {
    background: #fec942;
    border-radius: 4
}

.list-chart-processbar #progressbar-6.ui-progressbar {
    height: 11px;
    border-radius: 4
}

.list-chart-processbar #progressbar-6.ui-progressbar .ui-progressbar-value {
    background: #2ea0d7;
    border-radius: 4
}

.list-chart-processbar #progressbar-7.ui-progressbar {
    height: 11px;
    border-radius: 4
}

.list-chart-processbar #progressbar-7.ui-progressbar .ui-progressbar-value {
    background: #494a4c;
    border-radius: 4
}

.list-chart-processbar #progressbar-8.ui-progressbar {
    height: 11px;
    border-radius: 4
}

.list-chart-processbar #progressbar-8.ui-progressbar .ui-progressbar-value {
    background: #ffd21e;
    border-radius: 4
}

#progressbar-2,
#progressbar-5,
#progressbar-6 {
    margin-bottom: 50px
}

#progressbar-3,
#progressbar-4 {
    margin-bottom: 100px
}

.pie-chart {
    float: left;
    height: 210px;
    margin-left: -8px;
    margin-right: 52px;
    max-width: 200px
}

.pie-chart:last-child {
    margin-right: 0
}

.percentage:last-child {
    display: none
}

#target1 .pie0 {
    background-color: #2ea0d7;
    fill: #2ea0d7
}

#target1 .pie1 {
    background-color: #2a86b2;
    fill: #2a86b2
}

#target2 .pie0 {
    background-color: #fec942;
    fill: #fec942
}

#target2 .pie1 {
    background-color: #d1a73b;
    fill: #d1a73b
}

#target3 .pie0 {
    background-color: #a9a9a9;
    fill: #a9a9a9
}

#target3 .pie1 {
    background-color: #ccc;
    fill: #ccc
}

#target4 .pie0 {
    background-color: #d1ae1e;
    fill: #d1ae1e
}

#target4 .pie1 {
    background-color: #ffd21e;
    fill: #ffd21e
}

#target5 .pie0 {
    background-color: #494a4c;
    fill: #494a4c
}

#target5 .pie1 {
    background-color: #1b1d1f;
    fill: #1b1d1f
}

.bt-link {
    border: none;
    display: inline-block;
    height: 40px;
    padding: 0 30px;
    text-transform: uppercase;
    vertical-align: top;
    overflow: hidden;
    -webkit-transition: all .5s ease-out 0s
}

.list-button-box1 {
    background: #f5f5f5;
    padding: 50px 0 40px
}

.list-button-box2 {
    background: #1b1d1f;
    padding: 50px 0 40px
}

.list-button-box1 .bt-link,
.list-button-box2 .bt-link {
    margin-bottom: 10px;
    margin-left: 50px
}

.bt-black {
    background: #1b1d1f;
    color: #fff
}

.bt-yellow {
    background: #fec64d;
    color: #fff
}

.bt-style1:hover,
.bt-white {
    background: #fff;
    color: #1b1d1f
}

.bt-style6:hover {
    background: #1b1d1f;
    box-shadow: none;
    color: #fff
}

.bt-style7 {
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .1)
}

.bt-style7:hover {
    background: #1b1d1f;
    color: #fff;
    box-shadow: none
}

.bt-style2:hover {
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, .1);
    background: #fff;
    color: #1b1d1f
}

.bt-style3,
.bt-style8 {
    padding: 5px;
    width: 143px;
    box-shadow: 0 0 0 1px #1b1d1f inset
}

.bt-style8 {
    background-clip: content-box
}

.bt-style8:hover {
    background-clip: padding-box
}

.bt-style3:hover {
    background-clip: content-box
}

.bt-style4:hover {
    background: #fec64d
}

.bt-style5:hover,
.bt-style9:hover {
    background: #1b1d1f
}

.bt-style10:hover,
.bt-style11:hover {
    background: #fec64d
}

.bt-style11 {
    box-shadow: 0 0 0 1px #fff inset
}

.bt-style11:hover,
.bt-style16 {
    box-shadow: 0 0 0 2px #fff inset
}

.bt-style12,
.bt-style16:hover {
    box-shadow: 0 0 0 1px #fff inset
}

.bt-style16:hover {
    background: #1b1d1f
}

.bt-style12:hover,
.bt-style17 {
    box-shadow: 0 0 0 1px #FEC64D inset
}

.bt-style12:hover {
    background: #fff;
    color: #1b1d1f
}

.bt-style13,
.bt-style17:hover {
    box-shadow: 0 0 0 1px #fff inset
}

.bt-style17:hover {
    background: #1b1d1f;
    color: #fff
}

.bt-style13:hover,
.bt-style18 {
    box-shadow: 0 0 0 4px #FEC64D inset;
    background: #fff;
    color: #1b1d1f
}

.bt-style14,
.bt-style18:hover,
.bt-style19 {
    box-shadow: 0 0 0 1px #fff inset
}

.bt-style18:hover {
    background: #1b1d1f;
    color: #fff
}

.bt-style14:hover {
    background: #fff;
    color: #1b1d1f
}

.bt-style21:hover,
.bt-style22:hover,
.bt-style26,
.bt-style27 {
    box-shadow: 0 0 0 1px #1997D4 inset
}

.bt-style19:hover {
    background: #1b1d1f;
    color: #fff
}

.bt-brown,
.bt-style15:hover {
    background: #323436;
    color: #FEC64D
}

.bt-style20:hover {
    background: #FEC64D;
    color: #fff
}

.title-bittons {
    font-size: 16px;
    margin: 50px 0 30px;
    text-transform: uppercase
}

.link-style14::before,
.link-style19::before,
.link-style4::before,
.link-style9::before {
    font-family: fontAwesome;
    font-size: 18px
}

.bt-blue {
    background: #1997D4;
    color: #fff
}

.bt-radius,
.bt-radius .fa {
    border-radius: 4px
}

.bt-cart {
    text-transform: capitalize
}

.bt-loadmore {
    width: 300px;
    color: #fff
}

.bt-style21,
.bt-style26,
.bt-style31,
.bt-style36 {
    width: 140px;
    padding: 0
}

.bt-link span {
    display: block;
    line-height: 32px;
    margin: 4px;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box,
.btn-circle-box a {
    display: inline-block;
    vertical-align: middle
}

.link-black,
.link-white {
    line-height: 36px;
    margin-right: 40px
}

.link-black,
.link-style4 span,
.link-style4::before {
    transition: all .5s ease-out 0s
}

.bt-style21:hover {
    background: #fff
}

.bt-style21:hover .fa {
    background: #1997D4
}

.bt-style26 {
    background: #fff
}

.bt-style26 .fa,
.bt-style26:hover {
    background: #1997D4
}

.bt-style22:hover,
.bt-style27 {
    background: #fff;
    color: #1997D4
}

.bt-shadow,
.bt-style23:hover {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .3)
}

.bt-style27:hover {
    background: #1997D4;
    color: #fff
}

.bt-gold,
.bt-style23:hover {
    background: #FFD21D
}

.bt-style23:hover {
    color: #1b1d1f
}

.bt-style28:hover {
    background: #1997D4;
    color: #fff;
    box-shadow: none
}

.bt-style24:hover {
    background: #FFD21D
}

.bt-style25:hover,
.bt-style29:hover {
    background: #1997D4
}

.bt-style25 {
    box-shadow: 0 0 0 1px #e3e3e3 inset
}

.bt-style25:hover {
    box-shadow: none;
    color: #fff
}

.bt-style30:hover {
    box-shadow: 0 0 0 1px #e3e3e3 inset;
    background: #fff;
    color: #1b1d1f
}

.bt-style31 .fa {
    background: #1997D4
}

.bt-style31:hover,
.bt-style36 {
    box-shadow: 0 0 0 1px #1997D4 inset;
    background: #1b1d1f
}

.bt-style36 .fa,
.bt-style36:hover {
    background: #1997D4
}

.bt-style32 {
    box-shadow: 0 0 0 1px #8d8d8d inset
}

.bt-style32:hover {
    box-shadow: 0 0 0 1px #fff inset;
    background: #fff;
    color: #1b1d1f
}

.bt-style37:hover {
    box-shadow: 0 0 0 1px #8d8d8d inset;
    background: #1b1d1f;
    color: #fff
}

.bt-style33,
.bt-style33:hover,
.bt-style38,
.bt-style38:hover {
    box-shadow: 0 0 0 1px #FFD21D inset
}

.bt-style33 {
    color: #FFD21D
}

.bt-style33:hover,
.bt-style38 {
    color: #1b1d1f;
    background: #FFD21D
}

.bt-style38:hover {
    color: #FFD21D;
    background: #1b1d1f
}

.bt-style34 {
    box-shadow: 0 0 0 2px #fff inset
}

.bt-style34:hover {
    box-shadow: 0 0 0 2px #4082F0 inset;
    background: #4082F0
}

.bt-style39:hover {
    box-shadow: 0 0 0 2px #fff inset;
    background: #1b1d1f
}

.bt-style35 {
    color: #fff
}

.bt-style35:hover,
.bt-style40 {
    color: #1997D4;
    background: #1b1d1f;
    box-shadow: 0 0 0 2px #1997D4 inset
}

.bt-style40:hover {
    color: #fff;
    background: #323436;
    box-shadow: 0 0 0 2px #323436 inset
}

.btn-circle-box1 .link-compare:hover,
.btn-circle-box11 .link-compare:hover {
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, .3)
}

.link-black {
    background: 0 0;
    color: #1b1d1f;
    padding: 0;
    position: relative;
    text-decoration: underline;
    height: 36px
}

.link-style1,
.link-style6 {
    padding: 0 30px
}

.link-style1:hover,
.link-style6 {
    text-decoration: none;
    border: 1px solid #1b1d1f
}

.link-style1 {
    border: 1px solid transparent
}

.link-style6:hover {
    border: 1px solid transparent;
    text-decoration: underline
}

.link-style2 {
    border-bottom: 3px double transparent
}

.link-style2:hover,
.link-style7 {
    border-bottom: 3px double #1b1d1f;
    color: #666;
    text-decoration: none
}

.link-style7:hover {
    border-bottom: 3px double transparent;
    text-decoration: underline;
    color: #1b1d1f
}

.link-style3 {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent
}

.link-style3:hover,
.link-style8 {
    text-decoration: none;
    border-bottom: 1px solid #1b1d1f;
    border-top: 1px solid #1b1d1f
}

.link-style8:hover {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    text-decoration: underline
}

.link-style5:hover {
    color: #fec64d
}

.link-style10:hover {
    color: #1b1d1f
}

.link-style10 {
    color: #fec64d
}

.link-style4 span {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -webkit-transition: all .5s ease-out 0s
}

.link-style4:hover span {
    transform: translate(0, 100%);
    -webkit-transform: translate(0, 100%)
}

.link-style4:hover::before {
    top: 0
}

.link-style4::before {
    content: "\f07a";
    left: 0;
    position: absolute;
    top: -100%;
    width: 100%;
    -webkit-transition: all .5s ease-out 0s
}

.link-style4::after {
    background: #1b1d1f;
    bottom: 100%;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.link-style4:hover::after {
    bottom: 0
}

.link-style9 span {
    transform: translate(0, 100%);
    -webkit-transform: translate(0, 100%);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.link-style9::after,
.link-style9::before {
    left: 0;
    position: absolute;
    width: 100%;
    transition: all .5s ease-out 0s
}

.link-style9:hover span {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0)
}

.link-style9:hover::before {
    top: -100%
}

.link-style9::before {
    content: "\f07a";
    top: 0;
    -webkit-transition: all .5s ease-out 0s
}

.link-style9::after {
    background: #1b1d1f;
    bottom: 0;
    content: "";
    height: 1px;
    -webkit-transition: all .5s ease-out 0s
}

.link-style14 span,
.link-white {
    transition: all .5s ease-out 0s
}

.link-style9:hover::after {
    bottom: 100%
}

.link-white {
    background: 0 0;
    color: #fff;
    height: 36px;
    padding: 0;
    position: relative;
    text-decoration: underline;
    -webkit-transition: all .5s ease-out 0s
}

.link-style11,
.link-style16 {
    padding: 0 30px
}

.link-style11 {
    border: 1px solid transparent
}

.link-style11:hover,
.link-style16 {
    border: 1px solid #fec64d;
    text-decoration: none;
    color: #fec64d
}

.link-style16:hover {
    border: 1px solid transparent;
    text-decoration: underline;
    color: #fff
}

.link-style12 {
    border-bottom: 3px double transparent
}

.link-style12:hover,
.link-style17 {
    border-bottom: 3px double #666;
    color: #fec64d;
    text-decoration: none
}

.link-style17:hover {
    border-bottom: 3px double transparent;
    text-decoration: underline;
    color: #fff
}

.link-style13 {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent
}

.link-style13:hover,
.link-style18 {
    text-decoration: none;
    border-bottom: 1px solid #1997d4;
    border-top: 1px solid #1997d4;
    color: #1997d4
}

.link-style18:hover {
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
    text-decoration: underline;
    color: #fff
}

.link-style15:hover {
    color: #fec64d
}

.link-style20:hover {
    color: #fff
}

.link-style20 {
    color: #fec64d
}

.link-style14 span {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -webkit-transition: all .5s ease-out 0s
}

.link-style14::after,
.link-style14::before {
    transition: all .5s ease-out 0s;
    left: 0;
    position: absolute;
    width: 100%
}

.link-style14:hover span {
    transform: translate(0, 100%);
    -webkit-transform: translate(0, 100%)
}

.link-style14:hover::before {
    top: 0
}

.link-style14::before {
    content: "\f07a";
    top: -100%;
    color: #fec64d;
    -webkit-transition: all .5s ease-out 0s
}

.link-style14::after {
    background: #fec64d;
    bottom: 100%;
    content: "";
    height: 1px;
    -webkit-transition: all .5s ease-out 0s
}

.link-style14:hover::after {
    bottom: 0
}

.link-style19 span {
    transform: translate(0, 100%);
    -webkit-transform: translate(0, 100%);
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.link-style19:hover span {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0)
}

.link-style19:hover::before {
    top: -100%
}

.link-style19::before {
    content: "\f07a";
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    color: #fec64d;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.link-style19::after {
    background: #fec64d;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box1 .link-add-cart,
.btn-circle-box1 a {
    background: #2FA0D8
}

.link-style19:hover::after {
    bottom: 100%
}

.btn-circle-box {
    margin: 0 35px
}

.btn-circle-box a {
    margin: 0 15px;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box a .fa,
.btn-circle-box5 .link-add-cart::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transition: all .5s ease-out 0s
}

.btn-circle-box a .fa {
    display: block;
    height: 13px;
    font-size: 13px;
    z-index: 99;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box a.link-add-cart .fa {
    height: 18px;
    font-size: 18px
}

.btn-circle-box .link-add-cart {
    width: 48px;
    height: 48px
}

.btn-circle-box .link-compare,
.btn-circle-box .link-wishlist {
    width: 36px;
    height: 36px
}

.btn-circle-box .link-quick-view {
    width: 130px;
    height: 130px;
    line-height: 130px
}

.btn-circle-box1 a {
    color: #fff
}

.btn-circle-box1 a:hover {
    background-color: #FFD21D
}

.btn-circle-box1 .link-wishlist:hover .fa {
    transform: scale(2);
    -webkit-transform: scale(2)
}

.btn-circle-box11 a {
    background: #FFD21D;
    color: #fff
}

.btn-circle-box11 .link-add-cart {
    background: #FFD21D
}

.btn-circle-box11 a:hover {
    background-color: #2FA0D8
}

.btn-circle-box11 .link-wishlist:hover .fa {
    transform: scale(2);
    -webkit-transform: scale(2)
}

.btn-circle-box2 a {
    background: #1b1d1f;
    color: #fff
}

.btn-circle-box2 .link-add-cart {
    width: 56px;
    height: 56px
}

.btn-circle-box2 .link-add-cart:hover {
    background-color: #fff;
    box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, .3)
}

.btn-circle-box2 .link-compare:hover .fa,
.btn-circle-box2 .link-wishlist:hover .fa {
    transform: scale(2);
    -webkit-transform: scale(2)
}

.btn-circle-box2 .link-add-cart::before {
    background: #1b1d1f;
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 48px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px
}

.btn-circle-box22 a {
    background: #FEC64D;
    color: #fff
}

.btn-circle-box22 .link-add-cart {
    width: 56px;
    height: 56px
}

.btn-circle-box22 .link-add-cart:hover {
    background-color: #fff;
    box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, .3)
}

.btn-circle-box22 .link-compare:hover,
.btn-circle-box22 .link-wishlist:hover {
    box-shadow: 1px 3px 3px 0 rgba(0, 0, 0, .3)
}

.btn-circle-box22 .link-add-cart::before {
    background: #FEC64D;
    border-radius: 50%;
    bottom: 0;
    content: "";
    height: 48px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px
}

.btn-circle-box3 .link-quick-view {
    background: #2FA0D8;
    color: #fff
}

.btn-circle-box3 .link-quick-view:hover {
    background: #FFD533
}

.btn-circle-box33 .link-quick-view {
    background: #FFD533;
    color: #fff
}

.btn-circle-box33 .link-quick-view:hover {
    background: #2FA0D8
}

.btn-circle-box4 a {
    background: #fff;
    color: #2FA0D8;
    box-shadow: 0 0 0 1px #e5e5e5 inset
}

.btn-circle-box4 .link-add-cart {
    background: #1997D4;
    box-shadow: none;
    color: #fff
}

.btn-circle-box4 .link-wishlist:hover,
.btn-circle-box44 .link-wishlist {
    box-shadow: 0 0 0 1px #1997D4 inset
}

.btn-circle-box4 a,
.btn-circle-box44 a {
    margin: 0 5px
}

.btn-circle-box4 .link-add-cart:hover {
    background-color: #1b1d1f
}

.btn-circle-box4 .link-compare:hover {
    box-shadow: 0 0 0 1px #1997D4 inset;
    background-color: #1997D4;
    color: #fff
}

.btn-circle-box44 .link-add-cart {
    background: #1b1d1f;
    color: #fff
}

.btn-circle-box44 .link-add-cart:hover {
    background-color: #1997D4
}

.btn-circle-box44 .link-wishlist {
    background: #fff;
    color: #1997D4
}

.btn-circle-box44 .link-wishlist:hover {
    box-shadow: 0 0 0 1px #e5e5e5 inset
}

.btn-circle-box44 .link-compare {
    background: #1997D4;
    color: #fff
}

.btn-circle-box44 .link-compare:hover {
    box-shadow: 0 0 0 1px #e5e5e5 inset;
    background: #fff;
    color: #1997D4
}

.list-button-circle {
    padding: 10px 0
}

.list-button-circle>div {
    padding: 5px 0
}

.btn-circle-box7 .link-quick-view {
    background: #e9e9e9;
    color: #1b1d1f;
    border: 3px solid transparent
}

.btn-circle-box7 .link-quick-view:hover {
    border: 3px solid #000
}

.btn-circle-box77 .link-quick-view {
    background: #e9e9e9;
    color: #1b1d1f;
    border: 3px solid #000
}

.btn-circle-box77 .link-quick-view:hover {
    border: 3px solid transparent
}

.btn-circle-box5 .link-add-cart {
    background: #1997D4;
    color: #fff
}

.btn-circle-box5 .link-add-cart:hover {
    background-color: #FFD21D
}

.btn-circle-box5 .link-add-cart:hover::before {
    border: 1px solid transparent;
    width: 48px;
    height: 48px
}

.btn-circle-box5 .link-add-cart::before {
    content: "";
    width: 38px;
    height: 38px;
    border: 1px solid #1b1d1f;
    border-radius: 50%;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box55 .link-add-cart::before,
.btn-circle-box6 .link-add-cart::before {
    transition: all .5s ease-out 0s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto
}

.btn-circle-box5 .link-wishlist {
    background: #fff;
    box-shadow: 0 0 0 1px #1997D4 inset;
    color: #1997D4
}

.btn-circle-box5 .link-wishlist:hover {
    background: #FFD21D;
    color: #fff;
    box-shadow: 0 0 0 1px transparent inset
}

.btn-circle-box5 .link-wishlist:hover .fa {
    transform: scale(2)
}

.btn-circle-box5 .link-compare {
    background: #1997D4;
    color: #fff
}

.btn-circle-box5 .link-compare:hover {
    background: #FFD21D;
    color: #1b1d1f
}

.btn-circle-box55 .link-add-cart {
    background: #FFD21D;
    color: #fff
}

.btn-circle-box55 .link-add-cart:hover {
    background-color: #1997D4
}

.btn-circle-box55 .link-add-cart:hover::before {
    border: 1px solid #1b1d1f;
    width: 38px;
    height: 38px
}

.btn-circle-box55 .link-add-cart::before {
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    border-radius: 50%;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box55 .link-wishlist {
    background: #FFD21D;
    color: #fff;
    box-shadow: 0 0 0 1px transparent inset
}

.btn-circle-box55 .link-wishlist:hover {
    background: #fff;
    color: #1997D4;
    box-shadow: 0 0 0 1px #1997D4 inset
}

.btn-circle-box55 .link-wishlist:hover .fa {
    transform: scale(2);
    -webkit-transform: scale(2)
}

.btn-circle-box55 .link-compare:hover {
    background: #1997D4;
    color: #fff
}

.btn-circle-box55 .link-compare {
    background: #FFD21D;
    color: #1b1d1f
}

.btn-circle-box5 .link-compare:hover .fa,
.btn-circle-box55 .link-compare:hover .fa {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg)
}

.btn-circle-box6 a {
    color: #fff
}

.btn-circle-box6 .link-add-cart:hover,
.btn-circle-box6 .link-wishlist:hover,
.btn-circle-box66 .link-wishlist {
    background: #fff;
    color: #1b1d1f
}

.btn-circle-box6 .link-add-cart {
    width: 56px;
    height: 56px
}

.btn-circle-box6 .link-add-cart::before {
    width: 56px;
    height: 56px;
    border: 3px solid transparent;
    border-radius: 50%;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box6 .link-add-cart:hover::before {
    border: 3px solid #1b1d1f;
    width: 52px;
    height: 52px
}

.btn-circle-box6 .link-compare:hover {
    transform: rotate(225deg) scale(2);
    -webkit-transform: rotate(225deg) scale(2)
}

.btn-circle-box66 .link-wishlist:hover {
    background: 0 0;
    color: #fff
}

.btn-circle-box66 .link-add-cart {
    width: 56px;
    height: 56px;
    background: #fff;
    color: #1b1d1f
}

.btn-circle-box66 .link-add-cart:hover,
.btn-circle-box8 a {
    background: 0 0;
    color: #fff
}

.btn-circle-box66 .link-add-cart::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 52px;
    height: 52px;
    margin: auto;
    border: 3px solid #1b1d1f;
    border-radius: 50%;
    transition: all .5s ease-out 0s;
    -webkit-transition: all .5s ease-out 0s
}

.btn-circle-box66 .link-add-cart:hover::before {
    border: 3px solid transparent;
    width: 56px;
    height: 56px
}

.btn-circle-box66 .link-compare {
    transform: scale(2);
    -webkit-transform: scale(2);
    color: #fff
}

.btn-circle-box66 .link-compare:hover {
    transform: rotate(225deg);
    -webkit-transform: rotate(225deg)
}

.btn-circle-box8 a,
.btn-circle-box88 a {
    margin: 0 5px
}

.btn-circle-box8 a {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .2) inset
}

.btn-circle-box8 .link-add-cart {
    background: #fff;
    box-shadow: 0 0 0 1px transparent inset;
    color: #1b1d1f
}

.btn-circle-box8 .link-add-cart:hover {
    background: 0 0;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .2) inset;
    color: #fff
}

.btn-circle-box8 .link-wishlist:hover {
    color: #1698D4;
    box-shadow: 0 0 0 1px #1698D4 inset
}

.btn-circle-box8 .link-compare:hover {
    color: #fff;
    box-shadow: 0 0 0 1px #fff inset
}

.btn-circle-box88 .link-add-cart:hover {
    background: #fff;
    box-shadow: 0 0 0 1px transparent inset;
    color: #1b1d1f
}

.btn-circle-box88 .link-add-cart {
    background: 0 0;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .2) inset;
    color: #fff
}

.btn-circle-box88 .link-wishlist {
    color: #1698D4;
    box-shadow: 0 0 0 1px #1698D4 inset
}

.btn-circle-box88 .link-wishlist:hover {
    color: #fff;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .2) inset
}

.btn-circle-box88 .link-compare {
    color: #fff;
    box-shadow: 0 0 0 1px #fff inset
}

.btn-circle-box88 .link-compare:hover {
    box-shadow: 0 0 0 1px rgba(255, 255, 255, .2) inset
}

.list-button-box {
    margin-bottom: 50px
}